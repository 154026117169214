import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import logo from '../../logo-dorado.svg';
import person from '../../icons/person.svg';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { slide as Menu } from 'react-burger-menu'
import CartPanel from '../../components/carrito/CartPanel';
import Swal from 'sweetalert2';
import { BoxArrowInLeft, BoxArrowRight } from 'react-bootstrap-icons';

import './navbar-mobile.layout.css'
import urlSubcuenta from 'utils/urlSubcuenta';
function NavbarMobile() {
    let history = useNavigate();
    const { logged, clientInfo, guestInfo } = useSelector((state) => state);
    const [cartPanel, setCartPanel] = useState(false)
    const [burguerStatus, setburguerStatus] = useState(false)
    const [itemsCarrito, setItemsCarrito] = useState(0);
    const [numberCarrito, setNumberCarrito] = useState(0);

    const location = useLocation();
    const { pathname } = location;

    const handleOpen = () => {
        setCartPanel((cartPanel) => !cartPanel)
    }

    const handleOpenBurguer = (state) => {
        if (state.isOpen !== burguerStatus) {
            setburguerStatus(state.isOpen);
        }
    };

    const handleMenuLinkClick = () => {
        setburguerStatus(false); // Cierra el menú cuando se hace clic en un enlace
    };

    const handleLogin = () => {
        if (!logged) {
            // Realizar el redireccionamiento con el historial en el navegador
            window.location.href = urlSubcuenta() + '/login/';
        }
    };

    useEffect(() => {
        if (logged) {
          setItemsCarrito(clientInfo.CARRITO.TOTAL);
          setNumberCarrito(clientInfo.CARRITO.PRODUCTOS.length);
        } else {
          setItemsCarrito(guestInfo.CARRITO.TOTAL);
          setNumberCarrito(guestInfo.CARRITO.PRODUCTOS.length);
        }
      }, [clientInfo.CARRITO?.TOTAL, guestInfo.CARRITO?.TOTAL, clientInfo.CARRITO?.PRODUCTOS, guestInfo.CARRITO?.PRODUCTOS, logged]);

    return (
        <div>
            <div className={cartPanel ? 'menu-div-disable' : 'menu-div-enable'}>
                <Menu disableAutoFocus className='menu-div' onStateChange={handleOpenBurguer} isOpen={burguerStatus}>
                    <Link className="menu-item navbar-buttonM" style={{ color: `${pathname.includes('productos') ? '#E5CB65' : 'white'}` }} to={`/app/productos`} onClick={handleMenuLinkClick}>NUESTROS CORTES</Link>
                    <Link className="menu-item navbar-buttonM" style={{ color: `${pathname.includes('acompa') ? '#E5CB65' : 'white'}` }} to={`/app/acompañamientos`} onClick={handleMenuLinkClick}>ACOMPAÑAMIENTOS</Link>
                    <Link to="https://keken.com.mx" target='_blank' className='navbar-buttonM' title="Conócenos">CONÓCENOS</Link>
                    {/* <Link href='#' className='navbar-buttonM'>CREA PARRILLAS GLORIOSAS</Link> */}
                    {/* <Link to="#" className='navbar-buttonM'>PROMOCIONES</Link> */}
                    <div style={{ display: 'flex' }}>
                        {
                            !logged ?
                                <div style={{ display: 'flex', justifyContent: 'space-around' }}>

                                    <Link onClick={handleLogin}>
                                        <button title='Mi cuenta' className='navbar-button-icon-person' onClick={handleMenuLinkClick}>
                                            <img src={person} alt="" width="100%" />
                                        </button>
                                    </Link>

                                    {/* <button
                                        className='navbar-button-icon'
                                        style={{ color: 'white' }}
                                        onClick={handleLogin}>
                                        <BoxArrowInLeft style={{ fontSize: '2rem' }} />
                                    </button> */}
                                </div>
                                :
                                /* Círculo con la inicial del nombre del usuario logueado */
                                <div className='logged-username-container'>
                                    <Link to={`/app/cuenta/datos`} className='user-initial-circle' onClick={handleMenuLinkClick}>
                                        <span className='user-initial'>{clientInfo && clientInfo.NOMBRE ? clientInfo.NOMBRE[0].toUpperCase() : ' '} </span>
                                    </Link>
                                    <span className='user-name'> {`HOLA, ${clientInfo && clientInfo.NOMBRE ? clientInfo.NOMBRE.split(" ")[0].toUpperCase() : ' '}`}</span>

                                    <button
                                        className='navbar-button-icon'
                                        style={{ color: 'white' }}
                                        onClick={() => {
                                            Swal.fire({
                                                title: '¿Estás seguro que deseas cerrar sesión?',
                                                icon: 'warning',
                                                showCloseButton: true,
                                                showCancelButton: true,
                                                confirmButtonText: 'Confirmar',
                                                cancelButtonText: 'Cancelar',
                                                confirmButtonColor: '#E5CB65',
                                                background: '#333',
                                                color: '#FFF'
                                            }).then((result) => {
                                                if (result.isConfirmed) {
                                                    const uniqueParam = new Date().getTime();
                                                    // Hacer la redirección al cerrar sesión
                                                    window.location.href = urlSubcuenta() + `/do/logout?cache_bust=${uniqueParam}`;
                                                }
                                            });
                                        }}>
                                        <BoxArrowRight style={{ fontSize: '2rem' }} />
                                    </button>
                                </div>
                        }
                    </div>

                </Menu>
            </div>
            <div className='custom-navbar-mobile'>
                <div className='center-container'>
                    <img src={logo} className='navbar-img' alt="" onClick={() => history("/app/")} />
                </div>

                <button title="Carrito de compras" className='navbar-button-icon' onClick={handleOpen}>
                    <div className='cart-icon-container'>
                        {
                        pathname.includes("carrito") && itemsCarrito === 0 ? (
                            <img src="/images/icons/CARRITO_HOVER.png" alt="" width="100%" />
                        ) : pathname.includes("carrito") && itemsCarrito > 0 ? (
                            <img src="/images/icons/CARRITO_LLENO_FOCUS.png" alt="" width="100%" />
                        ) : !pathname.includes("carrito") && itemsCarrito > 0 ? (
                            <img src="/images/icons/CARRITO_LLENO.png" alt="" width="100%" />
                        ) : (
                            <img src="/images/icons/CARRITO_DEFAULT.png" alt="" width="100%" />
                        )
                        }
                        {itemsCarrito > 0 && (
                        <div className='cart-items-count'>
                            {numberCarrito}
                        </div>
                        )}
                    </div>
                </button>

                <CartPanel propiedades={{ cartPanel, setCartPanel, handleOpen }}></CartPanel>
            </div>
        </div>
    );
}

export default NavbarMobile;