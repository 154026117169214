import { useParams } from 'react-router-dom';
import ItemProductoDescripcion from '../../common/item-producto/item-producto-descripcion.common';
import CrossSell from '../../common/cross-sell/cross-sell.common';
import { useSelector } from 'react-redux';

import './pdp.page.css';


function PDPProductos() {
  const { id } = useParams();
  const { acompañamientos } = useSelector((state) => state);
  return (
    <div className='div-pdp-container'>
      <div className='pdp-carr-cont'>
        <ItemProductoDescripcion productoID={id} />
      </div>

      <CrossSell isProductos={true} backgroundImage={true} titulo={"complementa tu parrilla"} subtitulo={"CONOCE LOS COMPLEMENTOS PARA CARNE ASADA QUE TE AYUDARÁN A CONQUISTAR LA PARRILLA."} items={acompañamientos} />
    </div>
  );
}

export default PDPProductos;