import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Heart, HeartFill } from 'react-bootstrap-icons';
import { Swiper, SwiperSlide } from "swiper/react";
import { HashNavigation, Navigation, Pagination } from "swiper";
import { Plus } from 'react-bootstrap-icons';
import { Dash } from 'react-bootstrap-icons';
import { getProductosPath, getImagesCard } from 'utils/getImages';
import { addClientFav, addItemClientCart, deleteClientFav, updateCheckoutForm, updateItem } from 'redux/actions';
import useUnloggedCart from 'redux/actions/unloggedCart'
import { Link } from 'react-router-dom';
import { formatterPrice } from 'utils/formatter';

import './producto-item-carrousel.css';
// Import Swiper styles
import 'bootstrap/dist/css/bootstrap.min.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/a11y';

function ProductoItemCarrousel({ item, buttonText }) {

    const [mobile, setMobile] = useState(false);
    const { addToCart, isItemInCart } = useUnloggedCart()
    const dispatch = useDispatch()
    const { clientInfo, logged, formCheckout } = useSelector((stateRedux) => stateRedux)
    const [id, setId] = useState(Math.floor(Math.random() * 1000000))
    const [state, setState] = useState(() => {
        return {
            CANTIDAD: "01",
            PRECIO: item.PRECIO,
            TOTAL: item.PRECIO,
            NOMBRE: item.NOMBRE,
            IMG: getImagesCard(item.TIPO, item.URL_IMAGEN),
            URL_IMAGEN: getImagesCard(item.TIPO, item.URL_IMAGEN),
            URL_IMAGEN_H: (item.TIPO === 'PRODUCTO') && getImagesCard(item.TIPO, item.URL_IMAGEN, true),
            ID_PRODUCTO: item.ID_PRODUCTO,
            FAVORITO: false,
            AÑADIDO: false,
        };
    });

    const favoritoHandler = () => {
        // Aca deberíamos mandar un PATCH a los favoritos del usuario agregando el producto ID

        if (state.FAVORITO === false) {
            dispatch(addClientFav(clientInfo.UUID, clientInfo.EMAIL, item.ID_PRODUCTO))
        }

        if (state.FAVORITO === true) {
            dispatch(deleteClientFav(clientInfo.UUID, clientInfo.EMAIL, item.ID_PRODUCTO))
        }

        setState((state) => ({
            ...state,
            FAVORITO: !state.FAVORITO
        }));

    };

    const sumarItem = () => {
        let nuevaCantidad = parseInt(state.CANTIDAD);
        nuevaCantidad++;
        setState((state) => ({
            ...state,
            TOTAL: nuevaCantidad * parseFloat(state.PRECIO)
        }));

        nuevaCantidad = formatearNumero(nuevaCantidad);
        setState((state) => ({
            ...state,
            CANTIDAD: nuevaCantidad
        }));
    };

    const restarItem = () => {
        let nuevaCantidad = parseInt(state.CANTIDAD);
        if (nuevaCantidad > 1) {
            nuevaCantidad--;
            setState((state) => ({
                ...state,
                TOTAL: parseFloat(state.PRECIO) * nuevaCantidad
            }));

            nuevaCantidad = formatearNumero(nuevaCantidad);
            setState((state) => ({
                ...state,
                CANTIDAD: nuevaCantidad
            }));
        } else {
            setState((state) => ({
                ...state,
                CANTIDAD: "01"
            }));
        }
    };

    const formatearNumero = (n) => {
        if (n < 10) {
            n = "0" + n;
        } else {
            n = n.toString();
        }
        return n;
    };

    const handleCart = (e) => {
        e.preventDefault()
        // Actualiza los productos en global state y actualiza el total del carrito
        if (logged) {
            if (formCheckout.PRODUCTOS[item.ID_PRODUCTO]) {
                //Actualiza la cantidad de items seleccionada
                const nuevaCantidad = parseInt(formCheckout.PRODUCTOS[item.ID_PRODUCTO].CANTIDAD) + parseInt(state.CANTIDAD)
                dispatch(updateItem(item.ID_PRODUCTO, { CANTIDAD: nuevaCantidad }))
                //Actualiza el precio total
                dispatch(updateCheckoutForm({ TOTAL: formCheckout.TOTAL + (nuevaCantidad * parseFloat(state.PESO)) * parseFloat(state.PRECIO) }))
                dispatch(addItemClientCart(clientInfo.UUID, clientInfo.EMAIL, {
                    ID_PRODUCTO: item.ID_PRODUCTO,
                    CANTIDAD: nuevaCantidad
                }))
            } else {
                dispatch(addItemClientCart(clientInfo.UUID, clientInfo.EMAIL, {
                    ID_PRODUCTO: item.ID_PRODUCTO,
                    CANTIDAD: parseInt(state.CANTIDAD)
                }))
            }
        } else {
            addToCart({ ID_PRODUCTO: item.ID_PRODUCTO, CANTIDAD: state.CANTIDAD, PRECIO: item.PRECIO })
        }

        setState((state) => {
            return {
                ...state,
                AÑADIDO: true
            }
        })
    }
    useEffect(() => {
        const mediaQuery = window.matchMedia("(max-width: 600px)");
        setMobile(mediaQuery.matches);

        const handleResize = () => {
            setMobile(mediaQuery.matches);
        };

        mediaQuery.addEventListener("change", handleResize);
        return () => {
            mediaQuery.removeEventListener("change", handleResize);
        };
    }, []);
    
    useEffect(() => {

        if (logged) {
            if (clientInfo && clientInfo.FAVORITOS.length) {
                setState((state) => {
                    const isFavorite = clientInfo.FAVORITOS.length > 0 ? clientInfo.FAVORITOS.some((fav) => fav.ID_PRODUCTO === item.ID_PRODUCTO) : false;
                    return {
                        ...state,
                        FAVORITO: isFavorite,
                    }
                })
            }
            if (clientInfo && clientInfo.CARRITO.PRODUCTOS.length) {
                setState((state) => {
                    const isAlreadyAdded = (clientInfo && clientInfo.CARRITO.PRODUCTOS.length > 0) ? clientInfo.CARRITO.PRODUCTOS.some((added) => added.ID_PRODUCTO === state.ID_PRODUCTO) : false
                    return {
                        ...state,
                        AÑADIDO: isAlreadyAdded,
                    }
                })
            }
        }

        // Checkea si el item esta añadido al carrito del usuario no logeado.
        else {
            const isAlreadyAdded = isItemInCart()
            setState((state) => {
                return {
                    ...state,
                    AÑADIDO: isAlreadyAdded
                }
            })
        }


    }, [clientInfo.FAVORITOS, clientInfo.CARRITO && clientInfo.CARRITO.PRODUCTOS]);
    return (
        <div className='div-item-producto-block'>
            <div className='div-img-item-producto-common'>
                <Link to={`/app/${item.TIPO === 'PRODUCTO' ? 'pdpproductos' : 'pdpacompañamientos'}/${item.ID_PRODUCTO}`}>
                    {mobile ?
                        <Swiper
                            initialSlide={0}
                            slidesPerView={"auto"}
                            modules={[Navigation]}
                            navigation={{
                                nextEl: ".producto-item-carrousel-button-next",
                                prevEl: ".producto-item-carrousel-button-prev",
                                disabledClass: "swiper-button-disabled",
                                clickable: true,
                            }}
                            centeredSlides={true}
                            centeredSlidesBounds={true}
                            centerInsufficientSlides={true}
                            id={id}
                        >
                            <SwiperSlide>
                                <img src={state.IMG && state.IMG} alt='' />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={state.URL_IMAGEN_H} alt='' />
                            </SwiperSlide>
                            <div className="swiper-button-prev producto-item-carrousel-button-prev image-swiper-producto-item-carrousel-button"></div>
                            <div className="swiper-button-next producto-item-carrousel-button-next image-swiper-producto-item-carrousel-button"></div>
                        </Swiper>
                        :
                        <img className='img-item-producto-common' src={state.IMG && state.IMG}
                            onMouseEnter={() => (state.URL_IMAGEN_H ? setState((state) => ({ ...state, IMG: state.URL_IMAGEN_H })) : false)}
                            onMouseOut={() => setState((state) => ({ ...state, IMG: state.URL_IMAGEN }))} alt=""></img>
                    }

                </Link>
                {
                    logged ?
                        <button className='btn-item-producto-common-like' onClick={favoritoHandler}>
                            {state.FAVORITO === true ? <HeartFill></HeartFill> : <Heart></Heart>}
                        </button>
                        : null
                }
                <span className='span-item-producto-carrousel-common-img-descripcion'>{state.NOMBRE}</span>
            </div>
            <div className='div-cantidad-complementos-parrilla-item'>
                <button className='btn-item-producto-common-menos-mas' onClick={restarItem}>
                    <Dash></Dash>
                </button>
                <span className='text-color-white span-item-producto-common-contador'>{state.CANTIDAD}</span>
                <button className='btn-item-producto-common-menos-mas' onClick={sumarItem}>
                    <Plus></Plus>
                </button>
            </div>
            <div className='div-item-producto-common-precio'>
                <div className='div-item-producto-common-peso-aprox text-item-producto-common-peso'>
                    <span className='body-copy'>Aprox. 0.50 kg</span>
                    <span className='body-copy'>paquete</span>
                </div>
                <span className='text-color-white text-item-producto-common-precio'>{formatterPrice(state.TOTAL)}</span>
            </div>
            <div className='div-item-producto-common-agregar-item'>
                <button
                    className='btn-dorado btn-item-producto-common-agregar-item'
                    onMouseEnter={() => (state.URL_IMAGEN_H ? setState((state) => ({ ...state, IMG: state.URL_IMAGEN_H })) : false)}
                    onMouseOut={() => setState((state) => ({ ...state, IMG: state.URL_IMAGEN }))}
                    onClick={(e) => handleCart(e)}
                >
                    {buttonText ? buttonText : 'AÑADIR AL CARRITO'}
                </button>
            </div>
        </div>
    );
}

export default ProductoItemCarrousel