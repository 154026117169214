import { React, useEffect, useState } from 'react';
import { Stack, Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';

//--------------- Componentes -----------------------
import DeliveryAddress from '../../common/formulario/DeliveryAddress'
import DeliveryDateTime from '../../common/formulario/DeliveryDateTime'
import DeliveryContact from '../../common/formulario/DeliveryContact'
import CartOverview from './CartOverview.component'

// ----------------------------------------------------
import './Checkout.css'
import '../../index.css'
import '../../App.css'
import '../../common/formulario/formularios.css'
import 'bootstrap/dist/css/bootstrap.min.css';

function CheckoutDelivery({ properties }) {
  const { formCheckout } = useSelector((state) => state)
  const { setSelectedPage, messageToast, setMessageToast } = properties
  const [iTotalWeight, setTotalWeight] = useState(0)
 
  const handleTotalWeight = () => {
    let updatedTotalWeight = 0;
    Object.keys(formCheckout.PRODUCTOS).forEach((PRODUCTO) => {
      let cantidad = parseFloat(formCheckout.PRODUCTOS[PRODUCTO].CANTIDAD);
      let peso = parseFloat(formCheckout.PRODUCTOS[PRODUCTO].PESO);
      updatedTotalWeight += parseFloat((peso * cantidad).toFixed(2));
    });
    setTotalWeight(updatedTotalWeight);
  };

  const handleSubmit = () => {
    if (iTotalWeight < 2) {
      setMessageToast(true)
    } else {
      setSelectedPage('payment')
    }
  };


  useEffect(() => {
    handleTotalWeight()
  }, [formCheckout.PRODUCTOS])
  return (
    <Stack className="form-container">
      <CartOverview cardsEnabled={true} />
      <Stack>
        <DeliveryAddress title='Dirección' subtitle='Selecciona la dirección' />
      </Stack>
      <Stack className="delivery-info">
        <DeliveryDateTime title='Información de entrega' subtitle='Seleccione fecha y hora de tu entrega.' />
      </Stack>
      <Stack className="contact-info">
        <DeliveryContact title='Información de contacto' subtitle='Información del comprador' />
      </Stack>
      <Row>
        <Col lg={true}><button className="btn-dorado btn-submit" onClick={() => handleSubmit()}>Confirmar pedido</button></Col>
      </Row>
    </Stack>
  )
}

export default CheckoutDelivery