import MapaItem from './mapa-item.component';
import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";

import './mapa-item.component.css';
import './carousel-mapa.component.css';

function CarouselMapa() {
    var urlBase = "/images/desktop/mapas/";

    const [mobile, setMobile] = useState(false);

    const [hoveredIndex, setHoveredIndex] = useState(null);

    const handleMouseOver = (index) => {
        setHoveredIndex(index);
    };

    const handleMouseOut = () => {
        setHoveredIndex(null);
    };

    useEffect(() => {
        const mediaQuery = window.matchMedia("(max-width: 600px)");
        setMobile(mediaQuery.matches);

        const handleResize = () => {
            setMobile(mediaQuery.matches);
        };

        mediaQuery.addEventListener("change", handleResize);
        return () => {
            mediaQuery.removeEventListener("change", handleResize);
        };
    }, []);

    return (
        <div className='background-seccion-encuentranos'>
            <div className='gradient-div'></div>
            <h1 className='h1-encuentranos'>ENCUÉNTRANOS EN:</h1>
            <Swiper 
                className='swipper-mapa-carrusel'
                style={{
                    width: '100%',
                    marginTop: '30px',
                }}
                navigation={{
                    nextEl: ".image-swiper-mapa-button-next",
                    prevEl: ".image-swiper-mapa-button-prev",
                    disabledClass: "swiper-button-disabled"
                }}
                slidesPerView={mobile ? 2 : 7}
                spaceBetween={7}
                modules={[Navigation]}
                //Añadido para alinear al centro el Mapa
                wrapperClass= 'swiper-wrapper itemsAlign'
                id='swipper-mapa'
            >
                <div className="swiper-button-prev image-swiper-mapa-button-prev image-swiper-mapa-button"></div>
                <div className="swiper-button-next image-swiper-mapa-button-next image-swiper-mapa-button"></div>
                {
                    //[0, 1, 2, 3, 4, 5, 6].map((index) => ( ....)
                    //SOLO YUCATÁN
                    [6].map((index) => (
                    <SwiperSlide
                        key={index}
                        onMouseOver={() => handleMouseOver(index)}
                        onMouseOut={handleMouseOut}
                        style={{alignItems: 'flex-start'}}
                    >
                        <MapaItem
                            img={hoveredIndex === index ? urlBase + `a-${getFotoByIndex(index)}.svg` : urlBase + `i-${getFotoByIndex(index)}.svg`}
                            estado={getEstadoByIndex(index)}
                            ciudades={[getCiudadesByIndex(index)]}
                        />
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    )
};

function getEstadoByIndex(index) {
    const estados = ['CAMPECHE', 'CHIAPAS', 'GUANAJATO', 'PUEBLA', 'QUINTANA ROO', 'TABASCO', 'YUCATÁN'];
    return estados[index];
}

function getFotoByIndex(index) {
    const estados = ['campeche', 'chiapas', 'guanajuato', 'puebla', 'quintana', 'tabasco', 'yucatan'];
    return estados[index];
}

function getCiudadesByIndex(index) {
    const ciudades = [['Cd. De Campeche'], ['Tuxtla Gutiérrez'], ['Irapuato. ', 'Próximamente León'], ['Cd. De Puebla'], ['Cancún. ', 'Playa del Carmen (Solidaridad)'], ['Villa Hermosa'], ['Mérida']];
    return ciudades[index];
}

export default CarouselMapa;