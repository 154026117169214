import React, { useEffect, useState } from 'react'
import { Stack, Breadcrumb, Alert } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'

//--------------- Componentes -----------------------
import HeroBanner from '../../common/hero-banner.common'
import CheckoutDelivery from './CheckoutDelivery'
import CheckoutPayment from './CheckoutPayment';
// ----------------------------------------------------

import { addItemClientCart, getClientPedidos, getProductosHistorico, postPedidoUsuarioLogeado, postPedidoUsuarioNoLogueado, setLoader, validateForm } from 'redux/actions';
import { addCardHSBC, autorizarPedidoHSBC } from 'redux/actions/servicioHSBC';
import { resetCardForm } from '../../redux/actions';
import { getBannerImg } from 'utils/getImages';
import { checkForm, validarCampos } from 'utils/validator';

//------------- Servicio carrito no logueado -----------
import useUnloggedCart from 'redux/actions/unloggedCart';

import './Checkout.css'
import '../../index.css'
import '../../App.css'
import '../../common/formulario/formularios.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import MessageToast from './MessageToast.component';

function Checkout() {
  const { getCartTotal, getCartItems } = useUnloggedCart()
  const dispatch = useDispatch();
  const { logged, formCheckout, clientInfo, validations, confirmacionPedido } = useSelector((state) => state);
  const state = useSelector((state) => state)
  const [selectedPage, setPage] = useState('delivery');
  const [showMessageError, setMessageError] = useState(false);
  const [messageToast, setMessageToast] = useState(false)
  const navigate = useNavigate();

  // deliveryValidator devuelve un booleano "false" impide continuar al pago. True es que está todo correcto.
  const deliveryValidator = () => {

    // Variables declaradas para formularios ya validados (objeto)
    let oValidateCONTACT_FORM = {}
    let oValidateADDRESS_FORM = {}

    // Variable que retorna un booleano, representa el resultado de la validacion: false - NO VÁLIDO
    let isValid = false

    if (logged) {
      // Valido informacción de contacto
      oValidateCONTACT_FORM = validarCampos({ ...formCheckout.CONTACT_FORM, COD_POSTAL: clientInfo.COD_POSTAL ? clientInfo.COD_POSTAL : clientInfo.FACT_COD_POSTAL ? clientInfo.FACT_COD_POSTAL : '' })
      dispatch(validateForm('CONTACT_FORM', oValidateCONTACT_FORM))

      // Validar el formulario de direccion de entrega (solo si seleccionó "Otra")
      if (formCheckout.SELECTED_ADDRESS === 'Otra') {
        oValidateADDRESS_FORM = validarCampos(formCheckout.ADDRESS_FORM)
        dispatch(validateForm('ADDRESS_FORM', oValidateADDRESS_FORM))
        isValid = checkForm(oValidateADDRESS_FORM) && checkForm(oValidateCONTACT_FORM)

      } else {
        isValid = checkForm(oValidateCONTACT_FORM)
      }
    } else {
      // Validar direccion de entrega
      oValidateADDRESS_FORM = validarCampos(formCheckout.ADDRESS_FORM)
      dispatch(validateForm('ADDRESS_FORM', oValidateADDRESS_FORM))
      let postalCodeTyped = `CP--checkout`
      oValidateCONTACT_FORM = validarCampos(formCheckout.CONTACT_FORM)
      dispatch(validateForm('CONTACT_FORM', { ...oValidateCONTACT_FORM, COD_POSTAL: state[postalCodeTyped] ? state[postalCodeTyped] : null }))
      isValid = (checkForm(oValidateADDRESS_FORM) && checkForm(oValidateCONTACT_FORM))
    }

    // Set de los mensajes de error
    if (formCheckout.SELECTED_ADDRESS === 'Otra' && !checkForm(oValidateADDRESS_FORM)) setMessageError('Por favor, verifique todos los campos de la dirección de entrega.');
    if (logged) {
      if (!checkForm(oValidateCONTACT_FORM)) setMessageError('Antes de continuar, debe completar sus datos personales y datos de facturación en la sección "Mi datos".');
    } else if (!checkForm(oValidateCONTACT_FORM)) setMessageError('Por favor, complete todos los campos de la información de contacto.');
    if (checkForm(oValidateADDRESS_FORM) && checkForm(oValidateCONTACT_FORM)) setMessageError(false)

    // let iTotalWeight = 0
    // Object.keys(formCheckout.PRODUCTOS).forEach((PRODUCTO) => {
    //   let cantidad = parseFloat(formCheckout.PRODUCTOS[PRODUCTO].CANTIDAD);
    //   let peso = parseFloat(formCheckout.PRODUCTOS[PRODUCTO].PESO);
    //   iTotalWeight += parseFloat((peso * cantidad).toFixed(2));
    // });
    // if (iTotalWeight < 2) {
    //   setMessageToast(true)
    // }

    return isValid
  }

  // paymentValidator valida formulario de tarjeta y datos de facturación, luego hace el request para la compra.
  const paymentValidator = () => {
    let oValidateCARD_FORM = validarCampos(formCheckout.CARD_FORM)
    dispatch(validateForm('CARD_FORM', oValidateCARD_FORM))

    let oValidateBILLING_FORM = validarCampos(formCheckout.BILLING_FORM);

    if (!validations.global.REQUIRES_INVOICE){ 
      Object.keys(formCheckout.BILLING_FORM).forEach((key) => {
        formCheckout.BILLING_FORM[key] = null;
      });
      Object.keys(oValidateBILLING_FORM).forEach((key) => {
        oValidateBILLING_FORM[key] = null;
      });
    }
    dispatch(validateForm('BILLING_FORM', oValidateBILLING_FORM))

    if (!checkForm(oValidateCARD_FORM)) setMessageError('Por favor, complete todos los campos de la información de tarjeta.');
    if (!checkForm({ ...oValidateBILLING_FORM, NUMERO_INT: null })) setMessageError('Por favor, complete todos los campos de los datos de facturación.');
    if (!checkForm(oValidateCARD_FORM) || !checkForm(oValidateBILLING_FORM)) return false

    const user = {
      id: logged ? clientInfo.UUID : formCheckout.CONTACT_FORM.EMAIL,
      email: logged ? clientInfo.EMAIL : formCheckout.CONTACT_FORM.EMAIL
    }

    const CVC = formCheckout.CARD_FORM.VencimientoTarjeta.split("/");

    const DATOS_TARJETA = {
      user: user,
      card: {
        number: formCheckout.CARD_FORM.NumeroTarjeta,
        holder_name: formCheckout.CARD_FORM.NombreTarjeta,
        expiry_month: parseInt(CVC[0]),
        expiry_year: parseInt(new Date().getFullYear().toString().slice(0, 2) + CVC[1]),
        cvc: formCheckout.CARD_FORM.CVCTarjeta
      }
    }

    if (logged) {

      let direccion = { ID_DIRECCION: formCheckout.SELECTED_ADDRESS };
      if (formCheckout.SELECTED_ADDRESS === "Otra") {
        direccion = formCheckout.ADDRESS_FORM;
      }

      const BODY_OBJ = {
        DATOS_FACTURA: {
          ...formCheckout.BILLING_FORM,
          REQUIRES_INVOICE: validations.global.REQUIRES_INVOICE
        },
        DIRECCION: direccion,
        UUID: clientInfo.UUID,
        EMAIL: clientInfo.EMAIL,
        DATOS_CLIENTE: { EMAIL: clientInfo.EMAIL }
      }

      const DATOS_PEDIDO = {
        user: user,
        order: {
          dev_reference: "Usuario Logeado | Selectos Keken",
          amount: parseFloat(parseFloat(formCheckout.TOTAL).toFixed(2)),
          description: `Cortes Selectos Kekén - Usuario ${clientInfo.EMAIL}`,
          vat: 0.00
        }
      }
      dispatch(setLoader(true))
      
      
      addCardHSBC(DATOS_TARJETA).then((token) => {
        dispatch(postPedidoUsuarioLogeado(clientInfo.UUID, clientInfo.EMAIL, { ...BODY_OBJ, TOKEN_TARJETA: token }))
      }).catch((error) => {
        dispatch(setLoader(false))
        console.log(error)
      })

    } else {
      const postalCodeTyped = `CP--checkout`
      const carritoLocalStorage = getCartItems()
      const BODY_OBJ = {
        DATOS_FACTURA: {
          ...formCheckout.BILLING_FORM,
          REQUIRES_INVOICE: validations.global.REQUIRES_INVOICE
        },
        DIRECCION: { ...formCheckout.ADDRESS_FORM, COD_POSTAL: state[postalCodeTyped] },
        DATOS_CLIENTE: {
          NOMBRE: formCheckout.CONTACT_FORM.NOMBRE,
          APELLIDO: formCheckout.CONTACT_FORM.APELLIDO,
          EMAIL: formCheckout.CONTACT_FORM.EMAIL,
          TELEFONO: formCheckout.CONTACT_FORM.TELEFONO
        },
        CARRITO: carritoLocalStorage.map(objeto => {
          const { PRECIO, CANTIDAD, ...objetoSinPrecio } = objeto;
          objetoSinPrecio.CANTIDAD = parseInt(CANTIDAD)
          return objetoSinPrecio;
        })
      }

      dispatch(setLoader(true))
      
      addCardHSBC(DATOS_TARJETA).then((token) => {
        dispatch(postPedidoUsuarioNoLogueado({ ...BODY_OBJ, TOKEN_TARJETA: token }))
      }).catch((error) => {
        dispatch(setLoader(false))
        console.log(error)
      })
    }
  }

  const setSelectedPage = (sPage) => {
    if (sPage === 'payment' && !deliveryValidator()) {
      return;
    }
    if (sPage === 'complete') {
      paymentValidator()
      return;
    }
    if(sPage === 'delivery'){
      dispatch(resetCardForm());
    }
    setPage(sPage);
  }

  useEffect(() => {
    if (confirmacionPedido.PEDIDO_REALIZADO) {
      if (logged) {
        dispatch(getClientPedidos(clientInfo.UUID, clientInfo.EMAIL))
        dispatch(getProductosHistorico(clientInfo.UUID, clientInfo.EMAIL))
        navigate('/app/cuenta/pedido-confirmado')
      } else {
        navigate('/app/cuenta/pedido-confirmado')
      }
    }
  }, [confirmacionPedido.PEDIDO_REALIZADO])

  return (
    <div>
      <div className="checkout" style={{ filter: messageToast ? 'blur(2px)' : 'none' }} >
        <div className="checkout-banner">
          {selectedPage === 'delivery' ?
            <HeroBanner title='Información </br> de entrega' subtitle='Conoce nuestra variedad de cortes selectos de cerdo' backgroundImg={getBannerImg("InformacionEntrega")}></HeroBanner>
            :
            <HeroBanner title='Información </br> de pago' subtitle='Conoce nuestra variedad de cortes selectos de cerdo' backgroundImg={getBannerImg("InformacionEntrega")}></HeroBanner>
          }
        </div>
        <section className="gradient-bg">
          <Stack className='container checkout-body'>
            <Alert show={showMessageError} variant='danger'>{showMessageError}</Alert>
            <Breadcrumb>
              <Breadcrumb.Item className='breadcrumb-item' href="#" active={selectedPage === 'delivery'} onClick={() => setSelectedPage('delivery')}>
                <p className={selectedPage === 'delivery' ? 'checkout-selected-page' : 'checkout-unselected-page'}>Datos de entrega</p>
              </Breadcrumb.Item>
              <Breadcrumb.Item href="#" active={selectedPage === 'payment'} onClick={() => setSelectedPage('payment')}>
                <p className={selectedPage === 'payment' ? 'checkout-selected-page' : 'checkout-unselected-page'}>Proceso de pago</p>
              </Breadcrumb.Item>
            </Breadcrumb>
            {selectedPage === 'delivery' && <CheckoutDelivery properties={{ setSelectedPage, messageToast, setMessageToast }} />}
            {selectedPage === 'payment' && <CheckoutPayment properties={{ setSelectedPage }} />}
            <Alert style={{ marginTop: '30px' }} show={showMessageError} variant='danger'>{showMessageError}</Alert>
          </Stack>
        </section>
      </div>
      <MessageToast
        title='IMPORTANTE'
        message='Recuerda que tu pedido es sin costo, sin embargo, el pedido mínimo es de 2 kg.'
        btnPath1='/app/productos'
        actionText1='Comprar'
        btnPath2='/login'
        actionText2=' Inicia sesión'
        actionText3='¿Ya tienes cuenta?'
        visible={messageToast}
        handleToast={setMessageToast} />
    </div>
  )
}

export default Checkout