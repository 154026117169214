//import axios from "axios";
import http from "../../utils/http";
import Swal from "sweetalert2";

function getNumericStringFromErrorType(errorType) {
    const numericString = errorType.match(/\d+/);

    if (numericString && numericString.length > 0) {
        return numericString[0];
    }
}

export function addCardHSBC(DATOS_TARJETA) {
    return new Promise((resolve, reject) => {
        http.post(`/apikuo-hsbc/card/add`, JSON.stringify(DATOS_TARJETA)).then(response => {
            if (response.data.card.status === 'rejected') {
                Swal.fire({
                    title: 'Tu tarjeta fue rechazada',
                    icon: 'error',
                    showCloseButton: true,
                    confirmButtonText: 'Aceptar',
                    confirmButtonColor: '#E5CB65',
                    background: '#333',
                    color: '#FFF'
                });
                // Rechaza la promesa con un error indicando que la tarjeta fue rechazada
                reject(new Error('Tarjeta rechazada'));
            } else {
                resolve(response.data.card.token);
            }
        }).catch(error => {
            if (!error.unauthorized) {
                if (error.response.data.error.type) {
                    const token = getNumericStringFromErrorType(error.response.data.error.type);
                    if (token) {
                        resolve(token)
                        return
                    }
                }
                Swal.fire({
                    title: 'Por favor, valide los datos de la tarjeta e intente nuevamente.',
                    icon: 'warning',
                    showCloseButton: true,
                    confirmButtonText: 'Aceptar',
                    confirmButtonColor: '#E5CB65',
                    background: '#333',
                    color: '#FFF'
                })
            }
            reject(error)
        })
    })
}

export async function autorizarPedidoHSBC(DATOS_PEDIDO, TOKEN_TARJETA) {

    DATOS_PEDIDO.card = { token: TOKEN_TARJETA }
    return new Promise((resolve, reject) => {
        http.post(`/apikuo-hsbc/transaction/authorize/`, JSON.stringify(DATOS_PEDIDO)).then(response => {
            http.post(`/apikuo-hsbc/card/delete/`, JSON.stringify({
                card: {
                    token: TOKEN_TARJETA
                },
                user: {
                    id: DATOS_PEDIDO.user.id
                }
            }))
            resolve(response.data.transaction.id);
        }).catch(reject)
    });
}