// unloggedCart.js
import { useDispatch } from 'react-redux';
import actionTypes from './actionTypes';
import { toast } from 'react-toastify';
const useUnloggedCart = () => {

  const dispatch = useDispatch();
  // Agregar un item al carrito
  const addToCart = (item) => {
    const existingCartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
    const existingItemIndex = existingCartItems.findIndex((cartItem) => cartItem.ID_PRODUCTO === item.ID_PRODUCTO);

    if (existingItemIndex !== -1) {
      // Si el producto ya existe en el carrito, sumamos la cantidad
      existingCartItems[existingItemIndex].TOTAL = (parseFloat(parseFloat(existingCartItems[existingItemIndex].CANTIDAD) + parseInt(item.CANTIDAD)) * parseFloat(item.PESO)) * parseFloat(existingCartItems[existingItemIndex].PRECIO)
      existingCartItems[existingItemIndex].CANTIDAD = parseInt(parseInt(existingCartItems[existingItemIndex].CANTIDAD) + parseInt(item.CANTIDAD));

    } else {
      // Si el producto no existe en el carrito, lo agregamos al array de items
      existingCartItems.push({ ...item, TOTAL: (parseInt(item.CANTIDAD) * parseFloat(item.PESO)) * parseFloat(item.PRECIO) });

      // Pequeño mensaje confirmación para que el usuario sepa que funcionó
      toast.success('Producto añadido al carrito.', {
        position: toast.POSITION.BOTTOM_LEFT,
        autoClose: 3000,
        theme: "dark"
      });
    }

    localStorage.setItem('cartItems', JSON.stringify(existingCartItems));
    updateTotal(existingCartItems); // Actualizar el total del carrito

    dispatch({
      type: actionTypes.ADD_TO_CART,
      payload: existingCartItems,
    });
  };


  // Eliminar un item del carrito por su ID_PRODUCTO
  const removeFromCart = (ID_PRODUCTO) => {
    const existingCartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
    const updatedCartItems = existingCartItems.filter((item) => item.ID_PRODUCTO !== ID_PRODUCTO);
    localStorage.setItem('cartItems', JSON.stringify(updatedCartItems));
    updateTotal(updatedCartItems); // Actualizar el total del carrito

    dispatch({
      type: actionTypes.REMOVE_FROM_CART,
      payload: updatedCartItems,
    });
  };

  // Limpiar el carrito completamente
  const clearCart = () => {
    localStorage.removeItem('cartItems');
    localStorage.removeItem('TOTAL'); // Remover la variable TOTAL del localStorage

    dispatch({
      type: actionTypes.CLEAR_CART,
      payload: 0,
    });
  };

  // Obtener todos los items del carrito
  const getCartItems = () => {
    const cartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
    dispatch({
      type: actionTypes.GET_CART_ITEMS,
      payload: cartItems,
    });

    updateTotal(cartItems)
    return cartItems

  };

  // Función para actualizar el total del carrito
  const updateTotal = (cartItems) => {
    const newTotal = cartItems.reduce((acc, item) => acc + parseFloat(item.PRECIO) * item.CANTIDAD, 0);
    localStorage.setItem('TOTAL', newTotal); // Actualizar el TOTAL en el localStorage
    dispatch({
      type: actionTypes.UPDATE_CART_TOTAL,
      payload: parseFloat(parseFloat(newTotal).toFixed(2)),
    });
  };

  // Verificar si un producto ya está añadido al carrito
  const isItemInCart = (ID_PRODUCTO) => {
    const existingCartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
    const isInCart = existingCartItems.some((item) => item.ID_PRODUCTO === ID_PRODUCTO);
    return isInCart
  };

  const getCartTotal = () => {
    const cartItems = JSON.parse(localStorage.getItem('cartItems')) || [];

    if (cartItems.length === 0) {
      return 0; // Retorna 0 si el carrito está vacío
    }

    const total = cartItems.reduce((total, item) => total + item.PRECIO * item.CANTIDAD, 0);
    return total;
  };

  // Función para realizar el merge de dos arrays de items del carrito
  const mergeCarts = (cartFromDB, cartFromLocalStorage) => {
    const mergedCart = [...cartFromDB];

    cartFromLocalStorage.forEach((item) => {
      const existingItem = mergedCart.find((cartItem) => cartItem.ID_PRODUCTO === item.ID_PRODUCTO);
      if (!existingItem) {
        mergedCart.push(item);
      }
    });
    return mergedCart;
  };

  return {
    addToCart,
    removeFromCart,
    clearCart,
    getCartItems,
    isItemInCart,
    getCartTotal,
    mergeCarts,
  }
}


export default useUnloggedCart;