import { Link } from 'react-router-dom';
import ItemCard from '../../components/carrito/ItemCard.jsx';
import HeroBanner from '../../common/hero-banner.common';
import { getBannerImg } from 'utils/getImages.js';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import CrossSell from 'common/cross-sell/cross-sell.common.jsx';
import { getClientCart, updateCheckoutForm } from 'redux/actions/index.js';
import { resetFormCheckout } from '../../redux/actions';
import { formatterPrice } from 'utils/formatter.js';

import 'bootstrap/dist/css/bootstrap.min.css';
import './carrito.page.css';
import '../../App.css';
import '../../index.css';

function Carrito() {

  const dispatch = useDispatch()
  const { acompañamientos, formCheckout, logged, guestInfo } = useSelector((state) => state)
  //Nueva logica para items y total
  const [productos, setProductos] = useState([]);
  const [totalPrice, setTotalPrice] = useState(formCheckout.TOTAL);
  const [mobile, setMobile] = useState(false);

  useEffect(() => {
    setProductos(Object.values(formCheckout.PRODUCTOS));
  }, [formCheckout.PRODUCTOS])

  useEffect(() => {
    setTotalPrice(formCheckout.TOTAL || 0);
  }, [formCheckout.TOTAL])

  // Setea si es mobile o no, según el width del viewport.
  useEffect(() => {
    const handleMediaQuery = (event) => {
      setMobile(event.matches);
    };
    const mediaQuery = window.matchMedia('(max-width: 600px)');
    setMobile(mediaQuery.matches);
    mediaQuery.addEventListener('change', handleMediaQuery);
    return () => {
      mediaQuery.removeEventListener('change', handleMediaQuery);
    };
  }, []);

  //Limpiar forms de checkout
  useEffect(() => {
    dispatch(resetFormCheckout());
  }, [])

  return (
    <div>
      <div className="cart">
        <HeroBanner title='TU CARRITO DE COMPRAS' backgroundImg={getBannerImg("Carrito")} />
      </div>

      <section className='cont-flex purchases-section'>
        <div className="cont-flex purchases-sub-container">
          <div className="cont-block purchases-items-container">
            {productos.length > 0 ? productos.map(item => <ItemCard PRODUCTO={item} />) : null}
            <div className="cont-block purchases-buy-more-container">
              {parseInt(totalPrice) === 0 ? <h2 className="title-h2 title-delivery-status">Aún no tienes productos en tu carrito.</h2> : null}
              {mobile ?
                <p>¿Llevas lo necesario para tu parrilla gloriosa?<br /> Revisa más productos para complementarla.</p>
                :
                <p>¿Llevas lo necesario para tu parrilla gloriosa? Revisa más productos para complementarla.</p>
              }
              <div className="cont-flex purchases-links-container">
                <Link className='carrito-link-action' to="/app/productos">Nuestros Productos</Link>
                <Link className='carrito-link-action' to="/app/acompañamientos">Acompañamientos</Link>
              </div>
            </div>
          </div>

          {
            parseInt(totalPrice) > 0 ?
              <div className="cont-block purchases-total-container">
                <p>Esto es lo que llevas hasta el momento.</p>
                <p>Realiza el pago de tu pedido.</p>
                <span>{formatterPrice(parseFloat(totalPrice).toFixed(2))}</span>
                <Link to="/app/checkout" className="btn-dorado">Comprar</Link>
              </div>
              :
              null
          }

        </div>
      </section>

      <div className="cont-cross-sell">
        <div className='cards-cont'>
          {acompañamientos.length ? <CrossSell backgroundImage={true} titulo={"COMPLEMENTA TU PARRILLA"} subtitulo={"Conoce los complementos para carne asada que te ayudarán a conquistar la parrilla."}
            items={acompañamientos} /> : null}

        </div>
      </div>
    </div >
  );
}

export default Carrito;