import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Stack } from 'react-bootstrap'
import { parseDireccion } from '../../utils/utils'
import HeroBanner from '../../common/hero-banner.common'
import ProductoComprado from '../../components/confirmacion/ProductoComprado'
import { getBannerImg } from 'utils/getImages'
import { setPedidoRealizado } from 'redux/actions'

import '../../index.css'
import '../checkout/Checkout.css'
import '../../App.css'
import '../../common/formulario/formularios.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import urlSubcuenta from 'utils/urlSubcuenta'

function Confirmacion() {
  const { logged, confirmacionPedido } = useSelector((state) => state)
  const [direccion, setDireccion] = useState([])
  const [totalPesos, setTotalPesos] = useState([])
  const [productos, setProductos] = useState([])
  const dispatch = useDispatch();

  const handleLogin = () => {
    if (!confirmacionPedido.PEDIDO_REALIZADO) {
      // Realizar el redireccionamiento con el historial en el navegador
      window.location.href = urlSubcuenta() + '/login/';
    }
  };

  useEffect(() => {
    if (confirmacionPedido && confirmacionPedido.DATA && confirmacionPedido.DATA.DIRECCION) {
      setDireccion(parseDireccion(confirmacionPedido.DATA.DIRECCION))
      setTotalPesos(confirmacionPedido.DATA.TOTAL_PESOS)
    }
  }, [confirmacionPedido.DATA]);

  useEffect(() => {
    if (confirmacionPedido && confirmacionPedido.PRODUCTOS && confirmacionPedido.PRODUCTOS.length > 0) {
      setProductos(confirmacionPedido.PRODUCTOS)
    }else {
      window.location.href = urlSubcuenta() + '/app/';
    }
  }, [confirmacionPedido.PRODUCTOS]);

  useEffect(() => {
    if (confirmacionPedido.PEDIDO_REALIZADO) {
      dispatch(setPedidoRealizado(false))
    }
  }, [confirmacionPedido.PEDIDO_REALIZADO])

  return (
    <div className='checkout-confirmacion'>
      <div className="checkout-banner">
        <HeroBanner title='Tu pedido se realizó' subtitle={false} backgroundImg={getBannerImg("PedidoRealizado")} />
      </div>

      <section className="gradient-bg">
        <Stack orientation='vertical' className='container checkout-body' gap={4}>
          <Stack>
            <h2 className="title-h2">Tu compra se ha realizado con éxito</h2>
            {/*<p className='item-label'>Recibimos tu pedido, y en las próximas horas recibirás la confirmación del envío. Puedes rastrearlo por este medio o contactarnos para cualquier duda al +52 999 930 2222.</p>
            <a class="footer-link" href="https://api.whatsapp.com/send?phone=%2B529999302222" target="_blank">CONTÁCTANOS</a>*/}
            <p className='item-label-whatsapp'>
              Recibimos tu pedido, y en las próximas horas recibirás la confirmación del envío. Puedes rastrearlo por este medio o por cualquier duda contactarse al
              <a className='whatsapp-link' href="https://api.whatsapp.com/send?phone=%2B529999302222" target="_blank">
                <span className='whatsapp-text'>WhatsApp</span>
                <img className='whatsappImage' src="/images/icons/whatsapp-logo-png.png" />
              </a>
            </p>
          </Stack>

          <Stack className='checkout-conf-cont' gap={0}>
            <h3 className="title-h3">Información de entrega:</h3>
            <p className='item-label'>Si tu pedido lo realizas entre las 7:00am y 12:00 pm se entregará el mismo día en un tiempo 60 a 90 minutos una vez confirmado el pedido. (Excepto Domingos).

              Si tu pedido lo realizas después de las 12:00 pm, tu pedido se entregará al día siguiente.

              Las entregas de productos se realizan de lunes a sábado de 7:30am a 2:00pm.</p>
            {/*<p className='item-label'>Día:<span className='item-value'>{fechaHora.weekday} {fechaHora.day} de {fechaHora.month}</span></p>
            <p className='item-label'>Día:<span className='item-value'>Jueves 7 de febrero</span></p>
            <p className='item-label'>Hora:<span className='item-value'>{fechaHora.hour}:{fechaHora.minutes}</span></p>
            <p className='item-label'>Hora:<span className='item-value'>1:30 pm</span></p>*/}
            <p className='item-label'>Direccion:<span className='item-value'>{direccion}</span></p>
          </Stack>


          <Stack>
            <h3 className="title-h3">Información de pedido:</h3>
            {/*<p className='item-label'>Total de la compra:<span className='item-value'>$570.00</span></p>*/}
            <p className='item-label'>Total de la compra:<span className='item-value'>${totalPesos}</span></p>
          </Stack>

          <Stack>
            <span className='warning-text'>
              Ten en cuenta que el peso de los productos es estimado, por lo que el total del importe de tu pedido puede variar en el cobro final. Con base en el peso final del producto, se ajustará el importe final y éstos se reflejarán en tu ticket de compra al entregar el producto.
            </span>
          </Stack>

          <Stack className='productos-comprados-main'>
            <Stack direction='horizontal' className='container productos-comprados-cont'>
              {productos.length > 0 ? (
                productos.map((p) => {
                  return (
                    <ProductoComprado
                      item={p} />
                  )
                })
              ) : null}
              {/*
                acompañamientos.map((item) => {
                  return (
                    <ProductoComprado
                      item={item} />
                  )
                })
              */}
            </Stack>
          </Stack>

          {
            !logged &&
            <Stack>
              <Stack style={{ alignItems: 'center' }}>
                <p>Para una mejor experiencia, crea tu cuenta aquí.</p>
                <Link title="Registrarme" onClick={handleLogin} className="btn-dorado btn-submit" >Registrarme</Link>
              </Stack>
            </Stack>
          }
        </Stack>
      </section>
    </div>
  )
}

export default Confirmacion