import ItemProductoDescripcion from '../../common/item-producto/item-producto-descripcion.common';
import CrossSell from '../../common/cross-sell/cross-sell.common';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';


function PDPAcompañamientos() {
  const { id } = useParams();
  const { productos } = useSelector((state) => state);

  return (
    <div className='div-pdp-container'>
      <div className='pdp-carr-cont'>
        <ItemProductoDescripcion productoID={id} />
      </div>
      <CrossSell backgroundImage={true} titulo={"Todo el sabor del cerdo a la parrilla"} subtitulo={"Conoce nuestra variedad de cortes de carne de cerdo."} items={productos} />
    </div>
  );
}

export default PDPAcompañamientos;