import React, { useState, useEffect } from 'react'
import { Row, Col, Form } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'

import '../../common/formulario/formularios.css'
import '../../App.css'
import '../../index.css'
import './UserInformation.css'

import { validate } from 'utils/validator';
import { updateCheckoutForm, validateForm } from '../../redux/actions/index'

function UserInformation({ editMode }) {
    const dispatch = useDispatch()
    const { clientInfo, validations } = useSelector((state) => state);

    const renderForm = [
        [{ value: 'NOMBRE', placeholder: 'Ingrese su nombre completo' }, { value: 'APELLIDO', placeholder: 'Ingrese su apellido' }],
        [
            {
                value: 'EMAIL',
                placeholder: 'Ingrese su correo electrónico',
                validations: {
                    match: "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+[.]{1}[a-zA-Z]{2,}$"
                }
            },
            {
                value: 'TELEFONO',
                placeholder: 'Ingrese su teléfono'
            }
        ]
    ]

    const [form, setForm] = useState({
        NOMBRE: clientInfo.NOMBRE,
        APELLIDO: clientInfo.APELLIDO,
        EMAIL: clientInfo.EMAIL,
        TELEFONO: clientInfo.TELEFONO,
    });

    //Métodos
    const onChangeValue = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value }) //Actualiza el valor ingresado al formulario
    }
    const validateInput = (e) => {
        dispatch(validateForm('CLIENT_FORM', validate(e.target))) //Actualiza el estado global del campo
    }

    //Actualiza el formulario global
    useEffect(() => {
        dispatch(updateCheckoutForm({ CLIENT_FORM: form }))
    }, [form]);
    
    //Actualiza el formulario local al obtener los datos del cliente
    // useEffect(() => {
    //     var oForm = {
    //             NOMBRE: clientInfo.NOMBRE,
    //             APELLIDO: clientInfo.APELLIDO,
    //             EMAIL: clientInfo.EMAIL,
    //             TELEFONO: clientInfo.TELEFONO,
    //         },
    //         oValidationForm = {}
    //     Object.keys(oForm).forEach(sKey => {
    //         oValidationForm[sKey] = !!oForm[sKey] ? null : ''
    //     })

    //     dispatch(validateForm('CLIENT_FORM', oValidationForm))
    //     setForm(oForm)
    // }, [clientInfo.NOMBRE, clientInfo.APELLIDO, clientInfo.EMAIL, clientInfo.TELEFONO]);

    return (
        <div className='cuenta-mis-datos'>
            {editMode ?
                renderForm.map(aRows =>
                    <Row className="address-row">
                        {
                            aRows.map(oColumn =>
                                <Col lg={true}>
                                    <Form.Control disabled={!editMode} className={`address-input ${editMode ? 'golden-input' : 'grey-input'}`}
                                        type="text"
                                        isInvalid={validations.CLIENT_FORM[oColumn.value]}
                                        required={true}
                                        validations={JSON.stringify(oColumn.validations || {
                                            minSize: 1
                                        })}
                                        name={oColumn.value}
                                        placeholder={oColumn.placeholder || oColumn.value}
                                        value={form[oColumn.value]}
                                        onBlur={validateInput}
                                        onChange={onChangeValue} />
                                    <Form.Control.Feedback className="input-feedback" type='invalid'>{validations.CLIENT_FORM[oColumn.value]}</Form.Control.Feedback>
                                </Col>
                            )
                        }
                    </Row>
                )
                :
                <div className='not-editando'>
                    <div className='data-container'>
                        <p>Nombre: <b>{form.NOMBRE}</b></p>
                        <p>Apellido: <b>{form.APELLIDO}</b></p>
                    </div>
                    <div className='data-container'>
                        <p>Correo electrónico: <b>{form.EMAIL}</b></p>
                        <p>Teléfono: <b>{form.TELEFONO}</b></p>
                    </div>
                </div>
            }
        </div>
    )
}
export default UserInformation