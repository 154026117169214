import { Button } from 'bootstrap';
import React from 'react'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import './Checkout.css'
import urlSubcuenta from 'utils/urlSubcuenta';
function MessageToast({ visible, title, message, btnPath1, btnPath2, actionText1, actionText2, actionText3, handleToast }) {

    const { logged } = useSelector((state) => state);

    const onClose = () => {
        handleToast(!visible)
    }

    const handleLogin = () => {
        if (!logged) {
            const uniqueParam = new Date().getTime();
            // Hacer la redirección al cerrar sesión
            window.location.href = urlSubcuenta() + `/do/logout?cache_bust=${uniqueParam}`;
        } else {
            window.location.href = urlSubcuenta() + '/app/';
        }
    };
    return (
        <>
            {
                visible ?
                    <div className='message-toast-cont' >
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div>
                                <h3 className='message-toast-title'>{title}</h3>

                                <span className='message-toast-message'>{message}</span>
                            </div>{ }
                            <button onClick={onClose} className='message-toast-btn-close closed-butotn'></button>
                        </div>
                        <div style={{ marginTop: '20px' }}>
                            <Link to={btnPath1}>
                                <button className={'btn-dorado btn-submit message-toast-btn'}>{actionText1}</button>
                            </Link>
                            <div className='message-toast-link-cont'>
                                <span className='message-toast-link'>
                                    {actionText3}
                                </span>

                                <Link className='message-toast-link-active' onClick={handleLogin}>{actionText2}</Link>
                            </div>
                        </div>
                    </div >
                    : null
            }
        </>
    );
}

export default MessageToast