import React, { useEffect } from 'react'
import { Stack } from 'react-bootstrap'
import HeroBanner from '../../common/hero-banner.common'
import { getBannerImg } from 'utils/getImages'

import '../../App.css'
import '../../index.css'
import { useDispatch, useSelector } from 'react-redux'
import { clearPostalCode } from 'redux/actions'
import { Link, useLocation } from 'react-router-dom'
import { Justify } from 'react-bootstrap-icons'
import urlSubcuenta from 'utils/urlSubcuenta'

function FueraZona() {
    const location = useLocation()
    const dispatch = useDispatch()
    const { logged } = useSelector(state => state);

    const handleLogin = () => {
        if (!logged) {
            // Realizar el redireccionamiento con el historial en el navegador
            window.location.href = urlSubcuenta() + '/login/';
        } else {
            window.location.href = urlSubcuenta() + '/app/';
        }
    };

    useEffect(() => {
        if (location.pathname.includes("fuera"))
            dispatch(clearPostalCode())
    }, [])


    return (
        <div className='fuera-de-zona'>
            <div className="checkout-banner">
                <HeroBanner title='Código postal </br> fuera de zona' backgroundImg={getBannerImg("FueraDeZona")}></HeroBanner>
            </div>
            <section className="gradient-bg">
                <Stack style={{ margin: '3rem 1rem', display:'flex', justifyContent:'center', minHeight:'20vh' }}>
                    <p style={{ color: 'white' }}>¡Oops! Por el momento nuestra cobertura no llega hasta tu territorio, pero seguimos creciendo para darte un mejor servicio.</p>
                    {
                        !logged && <button className='btn-dorado btn-register' onClick={handleLogin}>Registrarme</button>
                    }
                </Stack>
            </section>
        </div>
    )
}

export default FueraZona