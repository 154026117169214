import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import IlustracionCorteCerdo from '../../images/CERDITO-ECOMMERCE.png';
import CarouselCortesContainer from '../../components/carousel-cortes/carousel-cortes-container.component';
import CarouselMapa from '../../components/mapa/carousel-mapa.component';
import CrossSell from '../../common/cross-sell/cross-sell.common'
import PostalCode from '../../common/formulario/PostalCode.component'
import Whatsapp from 'common/whatsapp.common';
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import { Row, Col, Stack } from 'react-bootstrap'

import './home.page.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import "swiper/css";
import "swiper/css/pagination";

function Home() {
  const navigate = useNavigate();
  const state = useSelector((state) => state)

  const { productos, acompañamientos } = useSelector((state) => state)

  const [mobile, setMobile] = useState(false);
  const instance = `CP--Home`
  const postalCode = state[`postalCode--${instance}`]

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 600px)");
    setMobile(mediaQuery.matches);

    const handleResize = () => {
      setMobile(mediaQuery.matches);
    };

    mediaQuery.addEventListener("change", handleResize);
    return () => {
      mediaQuery.removeEventListener("change", handleResize);
    };
  }, []);


  useEffect(() => {
    if (typeof (postalCode) === 'object' && !postalCode.length) {
      navigate('/app/fuera-de-zona')
    }
  }, [postalCode])

  return (
    <div className='carrusel-container-home'>
      <a class="custom-position-whatsapp-icon-under">
        <Whatsapp />
      </a>
      <div className='carrusel-container-home'>
        <a class="custom-position-whatsapp-icon">
          <Whatsapp isHeader={true} />
        </a>
        <Swiper
          pagination={{
            clickable: true,
            renderBullet: function (index, className) {
              return '<span class="' + className + '"><img class="pagination-bullet"/></span>';
            }
          }}
          modules={[Pagination]}
          className="mySwiper carrusel-home"
        >
          <SwiperSlide>
            <div className='home-hero-img-container'>
              <div className='custom-position-title-carrusel'>
                <span className='text-color-white  custom-title-carrusel-home'>TODO EL <span className="text-color-gold custom-title-carrusel-home">SABOR DEL CERDO</span> AHORA A LA PARRILLA</span>
              </div>
              <div className='hero-calltoaction-container'>
                <Link to={`/app/productos`} className='hero-btn-container'>
                  <button className='btn-primary btn-carrusel-home'>COMPRAR</button>
                </Link>
              </div>
              <img className="img-carousel img-carousel-home" src={mobile ? '/images/home/m-header-carrusel-1.png' : '/images/home/header-carrusel-1.png'} alt="" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='home-hero-img-container'>
              <div className='custom-position-title-carrusel'>
                <span className='text-color-white  custom-title-carrusel-home'>Prueba el <sapan className="text-color-gold custom-title-carrusel-home">nuevo sabor</sapan> del descubrimiento</span>
              </div>

              <div className='hero-calltoaction-container'>
                <Link to={`/app/productos`} className='hero-btn-container'>
                  <button className='btn-primary btn-carrusel-home'>COMPRAR</button>
                </Link>
              </div>
              <img className="img-carousel img-carousel-home" src={mobile ? '/images/home/m-header-carrusel-2.png' : "/images/home/header-carrusel-2.png"} alt="" />

            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='home-hero-img-container'>
              <div className='custom-position-title-carrusel'>
                <span className='text-color-white custom-title-carrusel-home'>Cortes Selectos KEKÉN Para <sapan className="text-color-gold custom-title-carrusel-home">parrillas gloriosas</sapan></span>
              </div>
              <div className='hero-calltoaction-container'>
                <Link to={`/app/productos`} className='hero-btn-container'>
                  <button className='btn-primary btn-carrusel-home'>COMPRAR</button>
                </Link>
              </div>
              <img className="img-carousel img-carousel-home" src={mobile ? '/images/home/m-header-carrusel-3.png' : "/images/home/header-carrusel-3.png"} alt="" />
            </div>
          </SwiperSlide>
        </Swiper>
      </div>

      {/* <FavoritosCarrousel /> */}
      <CarouselCortesContainer />

      <CarouselMapa />

      <Stack className='background-seccion-validacion-cp'>
        <img className='triangulo-img' src='/images/home/encuentranos-triangulo.png'></img>
        <Stack className='cont-seccion-validacion-cp'>
          <div className='custom-validacion'>
            <h1 className='h1-validacion'>VALIDA TU CÓDIGO POSTAL</h1>
            <text className='txt-validacion'>Para validar la zona de cobertura, es necesario que especifiques el código postal al que se hará el envío de tu pedido.</text>
          </div>
          <PostalCode className='cp-home' id={`Home`} />
        </Stack>
      </Stack>

      <CrossSell isProductos={true} backgroundImage={false} titulo={"complementa tu parrilla"} subtitulo={"Conoce los complementos para carne asada que te ayudarán a conquistar la parrilla."}
        items={acompañamientos} id='home' />

      <Col className='custom-sub-footer-home'>
        <Row>
          <Col sm="12" md="12" xl="6" lg="6" className='bg-quien-es-keken-container'>
            <img src={IlustracionCorteCerdo} className="img-fluid"></img>
          </Col>
          <Col sm="12" md="12" xl="6" lg="6">
            <div className='custom-div-sub-footer-home-texto'>
              <div className='custom-div-sub-footer-home-texto-div'>
                <h1 className='custom-div-sub-footer-home-titulo'>¿QUIÉN ES KEKÉN?</h1>
                <hr className='hr-text'></hr>
                <text className='custom-div-sub-footer-home-div-texto'>Para los amantes de las parrillas que buscan la gloria en el sabor de la carne: Cortes
                  Selectos Kekén abre un nuevo y delicioso camino con productos de cerdo 100%
                  mexicanos respaldados por Kekén, líder en exportación a los mercados más
                  exigentes del mundo. Somos una aventura de sabor distinta y deliciosa. Somos el
                  placer del sabor del cerdo, ahora a la parrilla.<br></br>
                  Disfruta de la variedad en cortes selectos de cerdo que te ayudarán a sorprender a tus amigos y familiares cuando enciendas el fuego.</text>
                <div className='custom-div-sub-footer-home-btn'>
                  <Link to="https://keken.com.mx" target='_blank' className='navbar-button'>
                    <button className='btn-dorado custom-div-sub-footer-home-btn-dorado'>CONOCE MÁS</button>
                  </Link>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <div className='custom-div-logo-desc'>
          <img src="/images/logo/logo_desc.png" className="img-logo-desc" alt="Tu imagen" />
        </div>
      </Col>
    </div>
  );
}

export default Home;