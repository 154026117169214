
let urlBase = window.matchMedia('(max-width: 600px)').matches ? '/images/mobile' : '/images/desktop';
const updateUrlBase = () => {
  urlBase = window.matchMedia('(max-width: 600px)').matches ? '/images/mobile' : '/images/desktop';
};

window.addEventListener('resize', updateUrlBase);
window.addEventListener('load', updateUrlBase);


function getImages(options) {
  let images = [];
  let url = urlBase;
  //                      ej carrusel -        arranchera
  images.push(url + "/" + options.type + "/" + options.name + "_cr.png");
  images.push(url + "/" + options.type + "/" + options.name + "_co.png");
  images.push(url + "/" + options.type + "/" + options.name + "_em.png");

  return images;
}

function removeSpecialCaracters(str) {
    // Reemplazar la letra "ñ" por "ni"
    str = str && str.replace(/Ñ/g, 'NI');
  
    // Reemplazar caracteres con tilde por sus equivalentes sin tilde
    str = str && str.replace(/Á/g, 'A');
    str = str && str.replace(/É/g, 'E');
    str = str && str.replace(/Í/g, 'I');
    str = str && str.replace(/Ó/g, 'O');
    str = str && str.replace(/Ú/g, 'U');
    return str;
}

function getCarouselImages(options) {
  let images = [];
  let url = urlBase;
  const clearedName = removeSpecialCaracters(options.name);
  images.push("/images/desktop/categorias/i-" + clearedName + ".svg");
  return images;
}

function getBannerImg(pageName) {
  // pageName(string) Ej: "MiCuenta", "FueraDeZona", "PedidoRealizado"
  // Ejemplo URL final = /images/desktop/HerobannerImg-Carrito.png
  // Ejemplo URL final = /images/mobile/HerobannerImg-Carrito.png
  let url = `${urlBase}/herobanners/HerobannerImg-${pageName}.png`
  return url
}


function getImagesCard(tipo, nombre, h) {
  tipo = tipo && tipo.toLowerCase();
  nombre = nombre && nombre.toUpperCase()
  // Reemplazar la letra "ñ" por "ni" en tipo y nombre
  tipo = tipo && tipo.replace(/ñ/g, 'ni');
  nombre = removeSpecialCaracters(nombre);
  let url = '';
  if (h) {
    url = `/images/desktop/${tipo}/${nombre}_H.png`
  } else {
    url = `/images/desktop/${tipo}/${nombre}.png`
  }
  return url;
}

function getProductosPath(tipo, nombre, h, carrusel, empaquetado) {
  //Ej: tipo: PRODUCTO, ACOMPAÑAMIENTO - nombre: FILETE, ARRECHERA - h: boolean (TRUE, FALSE)
  tipo = tipo && tipo.toLowerCase();
  nombre = nombre && nombre.toUpperCase()
  // Reemplazar la letra "ñ" por "ni" en tipo y nombre
  tipo = tipo && tipo.replace(/ñ/g, 'ni');
  nombre = nombre && nombre.replace(/Ñ/g, 'NI');

  // Reemplazar caracteres con tilde por sus equivalentes sin tilde en nombre
  nombre = nombre && nombre.replace(/Á/g, 'A');
  nombre = nombre && nombre.replace(/É/g, 'E');
  nombre = nombre && nombre.replace(/Í/g, 'I');
  nombre = nombre && nombre.replace(/Ó/g, 'O');
  nombre = nombre && nombre.replace(/Ú/g, 'U');

  let url = '';

  if (h) {
    url = carrusel ? empaquetado ? `${urlBase}/${tipo}/carrusel/${nombre}_EM.png` : `${urlBase}/${tipo}/carrusel/${nombre}_H.png` : `${urlBase}/${tipo}/${nombre}_H.png`;
  } else {
    url = carrusel ? empaquetado ? `${urlBase}/${tipo}/carrusel/${nombre}_EM.png` : `${urlBase}/${tipo}/carrusel/${nombre}.png` : `${urlBase}/${tipo}/${nombre}.png`;
  }

  return url;
}

export { getImages, getCarouselImages, getBannerImg, getProductosPath, getImagesCard };