import React, { useEffect } from 'react'
import { useState } from 'react'
import { Row, Col, Form, Stack } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import PostalCode from '../../common/formulario/PostalCode.component'

import './formularios.css'
import '../../App.css'
import '../../index.css'
import { updateCheckoutForm, validateForm } from 'redux/actions'
import { validate } from 'utils/validator'

function DeliveryAddress({ title, subtitle }) {
  const dispatch = useDispatch();
  const renderForm = [

    [{ value: 'DIRECCION', placeholder: 'Calle' }, { value: 'COLONIA', placeholder: 'Colonia' }],
    [{ value: 'NUMERO_EXT', placeholder: 'No. Exterior' }, { value: 'NUMERO_INT', placeholder: 'No. Interior' }],
    [{ value: 'ESTADO', placeholder: 'Estado' }, { value: 'CIUDAD', placeholder: 'Municipio' }],
  ]

  //Estados globales
  const state = useSelector((state) => state);
  const { clientInfo, logged, formCheckout, validations } = state;
  const postalCodeCheckGlobal = !!state[`postalCodeCheck--CP--checkout`];

  //Estados locales
  const [postalCodeCheck, setPostalCodeCheck] = useState(postalCodeCheckGlobal);
  const [selectedAddress, setSelectedAddress] = useState(formCheckout.SELECTED_ADDRESS);
  const [form, setForm] = useState(formCheckout.ADDRESS_FORM);

  //Métodos
  const onChangeValue = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value }) //Actualiza el valor ingresado al formulario
  }
  const validateInput = (e) => {
    dispatch(validateForm('ADDRESS_FORM', validate(e.target))) //Actualiza el estado global del campo
  }
  const handleSelectAddress = (event) => dispatch(updateCheckoutForm({ SELECTED_ADDRESS: event.target.id })); //Actualiza el estado global de la direccion seleccionada
  const handleChangeSaveAddress = (event) => dispatch(updateCheckoutForm({ ADDRESS_FORM: { ...form, CREAR: event.target.checked } }))

  //Actualiza el formulario global
  useEffect(() => {
    dispatch(updateCheckoutForm({ ADDRESS_FORM: form }))
  }, [form]);

  //Almacena el id de la direccion seleccionada
  useEffect(() => {
    
    setSelectedAddress(formCheckout.SELECTED_ADDRESS)
    //Avisa si tiene que validar el formulario o no
    dispatch(validateForm('global', { ADDRESS_FORM: formCheckout.SELECTED_ADDRESS === 'Otra' }))
  }, [formCheckout.SELECTED_ADDRESS])

  //Obtiene la validacion del codigo postal
  useEffect(() => {
    setPostalCodeCheck(postalCodeCheckGlobal);
    setForm({ ...form, COD_POSTAL: state[`CP--checkout`] })
  }, [postalCodeCheckGlobal]);

  return (
    <Form.Group controlId="address-form">
      {title && <h3 className="title-h3">{title}</h3>}
      {subtitle && <p className="title-description">{subtitle}</p>}
      <Stack>
        <fieldset>
          { //Renderiza las direcciones
            (
              clientInfo.DIRECCIONES.length > 0 ?
                clientInfo.DIRECCIONES.map(dir => {
                  var text = `${dir.DIRECCION} - ${dir.NUMERO_EXT}, ${dir.COLONIA}, ${dir.CIUDAD}, ${dir.ESTADO}`;
                  return (
                    <Form.Check
                      style={{ textAlign: 'left' }}
                      id={dir.ID_DIRECCION}
                      label={text}
                      value={dir}
                      checked={selectedAddress === dir.ID_DIRECCION}
                      onChange={handleSelectAddress}
                      name='Direccion'
                    />
                  );
                })
                : null
              )
          }

          { //Muestra el boton Otra, solo si el usuario esta logueado y no posee direcciones
            logged && clientInfo.DIRECCIONES.length ?
            <Form.Check
              style={{ textAlign: 'left' }}
              id='Otra'
              value='Otra'
              checked={selectedAddress === 'Otra'}
              onChange={handleSelectAddress}
              label='Otra dirección'
              name='Direccion'
            />
            : null
          }
          {
            ((logged && selectedAddress === 'Otra') || !logged) &&
            <>
              <PostalCode id={`checkout`} />
              {
                renderForm.map(aRows =>
                  <Row className="address-row">
                    {
                      aRows.map(oColumn =>
                        <Col lg={true}>
                          <Form.Control disabled={!postalCodeCheck} className={`address-input ${postalCodeCheck ? 'golden-input' : 'grey-input'}`}
                            type="text"
                            isInvalid={validations.ADDRESS_FORM[oColumn.value]}
                            required={oColumn.value !== 'NUMERO_INT'}
                            validations={JSON.stringify({
                              minSize: 1
                            })}
                            name={oColumn.value}
                            placeholder={oColumn.placeholder || oColumn.value}
                            value={form[oColumn.value]}
                            onBlur={validateInput}
                            onChange={onChangeValue} />
                          <Form.Control.Feedback className="input-feedback" type='invalid'>{validations.ADDRESS_FORM[oColumn.value]}</Form.Control.Feedback>
                        </Col>
                      )
                    }
                  </Row>
                )
              }
            </>
          }
          { //Muestra el boton Guardar direccion, solo si el usuario esta logueado
            (logged && selectedAddress === 'Otra') &&
            <Form.Check
              disabled={!postalCodeCheck && clientInfo.DIRECCIONES.length >= 3}
              style={{ textAlign: 'left' }}
              type='checkbox'
              checked={formCheckout.ADDRESS_FORM.CREAR}
              onChange={handleChangeSaveAddress}
              label='Guardar esta dirección'
            />
          }
        </fieldset>
      </Stack>
    </Form.Group>
  )
}

export default DeliveryAddress