// export function formatterPrice(value) {
//     var parseValue = parseFloat(value);
//     return !!parseValue ? "$ " + formatterNumber(parseValue) : '$ 0';
// }
// export function formatterNumber(value) {
//     if (value) {
//         var oFormatter = new Intl.NumberFormat('de-DE');
//         return parseFloat(oFormatter.format(value)).toFixed(2);
//     }
//     return 0;
// }


export function formatterPrice(value) {
    var parseValue = parseFloat(value);
    if (!!parseValue) {
        return "$ " + formatterNumber(parseValue);
    } else {
        return '$ 0.00';
    }
}
export function formatterNumber(value) {
    if (value) {
        return parseFloat(value).toFixed(2);
    }
    return 0;
}