import React, { useEffect, useState } from 'react'
import { getBannerImg } from '../utils/getImages'

import './hero-banner.common.css'

function HeroBanner({ backgroundImg, title, subtitle }) {

    // Detecta si es mobile o no
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const createMarkup = (text) => {
        return { __html: windowWidth > 600 ? text.replace(/<[^>]*>?/gm, '') : text };
    };


    return (
        <div className="hero-section">
            <div className="background-hero-img" style={{ backgroundImage: `url(${backgroundImg ? backgroundImg : getBannerImg("Carrito")})` }}></div>
            <div className="gradient-hero-img"></div>
            <div className="container-hero-img">
                <div className="text-container-hero-img">
                    {/* <h1>{title}</h1> */}
                    {title && <h1 dangerouslySetInnerHTML={createMarkup(title)} />}
                    {subtitle && <span>{subtitle}</span>}
                </div>
            </div>
        </div>
    )
}

export default HeroBanner