import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Swiper, SwiperSlide } from "swiper/react";


import './FavoritosCarrousel.css'
import CrossSell from 'common/cross-sell/cross-sell.common';
import { Navigation } from 'swiper';


function FavoritosCarrousel() {
    const { clientInfo, logged, productos, acompañamientos } = useSelector((state) => state)
    const [categoria, setCategoria] = useState('PRODUCTO')
    const [productosFav, setProductosFav] = useState([])
    const [acompañamientosFav, setAcompañamientosFav] = useState([])
    const [id, setId] = useState(Math.floor(Math.random() * 1000000))
    const [mobile, setMobile] = useState(false);


    // ++++ CÓDIGO COMENTADO PARA CORRER LOCAL +++++

    // const productosEj = [
    //     {
    //         "ID_PRODUCTO": "P000002",
    //         "ACTIVO": "X",
    //         "NOMBRE": "COSTILLAS ST. LOUIS",
    //         "CATEGORIA": "CERDO",
    //         "TIPO": "PRODUCTO",
    //         "DESCRIPCION": "Se obtienen del centro del costillar del cerdo. Es una costilla larga con carne pegada al hueso lo que le da suavidad y jugosidad. De más rápida cocción que una costilla tradicional, ideal para cocinarse a la parrilla a fuego lento con el marinado de tu elección",
    //         "PRECIO": "1800",
    //         "UNIDAD": "UN",
    //         "URL_IMAGEN": "COSTILLAS_ST._LOUIS",
    //         "PESO": null
    //     },
    //     {
    //         "ID_PRODUCTO": "P000004",
    //         "ACTIVO": "X",
    //         "NOMBRE": "RIB EYE",
    //         "CATEGORIA": "CERDO",
    //         "TIPO": "PRODUCTO",
    //         "DESCRIPCION": "Corte grueso de composición magra por dentro y con grasa exterior, lo que lo hace de gran jugosidad y sabor ya que al cocinarse a la parrilla la grasa se derrite sobre la carne",
    //         "PRECIO": "1789",
    //         "UNIDAD": "UN",
    //         "URL_IMAGEN": "RIB_EYE",
    //         "PESO": null
    //     },
    //     {
    //         "ID_PRODUCTO": "P000005",
    //         "ACTIVO": "X",
    //         "NOMBRE": "PORK BELLY",
    //         "CATEGORIA": "CERDO",
    //         "TIPO": "PRODUCTO",
    //         "DESCRIPCION": "Este corte cuenta con piel y varias capas de carne y grasa, que lo hacen jugoso, blando y de un sabor sin igual Ideal para ahumar en la parrilla a fuego lento",
    //         "PRECIO": "1565",
    //         "UNIDAD": "UN",
    //         "URL_IMAGEN": "PORK_BELLY",
    //         "PESO": null
    //     },
    //     {
    //         "ID_PRODUCTO": "P000006",
    //         "ACTIVO": "X",
    //         "NOMBRE": "RACK DE LOMO REBANADO",
    //         "CATEGORIA": "CERDO",
    //         "TIPO": "PRODUCTO",
    //         "DESCRIPCION": "Corte jugoso y con mucha carne. Es un corte derivado de rebanar el lomo con la costilla, el resultado es una chuleta gruesa con hueso largo que le brinda gran sabor y jugosidad a la carne",
    //         "PRECIO": "1886",
    //         "UNIDAD": "UN",
    //         "URL_IMAGEN": "RACK_DE_LOMO_REBANADO",
    //         "PESO": null
    //     },
    //     {
    //         "ID_PRODUCTO": "P000007",
    //         "ACTIVO": "X",
    //         "NOMBRE": "MEDALLÓN DE LOMO",
    //         "CATEGORIA": "CERDO",
    //         "TIPO": "PRODUCTO",
    //         "DESCRIPCION": "Resultado de rebanar la carne suave y tierna del lomo. Corte magro de rápida cocción, ideal para cocinar al horno, a la parrilla o a la plancha",
    //         "PRECIO": "1750",
    //         "UNIDAD": "UN",
    //         "URL_IMAGEN": "MEDALLÓN_DE_LOMO",
    //         "PESO": null
    //     },
    //     {
    //         "ID_PRODUCTO": "P000008",
    //         "ACTIVO": "X",
    //         "NOMBRE": "FILETE",
    //         "CATEGORIA": "CERDO",
    //         "TIPO": "PRODUCTO",
    //         "DESCRIPCION": "Es un corte muy jugoso, suave y con poca grasa, ideal para hornear o asar a fuego lento en la parrilla",
    //         "PRECIO": "1600",
    //         "UNIDAD": "UN",
    //         "URL_IMAGEN": "FILETE",
    //         "PESO": null
    //     },
    //     {
    //         "ID_PRODUCTO": "P000009",
    //         "ACTIVO": "X",
    //         "NOMBRE": "FILETE PARRILLERO",
    //         "CATEGORIA": "CERDO",
    //         "TIPO": "PRODUCTO",
    //         "DESCRIPCION": "Este corte proviene del cuello (papada del cerdo). Es muy suave y jugoso y con un sabor sin igual, además de ser muy exclusivo, ya que solo se obtienen de 2 a 3 kilos por cerdo. La grasa intramuscular con la que cuenta este corte se derrite en la parrilla, lo que lo hace perfecto para asar a fuego indirecto",
    //         "PRECIO": "1720",
    //         "UNIDAD": "UN",
    //         "URL_IMAGEN": "FILETE_PARRILLERO",
    //         "PESO": null
    //     },
    //     {
    //         "ID_PRODUCTO": "P000001",
    //         "ACTIVO": "X",
    //         "NOMBRE": "ARRACHERA DE CERDO",
    //         "CATEGORIA": "CERDO",
    //         "TIPO": "PRODUCTO",
    //         "DESCRIPCION": "Uno de los cortes más nobles para cocinar, gracias a su sabor natural y textura suave, es perfecto para asar a la parrilla. La sal y especias son suficientes para realzar sus atributos",
    //         "PRECIO": "1596",
    //         "UNIDAD": "UN",
    //         "URL_IMAGEN": "ARRACHERA_DE_CERDO",
    //         "PESO": null
    //     }
    // ]

    // const acompañamientosEj = [
    //     {
    //         "ID_PRODUCTO": "A000001",
    //         "ACTIVO": "X",
    //         "NOMBRE": "CHISTORRA",
    //         "CATEGORIA": "CHORIZOS",
    //         "TIPO": "ACOMPAÑAMIENTO",
    //         "DESCRIPCION": "Embutido 100% de cerdo elaborado con mezcla de pimentones, ideal para asar y acompañar con queso fundido",
    //         "PRECIO": "1366",
    //         "UNIDAD": "UN",
    //         "URL_IMAGEN": "CHISTORRA",
    //         "PESO": null
    //     },
    //     {
    //         "ID_PRODUCTO": "A000002",
    //         "ACTIVO": "X",
    //         "NOMBRE": "CHORIZO ESPAÑOL",
    //         "CATEGORIA": "CHORIZOS",
    //         "TIPO": "ACOMPAÑAMIENTO",
    //         "DESCRIPCION": "Tipo Español 100% de cerdo, condimentado con pimienta y otras especias, ideal para acompañar tus cortes",
    //         "PRECIO": "2800",
    //         "UNIDAD": "UN",
    //         "URL_IMAGEN": "CHORIZO_ESPAÑOL",
    //         "PESO": null
    //     },
    //     {
    //         "ID_PRODUCTO": "A000003",
    //         "ACTIVO": "X",
    //         "NOMBRE": "CHORIZO ARGENTINO",
    //         "CATEGORIA": "CHORIZOS",
    //         "TIPO": "ACOMPAÑAMIENTO",
    //         "DESCRIPCION": "Chorizo tipo argentino 100% de cerdo, elaborado con especias naturales, ideal para asar y para hacer choripanes",
    //         "PRECIO": "2500",
    //         "UNIDAD": "UN",
    //         "URL_IMAGEN": "CHORIZO_ARGENTINO",
    //         "PESO": null
    //     },
    //     {
    //         "ID_PRODUCTO": "A000004",
    //         "ACTIVO": "X",
    //         "NOMBRE": "SALCHICHA ASADERA",
    //         "CATEGORIA": "CHORIZOS",
    //         "TIPO": "ACOMPAÑAMIENTO",
    //         "DESCRIPCION": "100% de cerdo, ideal para acompañar tu carne asada",
    //         "PRECIO": "1200",
    //         "UNIDAD": "UN",
    //         "URL_IMAGEN": "SALCHICHA_ASADERA",
    //         "PESO": null
    //     }
    // ]

    // const favoritosEj = [
    //     {
    //         "FECHA_REGISTRO": "2023-07-14T18:34:35.000Z",
    //         "ID_PRODUCTO": "A000004",
    //         "UUID": "3d380f95-a5fc-434f-8e2c-e43f615b7f8e"
    //     },
    //     {
    //         "FECHA_REGISTRO": "2023-07-20T19:26:54.000Z",
    //         "ID_PRODUCTO": "P000003",
    //         "UUID": "3d380f95-a5fc-434f-8e2c-e43f615b7f8e"
    //     },
    //     {
    //         "FECHA_REGISTRO": "2023-07-20T19:26:56.000Z",
    //         "ID_PRODUCTO": "P000004",
    //         "UUID": "3d380f95-a5fc-434f-8e2c-e43f615b7f8e"
    //     },
    //     {
    //         "FECHA_REGISTRO": "2023-07-21T23:10:33.000Z",
    //         "ID_PRODUCTO": "P000001",
    //         "UUID": "3d380f95-a5fc-434f-8e2c-e43f615b7f8e"
    //     },
    //     {
    //         "FECHA_REGISTRO": "2023-07-31T14:19:57.000Z",
    //         "ID_PRODUCTO": "P000002",
    //         "UUID": "3d380f95-a5fc-434f-8e2c-e43f615b7f8e"
    //     }
    // ]

    const handleCategoria = (categoria) => {
        setCategoria(categoria)
    }

    useEffect(() => {
        const mediaQuery = window.matchMedia("(max-width: 600px)");
        setMobile(mediaQuery.matches);

        const handleResize = () => {
            setMobile(mediaQuery.matches);
        };

        mediaQuery.addEventListener("change", handleResize);
        return () => {
            mediaQuery.removeEventListener("change", handleResize);
        };
    }, []);

    useEffect(() => {
        if (logged && clientInfo.FAVORITOS && clientInfo.FAVORITOS.length > 0) {
            // Filtra los productos favoritos que sean de tipo "PRODUCTO"
            setProductosFav(
                productos.filter((producto) => {
                    return clientInfo.FAVORITOS.some((favorito) => favorito.ID_PRODUCTO === producto.ID_PRODUCTO);
                })
            )
            // Filtra los productos favoritos que sean de tipo "ACOMPAÑAMIENTO"

            setAcompañamientosFav(
                acompañamientos.filter((producto) => {
                    return clientInfo.FAVORITOS.some((favorito) => favorito.ID_PRODUCTO === producto.ID_PRODUCTO);
                })
            )
        }

        // ++++ CÓDIGO COMENTADO PARA CORRER LOCAL +++++

        // else {
        //     // Filtra los productos favoritos que sean de tipo "PRODUCTO"
        //     setProductosFav(
        //         productosEj.filter((producto) => {
        //             return favoritosEj.some((favorito) => favorito.ID_PRODUCTO === producto.ID_PRODUCTO);
        //         })
        //     )
        //     // Filtra los productos favoritos que sean de tipo "ACOMPAÑAMIENTO"
        //     setAcompañamientosFav(
        //         acompañamientosEj.filter((producto) => {
        //             return favoritosEj.some((favorito) => favorito.ID_PRODUCTO === producto.ID_PRODUCTO);
        //         })
        //     )
        // }

    }, [clientInfo.FAVORITOS, productos, acompañamientos, categoria])


    useEffect(() => {
        if (!productosFav.length > 0 && acompañamientosFav.length > 0) {
            setCategoria('ACOMPAÑAMIENTO')
        }
        if (productosFav.length > 0 && !acompañamientosFav.length > 0) {
            setCategoria('PRODUCTO')
        }
    }, [productosFav, acompañamientosFav])
    return (
        <div className='fav-carrousel'>
            {logged && (productosFav.length > 0 || acompañamientosFav.length > 0) ?
                <div>
                    <h1 className='h1-cabecera-favoritos-carrousel'>Tus favoritos</h1>
                    <div className='fav-carrousel-main'>
                        {
                            productosFav.length > 0 ?
                                // <div className='fav-carrousel-categoria-cont'>
                                <div className={categoria === 'PRODUCTO' ? 'fav-carrousel-categoria-selected' : 'fav-carrousel-categoria-cont'}>
                                    <img
                                        // src={categoria === 'PRODUCTO' ? '/images/icons/FAV-CORTES-SELECTED.svg' : '/images/icons/FAV-CORTES-DEFAULT.svg'}
                                        src={'/images/icons/FAV-CORTES-DEFAULT.svg'}
                                        alt="Tus cortes favoritos"
                                        onClick={() => handleCategoria('PRODUCTO')} />
                                    {/* <span className={`fav-carrousel-cat-title ${categoria === 'PRODUCTO' ? 'cat-active' : null}`}>Nuestros cortes</span> */}
                                    <span className={`fav-carrousel-cat-title`}>Nuestros cortes</span>
                                </div>
                                : null
                        }
                        {
                            acompañamientosFav.length > 0 ?
                                // <div className='fav-carrousel-categoria-cont' >
                                <div className={categoria === 'ACOMPAÑAMIENTO' ? 'fav-carrousel-categoria-selected' : 'fav-carrousel-categoria-cont'}>
                                    <img
                                        // src={categoria === 'ACOMPAÑAMIENTO' ? '/images/icons/FAV-ACOMPANIAMIENTO-SELECTED.svg' : '/images/icons/FAV-ACOMPANIAMIENTO-DEFAULT.svg'}
                                        src={'/images/icons/FAV-ACOMPANIAMIENTO-DEFAULT.svg'}
                                        alt="Tus acompañamientos favoritos"
                                        onClick={() => handleCategoria('ACOMPAÑAMIENTO')} />
                                    {/* <span className={`fav-carrousel-cat-title ${categoria === 'ACOMPAÑAMIENTO' ? 'cat-active' : null}`}>Acompañamientos</span> */}
                                    <span className={`fav-carrousel-cat-title`}>Acompañamientos</span>
                                </div>
                                : null
                        }
                    </div>
                    {categoria === 'ACOMPAÑAMIENTO' && acompañamientosFav.length > 0 ? <CrossSell backgroundImage={false} titulo={false} subtitulo={false} transparent={true} hideVerMas={true} items={acompañamientosFav} id='fav-productos' /> : null}
                    {categoria === 'PRODUCTO' && productosFav.length > 0 ? <CrossSell backgroundImage={false} titulo={false} subtitulo={false} transparent={true} hideVerMas={true} items={productosFav} id='fav-productos' /> : null}
                </div>
                : null
            }
        </div>
    )
}

export default FavoritosCarrousel