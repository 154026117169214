import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Stack, Button } from 'react-bootstrap'
import HeroBanner from '../../common/hero-banner.common'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import MiCuentaMenu from '../../components/mi-cuenta/MiCuentaMenu'
import DeliveryStatus from '../../components/mi-cuenta/DeliveryStatus'
import { getBannerImg } from 'utils/getImages'
import CrossSell from '../../common/cross-sell/cross-sell.common'
import { Link } from 'react-router-dom'
import ItemProducto from 'common/item-producto/item-producto.common';

import '../../index.css'
import '../../App.css'
import '../../common/formulario/formularios.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import urlSubcuenta from 'utils/urlSubcuenta';

function MisCompras() {
  const { clientInfo, logged } = useSelector((state) => state)
  const [productosHistoricos, setProductosHistoricos] = useState([])
  const [pedidosRecientes, setPedidosRecientes] = useState([])
  const { productos } = useSelector((state) => state)

  useEffect(() => {
    if (clientInfo.PRODUCTOS_HISTORICOS && clientInfo.PRODUCTOS_HISTORICOS.length > 0) {
      setProductosHistoricos(clientInfo.PRODUCTOS_HISTORICOS)
    }

    if (!logged) {
      // Realizar el redireccionamiento con el historial en el navegador
      window.location.href = urlSubcuenta() + '/login/';
    }
  }, [clientInfo.PRODUCTOS_HISTORICOS, logged]);

  useEffect(() => {
    if (clientInfo.PEDIDOS_RECIENTES && clientInfo.PEDIDOS_RECIENTES.length > 0) {
      setPedidosRecientes(clientInfo.PEDIDOS_RECIENTES)
    }
  }, [clientInfo.PEDIDOS_RECIENTES])

  return (
    <div className='cuenta-compras'>
      <div className="checkout-banner">
        <HeroBanner title='Mis Compras' subtitle={false} backgroundImg={getBannerImg("MiCuenta")}></HeroBanner>
      </div>
      <section className="gradient-bg">
        <Stack>
          <MiCuentaMenu />
          <div style={{ marginBottm: '2.5rem' }}>
            {pedidosRecientes.length ? pedidosRecientes.map(item =>
              <Stack style={{ marginBottom: '1rem', marginTop: '1rem' }} orientation='vertical' className='container checkout-body' gap={4}>
                <DeliveryStatus item={item} />
              </Stack>

            ) :
              <Stack style={{ marginBottom: '1rem', marginTop: '1rem' }} orientation='vertical' className='container checkout-body' gap={4}>
                <div className='pedido-info-cont cont-delivery-status'>
                  <h2 className="title-h2 title-delivery-status">Aún no realizaste un pedido</h2>
                  <span className='id-pedido span-delivery-status'>¡Oh no! Tu lista de pedidos está vacía en este momento. Pero tranquilo, puedes explorar nuestros productos y hacer tu primer pedido en cualquier momento.</span>
                  <div className="cont-flex purchases-links-container">
                    <Link to="/app/productos">
                      <Button className='btn-dorado'>Ver productos</Button>
                    </Link>
                  </div>
                </div>
              </Stack>
            }

          </div>
        </Stack>
      </section>
      {
        pedidosRecientes.length > 0 && productosHistoricos.length > 0 &&
        <div className='background-color-gradient-black-grey div-producto-productos'>
          <div>
            <Row>
              <div className='div-cabecera'>
                <h1 className='h1-cabecera'>Pedidos anteriores</h1>
              </div>
              {
                productosHistoricos.map(p => (
                  <Col sm="12" md="12" xl="4" lg="6" className='div-producto-item-padding'>
                    <ItemProducto item={p} buttonText="VOLVER A COMPRAR" />
                  </Col>
                ))
              }
            </Row>
          </div>
        </div>
      }
    </div >
  )
}

export default MisCompras