import HeroBanner from '../../common/hero-banner.common';
import { getBannerImg } from 'utils/getImages';
import ItemProducto from '../../common/item-producto/item-producto.common';
import CrossSell from '../../common/cross-sell/cross-sell.common'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useSelector } from 'react-redux';
import { QuestionCircle } from 'react-bootstrap-icons';
import { useEffect, useState } from 'react';
import ProductoItemCarrousel from 'components/carousel-productos/producto-item-carrousel';

import './productos.page.css';
import 'bootstrap/dist/css/bootstrap.min.css';
function Productos() {

  const { productos, acompañamientos } = useSelector((state) => state)

  const [mobile, setMobile] = useState(false);


  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 600px)");
    setMobile(mediaQuery.matches);

    const handleResize = () => {
      setMobile(mediaQuery.matches);
    };

    mediaQuery.addEventListener("change", handleResize);
    return () => {
      mediaQuery.removeEventListener("change", handleResize);
    };
  }, []);

  return (
    <div>
      <HeroBanner title='TODO EL SABOR DEL CERDO <br/> A LA PARRILLA' subtitle='CONOCE NUESTRA VARIEDAD DE CORTES CARNE DE CERDO' backgroundImg={getBannerImg("NuestrosCortes")}></HeroBanner>
      <div className='background-color-gradient-black-grey div-producto-productos'>
        <div>

          {mobile ?
            productos.length > 0 ? (
              productos.map(p => (
                <Col sm="12" md="12" xl="4" lg="6" className='div-producto-item-padding'>
                  <ProductoItemCarrousel item={p} buttonText="AÑADIR AL CARRITO" />
                </Col>
              ))
            ) : null

            :

            <Row className='row-products-container'>
              {productos.length > 0 ? (
                productos.map(p => (
                  <Col sm="12" md="12" xl="4" lg="6" className='div-producto-item-padding'>
                    <ItemProducto item={p} buttonText="AÑADIR AL CARRITO" />
                  </Col>
                ))
              ) : null}
            </Row>}

          <div className='tooltip-container'>
            <div className='tooltip-sub-container'>
              <span>
                <QuestionCircle />
              </span>
              <p>
                Ten en cuenta que el peso de los productos es estimado, por lo que el total del importe de tu pedido puede variar en el cobro final. Con base en el peso final del producto, se ajustará el importe final y éstos se reflejarán en tu ticket de compra al entregar el producto.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div>
        <CrossSell isProductos={true} backgroundImage={true} titulo={"Complementa tu parrilla"} subtitulo={"Conoce los complementos para carne asada que te ayudarán a conquistar la parrilla."}
          items={acompañamientos} id='productos' />
      </div>
    </div>
  );
}

export default Productos;