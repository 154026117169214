export function getPreguntas () {
    return{
        preguntasPedido: [
            { 
                pregunta: "¿Cuál es su política de devoluciones?", 
                respuesta: "Ten en cuenta que podrás solicitar un cambio o devolución del producto si: * Existe un error en el producto o faltante en el pedido.  *Si el artículo presenta defectos de fabricación. Para proceder, debes conservar el empaque original, etiquetas, adhesivos y sello de garantía. Tampoco deberá presentar signos de uno, manipulación, maltrato o alteración de temperatura. Además, debes conservar tu ticket de compra en estado legible. Una vez entregado el producto, tienes 60 minutos para informar sobre la inconformidad con tu pedido. Llama al teléfono asignado a tu zona de servicio a domicilio."
            },
            { 
                pregunta: "¿Qué hago si me equivoqué al hacer mi pedido?", 
                respuesta: "Si tienes algún problema con tu pedido, contáctanos al número de atención a clientes o por WhatsApp al 999 930 2222. Ten a la mano tu número de pedido, nombre completo, teléfono, correo electrónico y el producto erróneo que recibiste."
            },
            { 
                pregunta: "¿Qué hago si mi pedido llegó incompleto?", 
                respuesta: "Si tienes algún problema con tu pedido, contáctanos al número de atención a clientes o por WhatsApp al 999 930 2222. Ten a la mano tu número de pedido, nombre completo, teléfono, correo electrónico y el producto erróneo que recibiste."
            },
            { 
                pregunta: "Tengo problemas para hacer mi pedido en el Sitio Web", 
                respuesta: "En caso de que no puedas realizar tu pedido, contáctanos a nuestro número de atención a clientes o por WhatsApp al 999 930 2222 para brindarte soporte técnico."
            },
            { 
                pregunta: "Me llegó un pedido equivocado", 
                respuesta: "Contáctanos al número de atención a clientes o por WhatsApp al 999 930 2222. Ten a la mano tu número de pedido, nombre completo, teléfono, correo electrónico y el producto erróneo que recibiste."
            },
            { 
                pregunta: "¿Cuáles son los horarios de entrega?", 
                respuesta: "Nuestro horario de servicio a domicilio es de lunes a sábado de 7 a.m. a 1:30 p.m."
            },
            { 
                pregunta: "¿Realizan entregas en mi zona?", 
                respuesta: "Por el momento, nuestra cobertura abarca los estados de: Mérida, Villahermosa, Cancún, Campeche, Irapuato, Puebla, Playa del Carmen, Tuxtla Gutiérrez, Guanajuato y León. Para conocer la cobertura del servicio a domicilio en tu zona, debes colocar el C.P. del domicilio al que deseas recibir tu pedido. También puedes contactarnos al número de atención a clientes o al WhatsApp 999 930 2222"
            },
            { 
                pregunta: "¿Cuál es el costo de envío?", 
                respuesta: "Todos los envíos a domicilio son sin costo, con un mínimo de compra de 2 kg."
            },
            { 
                pregunta: "¿Cuáles son los métodos de pago?", 
                respuesta: "Contamos con diversas opciones: puedes realizar tu pago con tarjeta de débito o crédito en línea al momento del check out, o puedes pedir que tu pago sea en efectivo o con tarjeta al momento de la entrega."
            },
            { 
                pregunta: "¿Manejan alguna promoción?", 
                respuesta: "Puedes encontrar todas nuestras promociones disponibles en la página principal del Sitio Web."
            },
            { 
                pregunta: "¿En cuántos días hábiles se emite la factura?", 
                respuesta: "Si ya solicitaste tu factura, la recibirás al correo que nos proporcionaste, en un periodo de 2 a 3 días hábiles."
            },
            { 
                pregunta: "Necesito factura, ¿cómo puedo solicitarla?", 
                respuesta: "Si deseas solicitar factura, en la sección 'Mi cuenta', llena los datos de facturación. Después de hacerlo, recibirás tu factura en un periodo de 2 a 3 días hábiles. Si no la has recibido dentro de ese tiempo, puedes comunicarte o envíar  WhatsApp al 999 930 2222."
            },
            { 
                pregunta: "¿Cómo consulto el estatus mi pedido?", 
                respuesta: "Si quieres conocer el estado de tu pedido, contáctanos al número de atención a clientes o por WhatsApp al 999 930 2222. Ten a la mano tu número de pedido, nombre completo, teléfono y correo electrónico."
            },
            { 
                pregunta: "Hice mi pedido fuera de los horarios de servicio, ¿hasta qué hora del día siguiente podré recibirlo?", 
                respuesta: "Nuestro horario de servicio a domicilio es de lunes a sábado de 7 a.m. a 1:30 p.m. Puedes contactarnos o escribirnos al WhatsApp 999 930 2222."
            },
            { 
                pregunta: "Me entregaron mi pedido sin ticket", 
                respuesta: "Contáctanos al número de atención a clientes o por WhatsApp al 999 930 2222. Ten a la mano tu número de pedido, nombre completo, teléfono y correo electrónico."
            }
        ],
        preguntasProducto: [
            { 
                pregunta: "¿Tienes algún comentario o inconformidad con tu producto?", 
                respuesta: "Para apoyarte con tu caso y brindarte la atención que mereces, puedes contactarnos a nuestro número de atención a clientes o por WhatsApp al 999 930 2222 especificando tu número de pedido, nombre completo, teléfono y correo electrónico."
            }
        ]
    };
}