import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { getAcompañamientos, getClientCart, getClientDir, getClientFav, getClientPedidos, getLoggedClient, getProductos, getProductosHistorico, updateCheckoutForm, validateForm } from 'redux/actions';
import Navbar from './layout/navbar/navbar.layout';
import NavbarMobile from './layout/navbar/navbar-mobile.layout';
import Footer from './layout/footer/footer.layout';
import Home from './pages/home/home.page'
import Productos from './pages/productos/productos.page'
import Carrito from './pages/carrito/carrito.page'
import Checkout from './pages/checkout/Checkout'
import MisCompras from './pages/mi-cuenta/MisCompras.page';
import MisDatos from './pages/mi-cuenta/MisDatos.page';
import Privacidad from './pages/privacidad/privacidad.page.jsx'
import Terminos from './pages/terminos/terminos.page.jsx'
import Devoluciones from './pages/devoluciones/devoluciones.page.jsx'
import Acompañamientos from './pages/acompañamientos/acompañamientos.page.jsx'
import PDPProductos from './pages/productos/pdp.page';
import PDPAcompañamientos from './pages/acompañamientos/pdp.page';
import Confirmacion from './pages/confirmacion/Confirmacion.page';
import FueraZona from './pages/home/FueraZona.page'
import useUnloggedCart from './redux/actions/unloggedCart'
import Login from 'pages/login/Login';
import ScrollToTop from 'utils/scrollToTop';
import Loader from 'common/loader';
import Faq from './pages/faq/faq.page.jsx'
import './App.css';
import 'react-toastify/dist/ReactToastify.css';


function App() {
  const { getCartItems } = useUnloggedCart();
  const dispatch = useDispatch();
  const { logged, clientInfo, guestInfo, productos, acompañamientos, loader, formCheckout } = useSelector((state) => state);
  const [mobile, setMobile] = useState(false);

  // Detecta si el usuario esta logeado y trae los productos y acompañamientos
  useEffect(() => {
    // Llena el "clientInfo"
    dispatch(getLoggedClient())
    dispatch(getProductos())
    dispatch(getAcompañamientos())
    getCartItems()
  }, [])

  useEffect(() => {
    //Setea los productos seleccionados
    if (productos.length || acompañamientos.length) {
      //Almacenara los productos seleccionados
      let oSelectedProducts = {};

      //Mapea los productos del carrito
      if (logged) {
        clientInfo.CARRITO.PRODUCTOS.forEach(e => oSelectedProducts[e.ID_PRODUCTO] = e);
      } else {
        guestInfo.CARRITO.PRODUCTOS.forEach(e => oSelectedProducts[e.ID_PRODUCTO] = e);
      }

      //Completa la información de los productos seleccionados
      [...productos, ...acompañamientos].forEach(e => {
        if (oSelectedProducts[e.ID_PRODUCTO]) {
          oSelectedProducts[e.ID_PRODUCTO] = Object.assign(e, oSelectedProducts[e.ID_PRODUCTO])
        }
      });
      //Actualiza los productos en global state
      dispatch(updateCheckoutForm({ PRODUCTOS: oSelectedProducts }))
    }
  }, [acompañamientos, productos, clientInfo.CARRITO, guestInfo.CARRITO]);

  //Actualiza el total del carrito
  useEffect(() => {
    if (formCheckout.PRODUCTOS) {
      dispatch(updateCheckoutForm({ TOTAL: Object.values(formCheckout.PRODUCTOS).reduce((sum, obj) => sum + parseFloat(obj.TOTAL), 0) }))
    

  }
  }, [formCheckout.PRODUCTOS]);

useEffect(() => {
  if (logged) {
    //Agrego formulario de direcciones a local
    if (clientInfo.DIRECCIONES && clientInfo.DIRECCIONES.length > 0) {
      clientInfo.DIRECCIONES.forEach((dir, index) => {
        let oForm = {
          ID_DIRECCION: dir.ID_DIRECCION,
          DIRECCION: dir.DIRECCION,
          COLONIA: dir.COLONIA,
          NUMERO_EXT: dir.NUMERO_EXT,
          NUMERO_INT: dir.NUMERO_INT,
          ESTADO: dir.ESTADO,
          COD_POSTAL: dir.COD_POSTAL,
          CIUDAD: dir.CIUDAD
        },
          sFormName = `DIRECCION${index}_FORM`,
          oValidationForm = {};

        Object.keys(oForm).forEach(sKey => {
          if (sKey !== 'COD_POSTAL' && sKey !== 'ID_DIRECCION') {
            oValidationForm[sKey] = !!oForm[sKey] ? null : ''
          }

          if (sKey.includes('INT')) {
            oValidationForm[sKey] = null
          }
        })
        dispatch(validateForm('global', { [sFormName]: true }));
        dispatch(validateForm(sFormName, oValidationForm))
        dispatch(updateCheckoutForm({ [`DIRECCION${index}_FORM`]: oForm }))
      })
    }

    //Actualizo los datos del cliente en local
    var oForm = {
      NOMBRE: clientInfo.NOMBRE,
      APELLIDO: clientInfo.APELLIDO,
      EMAIL: clientInfo.EMAIL,
      TELEFONO: clientInfo.TELEFONO,
    },
      oValidationForm = {};

    Object.keys(oForm).forEach(sKey => {
      oValidationForm[sKey] = !!oForm[sKey] ? null : ''
    })

    dispatch(validateForm('CLIENT_FORM', oValidationForm))
    dispatch(updateCheckoutForm({ CLIENT_FORM: oForm }))
  }

}, [clientInfo, logged])

// Setea si es mobile o no, según el width del viewport.
useEffect(() => {
  const handleMediaQuery = (event) => {
    setMobile(event.matches);
  };
  const mediaQuery = window.matchMedia('(max-width: 600px)');
  setMobile(mediaQuery.matches);
  mediaQuery.addEventListener('change', handleMediaQuery);
  return () => {
    mediaQuery.removeEventListener('change', handleMediaQuery);
  };
}, []);




// Si el usuario está logeado, trae su información
useEffect(() => {
  if (clientInfo.UUID && clientInfo.EMAIL) {
    dispatch(getClientCart(clientInfo.UUID, clientInfo.EMAIL))
    dispatch(getClientDir(clientInfo.UUID, clientInfo.EMAIL))
    dispatch(getClientFav(clientInfo.UUID, clientInfo.EMAIL))
    dispatch(getProductosHistorico(clientInfo.UUID, clientInfo.EMAIL));
    dispatch(getClientPedidos(clientInfo.UUID, clientInfo.EMAIL))
  }
}, [clientInfo.UUID, clientInfo.EMAIL])

return (
  <div className="App">
    <BrowserRouter>
      {loader ? <Loader /> : null}
      <ScrollToTop /> {/* Componente para scrollear al top cada vez que se cambia de ruta */}
      {mobile ? <NavbarMobile /> : <Navbar />}
      <Routes>
        <Route exact path={`/app/`} element={<Home />}></Route>
        <Route exact path={`/index.html`} element={<Home />}></Route>
        <Route exact path={`/app/productos`} element={<Productos />}></Route>
        <Route exact path={`/app/carrito`} element={<Carrito />}></Route>
        <Route exact path={`/app/checkout`} element={<Checkout />}></Route>
        <Route exact path={`/app/cuenta/datos`} element={<MisDatos />}></Route>
        <Route exact path={`/app/cuenta/compras`} element={<MisCompras />}></Route>
        <Route exact path={`/app/cuenta/pedido-confirmado`} element={<Confirmacion />}></Route>
        <Route exact path={`/app/terminos`} element={<Terminos />}></Route>
        <Route exact path={`/app/privacidad`} element={<Privacidad />}></Route>
        <Route exact path={`/app/devoluciones`} element={<Devoluciones />}></Route>
        <Route exact path={`/app/acompañamientos`} element={<Acompañamientos />}></Route>
        <Route exact path={`/app/pdpproductos/:id`} element={<PDPProductos />}></Route>
        <Route exact path={`/app/pdpacompañamientos/:id`} element={<PDPAcompañamientos />}></Route>
        <Route exact path={`/app/fuera-de-zona`} element={<FueraZona />}></Route>
        <Route exact path={`/app/preguntas-frecuentes`} element={<Faq />}></Route>
        <Route path={`/login`} element={<Login />}></Route>
        <Route path="/*" element={<Navigate to="/app/" />} />
      </Routes>
      <Footer></Footer>
      <ToastContainer />
    </BrowserRouter>
  </div>
);
}

export default App;