import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Stack from 'react-bootstrap/Stack';
import Form from 'react-bootstrap/Form';
import { checkPostalCode, validateForm, resetTrigger } from '../../redux/actions';
import './formularios.css'
import '../../App.css'
import '../../index.css'

function PostalCode({ id, redirect, customEvent }) {

    const state = useSelector((state) => state);
    const formResetTrigger = useSelector((state) => state.formResetTrigger);
    const [errors, setErrors] = useState({});
    const [form, setForm] = useState(state);

    const instance = `CP--${id}`
    const postalCode = state[`postalCode--${instance}`]
    const postalCodeCheck = state[`postalCodeCheck--${instance}`]
    const dispatch = useDispatch()


    const setField = (value) => {
        if (customEvent) customEvent();

        setForm({
            ...form,
            [instance]: value
        })

        if (!!errors) {
            setErrors({
                ...errors,
                [instance]: null,
            })
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        errors[instance] = null;
        if (form[instance] && form[instance].length > 4) {
            dispatch(checkPostalCode(form[instance], instance))
        } else {
            errors[instance] = "Ingrese un código postal válido."
        }
    }

    useEffect(() => {
        if (formResetTrigger) {
            setForm(prevForm => ({
                ...prevForm,
                [instance]: '',
            }));

            setErrors(prevErrors => ({
                ...prevErrors,
                [instance]: null,
            }));
            dispatch(resetTrigger())
            dispatch(validateForm('global', { [id]: false }));
        }
    }, [formResetTrigger, id, instance, dispatch]);


    useEffect(() => {
        if (typeof (postalCode) === 'object' && !postalCode.length) {
            setErrors({ [instance]: 'Zona sin cobertura.' })
        }

        if (postalCodeCheck && form[instance] && form[instance].length > 1){
            dispatch(validateForm('global', { [id]: true }));
        }
    }, [postalCode])

    return (
        <form onSubmit={handleSubmit} className="input">
            <Stack className="cp-main-container" direction='horizontal' gap={2}>
                <Stack className="cp-input-container">
                    <Form.Control required type="text" name='CP' className="validate-input golden-input" placeholder="Código Postal" isInvalid={!!errors[instance]} value={form[instance]} onChange={(e) => setField(e.target.value)} />
                    <Form.Control.Feedback className="input-feedback" type='invalid'>{errors[instance] && errors[instance]}</Form.Control.Feedback>
                    {postalCodeCheck && form[instance] && form[instance].length > 1 ? <p style={{ color: 'green', textAlign: 'left', fontWeight: '600', fontSize: '14px' }}>Codigo postal válido.</p> : null}
                </Stack>
                <button className='btn-dorado validate-btn submit-cp-btn' type="submit">Validar</button>
            </Stack>
        </form>
    )
}

export default PostalCode