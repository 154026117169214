import './mapa-item.component.css';

function MapaItem(prop) {
    return (
        <div className='mapa-item-div-container'>
            <img src={prop.img} className="mapa-item-img" alt="Map Image" />
            <div class="mapa-item-text-align">
                <h3 className='text-color-white mapa-item-titulo'>{prop.estado}</h3>
                <ul>
                    {prop.ciudades.map((cityGroup, index) => (
                        <div className='text-color-white mapa-item-sub-listado' key={index}>
                            {cityGroup.map((city, cityIndex) => (
                                <li key={cityIndex}>{city}</li>
                            ))}
                        </div>
                    ))}
                </ul>
            </div>
        </div>
    );
}

export default MapaItem;
