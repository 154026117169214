import React, { useEffect, useState } from 'react';
import { Heart, HeartFill, Plus } from 'react-bootstrap-icons';
import { Dash } from 'react-bootstrap-icons';
import IInfo from '../../icons/info.png';

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import useUnloggedCart from 'redux/actions/unloggedCart'
import { useDispatch, useSelector } from 'react-redux';
import { addClientFav, addItemClientCart, deleteClientFav, updateCheckoutForm, updateItem } from 'redux/actions';
import { formatterPrice } from 'utils/formatter';
import { getProductosPath } from 'utils/getImages';

import { useLocation } from 'react-router-dom';
import './item-producto-descripcion.common.css'

function ItemProductoDescripcion({ productoID, home }) {
    const dispatch = useDispatch()
    const { addToCart } = useUnloggedCart()
    const { clientInfo, logged, productos, acompañamientos, formCheckout } = useSelector(state => state);
    const [cantidadItem, setCantidad] = useState("01");
    const [item, setItem] = useState({});
    const [img, setIMG] = useState({});
    const [favorito, setFavorito] = useState(false);


    // Setea el menú de escritorio ó el de hamburguesa según el Viewport
    const location = useLocation();
    const { pathname } = location;

    // Favorito handlers
    const favoritoHandler = () => {
        // Aca deberíamos mandar un PATCH a los favoritos del usuario agregando el producto ID
        if (favorito === false) {
            dispatch(addClientFav(clientInfo.UUID, clientInfo.EMAIL, productoID))
        }

        if (favorito === true) {
            dispatch(deleteClientFav(clientInfo.UUID, clientInfo.EMAIL, productoID))
        }
        setFavorito(() => !favorito)
    };

    // Actualiza favorito
    useEffect(() => {
        if (logged) {
            if (clientInfo.FAVORITOS && clientInfo.FAVORITOS.length > 0) {
                const isFavorite = clientInfo.FAVORITOS.some(producto => producto.ID_PRODUCTO === productoID);
                setFavorito(() => isFavorite)
            }
        }

    }, [clientInfo.FAVORITOS])


    const formatearNumero = (n) => {
        if (n < 10) {
            n = "0" + n;
        }
        else {
            n = n.toString();
        }

        return n;
    }

    const restarItem = (e) => {
        e.preventDefault();
        let cantidad = cantidadItem;
        cantidad = parseInt(cantidad);
        if (!isNaN(cantidad) && cantidad > 1) {
            cantidad--;
            cantidad = formatearNumero(cantidad);
            setCantidad(cantidad);
        }
        else {
            cantidad = "01";
            setCantidad(cantidad);
        }
    }

    const sumarItem = (e) => {
        e.preventDefault();
        let cantidad = cantidadItem;
        cantidad = parseInt(cantidad);
        if (!isNaN(cantidad)) {
            cantidad++;
            cantidad = formatearNumero(cantidad);
            setCantidad(cantidad);
        }
        else {
            cantidad = "01";
            setCantidad(cantidad);
        }
    }

    const handleCart = (e) => {
        e.preventDefault()
        // Actualiza los productos en global state y actualiza el total del carrito
        if (logged) {
            if (formCheckout.PRODUCTOS[item.ID_PRODUCTO]) {
                //Actualiza la cantidad de items seleccionada
                const nuevaCantidad = parseInt(formCheckout.PRODUCTOS[item.ID_PRODUCTO].CANTIDAD) + parseInt(cantidadItem)
                dispatch(updateItem(item.ID_PRODUCTO, { CANTIDAD: nuevaCantidad }))

                //Actualiza el precio total
                dispatch(updateCheckoutForm({ TOTAL: formCheckout.TOTAL + (nuevaCantidad * parseFloat(item.PESO)) * parseFloat(item.PRECIO) }))
                dispatch(addItemClientCart(clientInfo.UUID, clientInfo.EMAIL, {
                    ID_PRODUCTO: item.ID_PRODUCTO,
                    CANTIDAD: nuevaCantidad
                }))
            } else {
                dispatch(addItemClientCart(clientInfo.UUID, clientInfo.EMAIL, {
                    ID_PRODUCTO: item.ID_PRODUCTO,
                    CANTIDAD: parseInt(cantidadItem)
                }))
            }
        } else {
            addToCart({ ID_PRODUCTO: item.ID_PRODUCTO, CANTIDAD: cantidadItem, PRECIO: item.PRECIO, PESO: item.PESO })
        }
    }

    useEffect(() => {
        if (productos && productos.length > 0 && acompañamientos && acompañamientos.length > 0) {
            let prod = productos.concat(acompañamientos).filter(e => e.ID_PRODUCTO === productoID)[0];
            setItem(prod || {})
            //Si existe producto, obtiene la imagen
            if (prod) {
                setIMG({
                    //Tercer param es HOVER, cuart"o param es CARRUSEL quinto param busca empaquetada
                    URL_IMAGEN: pathname.includes("pdpproductos") ? getProductosPath(prod.TIPO, prod.URL_IMAGEN, false, true, true) : getProductosPath(prod.TIPO, prod.URL_IMAGEN, false, true),
                    URL_IMAGEN_H: prod.TIPO === 'PRODUCTO' ? getProductosPath(prod.TIPO, prod.URL_IMAGEN, true, true) : null
                })
            }
        }
    }, [productos, acompañamientos, productoID]);

    return (
        <Row className='it-prod-desc-cont'>
            <Col sm="12" md="12" xl="8" lg="8">
                <Swiper
                    pagination={{
                        clickable: true,
                        renderBullet: function (_, className) {
                            return '<span class="' + className + '"><img class="pagination-bullet"/></span>';
                        }
                    }}
                    modules={[Pagination]}
                    className="mySwiper">
                    <SwiperSlide>
                        <div className='img-cont-pdp'>
                            {
                                logged ?
                                    <button className='btn-item-producto-common-like-pdp' onClick={favoritoHandler}>
                                        {favorito ? <HeartFill></HeartFill> : <Heart></Heart>}
                                    </button>
                                    : null
                            }

                            <img src={img.URL_IMAGEN} alt="" className='img-carousel it-prod-desc-img' />
                        </div>
                    </SwiperSlide>
                    {
                        img.URL_IMAGEN_H &&
                        <SwiperSlide>
                            <div>
                                {
                                    logged ?
                                        <button className='btn-item-producto-common-like-pdp' onClick={favoritoHandler}>
                                            {favorito ? <HeartFill></HeartFill> : <Heart></Heart>}
                                        </button>
                                        : null
                                }
                                <img src={img.URL_IMAGEN_H} alt="" className='img-carousel it-prod-desc-img' />
                            </div>
                        </SwiperSlide>
                    }
                </Swiper>
            </Col>

            <Col sm="12" md="12" xl="4" lg="4">
                <div className='it-prod-desc-d-t-container'>
                    <span className='text-color-gold it-prod-desc-d-t-cont-titulo'>{item.NOMBRE}</span>
                    <span className='text-color-white it-prod-desc-d-t-cont-descripcion'>
                        {item.DESCRIPCION}
                    </span>
                    <div className='it-prod-desc-d-t-i-info-cont'>
                        <img src={IInfo} alt="" className='it-prod-desc-d-t-i-info'></img>
                        <div className='it-prod-desc-d-t-pes-cont'>
                            <span className='text-color-white it-prod-desc-d-t-pes-pre'>aprox. {parseFloat(item.PESO).toFixed(2)}kg - precio ${item.PRECIO} por kg</span>
                            {/* <span className='text-color-white it-prod-desc-d-t-pes-pre'></span> */}
                        </div>
                    </div>
                    <div className='it-prod-desc-d-t-cont-acc'>
                        <div>
                            <div className='it-prod-handler-container'>
                                <button class='it-prod-desc-d-t-btn-mas-men' onClick={restarItem}><Dash></Dash></button>
                                <span className='text-color-white contador-categorias'>{cantidadItem}</span>
                                <button class='it-prod-desc-d-t-btn-mas-men' onClick={sumarItem}><Plus></Plus></button>
                            </div>
                        </div>
                        <span className='text-color-white it-prod-desc-d-t-pre mg-top-10-px'>{(formatterPrice((parseFloat(item.PESO) * parseInt(cantidadItem)) * parseFloat(item.PRECIO)))}</span>
                        <button className='btn-dorado mg-top-20-px btn-carousel-categoria' onClick={handleCart}>AÑADIR A CARRITO</button>
                    </div>
                </div>
            </Col>
        </Row>
    )
}


export default ItemProductoDescripcion;