import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Stack } from 'react-bootstrap'
import { Heart, HeartFill } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import { getProductosPath } from 'utils/getImages';
import { addClientFav, deleteClientFav } from 'redux/actions';

import '../../pages/checkout/Checkout.css'

function ProductoComprado({ item }) {

    const dispatch = useDispatch()
    const { clientInfo, logged } = useSelector((stateRedux) => stateRedux)

    const [state, setState] = useState(() => {
        return {
            CANTIDAD: item.CTD,
            PRECIO: item.PRECIO,
            TOTAL: item.PRECIO,
            NOMBRE: item.NOMBRE,
            IMG: getProductosPath(item.TIPO, item.URL_IMAGEN),
            URL_IMAGEN: getProductosPath(item.TIPO, item.URL_IMAGEN),
            URL_IMAGEN_H: (item.TIPO === 'PRODUCTO') && getProductosPath(item.TIPO, item.URL_IMAGEN, true),
            ID_PRODUCTO: item.ID_PRODUCTO,
            FAVORITO: false,
            AÑADIDO: false
        };
    });

    const favoritoHandler = () => {
        // Aca deberíamos mandar un PATCH a los favoritos del usuario agregando el producto ID
        if (state.FAVORITO === false) {
            dispatch(addClientFav(clientInfo.UUID, clientInfo.EMAIL, item.ID_PRODUCTO))
        }
        if (state.FAVORITO === true) {
            dispatch(deleteClientFav(clientInfo.UUID, clientInfo.EMAIL, item.ID_PRODUCTO))
        }
        setState((state) => ({
            ...state,
            FAVORITO: !state.FAVORITO
        }));

    };
    useEffect(() => {
        if (logged) {
            if (clientInfo && clientInfo.FAVORITOS.length) {
                setState((state) => {
                    const isFavorite = clientInfo.FAVORITOS.length > 0 ? clientInfo.FAVORITOS.some((fav) => fav.ID_PRODUCTO === item.ID_PRODUCTO) : false;
                    return {
                        ...state,
                        FAVORITO: isFavorite,
                    }
                })
            }
            if (clientInfo && clientInfo.CARRITO.PRODUCTOS.length) {
                setState((state) => {
                    const isAlreadyAdded = (clientInfo && clientInfo.CARRITO.PRODUCTOS.length > 0) ? clientInfo.CARRITO.PRODUCTOS.some((added) => added.ID_PRODUCTO === state.ID_PRODUCTO) : false
                    return {
                        ...state,
                        AÑADIDO: isAlreadyAdded,
                    }
                })
            }
        }
    }, [clientInfo.FAVORITOS]);

    return (
        <Stack direction='horizontal' className='producto-comprado' gap={3}>
            <div className="PC-img-cont">
                {/* <img className='PC-img'src={img} alt={nombre} /> */}
                <div className='PC-img' style={{ backgroundImage: `url(${state.URL_IMAGEN})` }} alt={state.NOMBRE} />
                <div className="icon-cont">
                    {
                        logged ?
                            <button className='btn-item-producto-common-like' onClick={favoritoHandler}>
                                {state.FAVORITO === true ? <HeartFill></HeartFill> : <Heart></Heart>}
                            </button>
                            : null
                    }
                    {/*<Link><HeartFill className="heart-icon"></HeartFill></Link>*/}
                </div>
            </div>

            <div className="PC-info-cont">
                <h4>{state.NOMBRE ? state.NOMBRE : 'Nombre del producto'}</h4>
                <span className='PC-info-piezas'>Piezas {state.CANTIDAD ? state.CANTIDAD : '0'}</span>
                <p className='PC-info-precio'>{state.PRECIO ? "$" + (((state.CANTIDAD * parseFloat(item.PESO)) * parseFloat(state.PRECIO)).toFixed(2)) : '$00.00'}</p>
            </div>
        </Stack>
    )
}

export default ProductoComprado