import React, { useState } from 'react'

function PencilIcon({ onClick }) {
  const [editMode, setEditMode] = useState(false);
  const [hovered, setHovered] = useState(false);

  const handleMouseOver = () => {
    setHovered(true);
  };

  const handleMouseOut = () => {
    setHovered(false);
  };
  const event = () => {
    if (onClick()){
      setEditMode((editMode) => !editMode)
    }
  }
  return (
    <button className='icon-btn' 
      onClick={() => event()}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}>
      <img src={editMode||hovered ? '/images/icons/pencil-selected.svg' : '/images/icons/pencil-default.svg'}></img>
    </button>
  )
}
export default PencilIcon