import React, { useEffect, useState } from 'react'

function Whatsapp({ isHeader }) {
    const [ visible, setVisible ] = useState(isHeader ? true : false);

    // Agrega un escuchador de eventos para el desplazamiento cuando el componente se monta
    useEffect(() => {
        window.addEventListener('scroll', () => {
            let isInTop = window.scrollY < 50;
            setVisible(isHeader ? isInTop : !isInTop)
        });
    }, []);
    return (
        <a hidden={!visible} href="https://api.whatsapp.com/send?phone=%2B529999302222" target='_blank'>
            <img className='custom-whatsapp-icon' src="/images/desktop/whatsapp.png" alt="" />
        </a>
    )
}
export default Whatsapp