import React, { useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, HashNavigation } from "swiper";
import ItemProducto from '../item-producto/item-producto.common';
import { Link } from 'react-router-dom';


// Import Swiper styles
import 'bootstrap/dist/css/bootstrap.min.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/a11y';
import './cross-sell.common.css';


function CrossSell(prop) {
  const [mobile, setMobile] = React.useState(false)
  window.addEventListener('resize', () => { document.documentElement.clientWidth > 1000 ? setMobile(false) : setMobile(true) })
  window.addEventListener('load', () => { document.documentElement.clientWidth > 1000 ? setMobile(false) : setMobile(true) })

  const [id, setId] = useState(Math.floor(Math.random() * 1000000))


  return (
    <div className={prop.backgroundImage === true ? 'background-cross-sell-img' : 'background-cross-sell-gradient'} style={{ background: `${prop.transparent && 'transparent'}` }} >
      <div className='div-cabecera-cross-sell' style={{ display: `${!prop.titulo && !prop.subtitulo && 'none'}` }}>
        <h1 className='h1-cabecera-cross-sell'>{prop.titulo}</h1>
        <text className='text-cabecera-cross-sell'>{prop.subtitulo}</text>
      </div>
      {
        mobile ?
          <Swiper
            initialSlide={0}
            slidesPerView={"auto"}
            spaceBetween={7}
            hashNavigation={{
              watchState: true
            }}
            modules={[Navigation, Pagination, HashNavigation]}
            navigation={{
              nextEl: ".image-swiper-cross-sell-button-next",
              prevEl: ".image-swiper-cross-sell-button-prev",
              disabledClass: "swiper-button-disabled",
              clickable: true,
            }}
            centeredSlides={true}
            centeredSlidesBounds={true}
            centerInsufficientSlides={true}
            id={id}
          >
            {prop.items.map(p => (
              <SwiperSlide><ItemProducto item={p} buttonText="AÑADIR AL CARRITO" /></SwiperSlide>
            ))}
            {/* <button ref={prevRef}>previous</button>
            <button ref={nextRef}>next</button> */}
            <div className="swiper-button-prev image-swiper-cross-sell-button-prev image-swiper-cross-sell-button"></div>
            <div className="swiper-button-next image-swiper-cross-sell-button-next image-swiper-cross-sell-button"></div>
          </Swiper>

          :

          <Swiper
            className='swiper-cross-sell'
            slidesPerView={2}
            breakpoints={{
              1100: { slidesPerView: 3},
            }}
            spaceBetween={50}
            initialSlide={0}
            modules={[Navigation]}
            navigation={{
              nextEl: ".image-swiper-cross-sell-button-next",
              prevEl: ".image-swiper-cross-sell-button-prev",
              disabledClass: "swiper-button-disabled",
              clickable: true
            }}
            centeredSlides={false}
            centeredSlidesBounds={true}
            centerInsufficientSlides={true}
            id={id}
          >
            {prop.items.map(p => (
              <SwiperSlide>
                  <ItemProducto item={p} buttonText="AÑADIR AL CARRITO" />
              </SwiperSlide>
            ))}
            <div className="swiper-button-prev image-swiper-cross-sell-button-prev image-swiper-cross-sell-button"></div>
            <div className="swiper-button-next image-swiper-cross-sell-button-next image-swiper-cross-sell-button"></div>
          </Swiper>
      }
      <div className='cont-btn-cross-sell' style={{ display: `${prop.hideVerMas && prop.hideVerMas && 'none'}` }}>
        <Link to={prop.isProductos ? '/app/acompañamientos' : '/app/productos'} className='verMasLink'>Ver más</Link>
      </div>
    </div >
  );
}

export default CrossSell;