import React from 'react'
import { Stack } from 'react-bootstrap'
import { Link, useLocation } from 'react-router-dom'
import ImgMiCuentaSelected from '../../images/mi-cuenta/MiCuenta-Selected.png'
import ImgMiCuentaDefault from '../../images/mi-cuenta/MiCuenta-Default.png'
import ImgMisComprasSelected from '../../images/mi-cuenta/MisCompras-Selected.png'
import ImgMisComprasDefault from '../../images/mi-cuenta/MisCompras-Default.png'

import '../../common/formulario/formularios.css'
import '../../pages/mi-cuenta/MiCuenta.css'
import '../../App.css'
import '../../index.css'

function MiCuentaMenu() {

    const location = useLocation()
    const { pathname } = location
    
    return (
        <Stack className='my-account-buttons' direction='horizontal'>
            <Stack className='btn-cont'>
                <Link to='/app/cuenta/datos'>
                    <img clasName='btn-img' src={window.location.pathname.includes("/cuenta/datos")  ? ImgMiCuentaSelected : ImgMiCuentaDefault} alt="" />
                    <span  className={`btn-title ${window.location.pathname.includes("/cuenta/datos") ? "selected" : "default"}`}>Mis datos</span>
                </Link>
            </Stack>
            <Stack className='btn-cont'>
                <Link to='/app/cuenta/compras'>
                    <img clasName='btn-img' src={window.location.pathname.includes("/cuenta/compras") ? ImgMisComprasSelected : ImgMisComprasDefault} alt="" />
                    <span className={`btn-title ${window.location.pathname.includes("/cuenta/compras") ? "selected" : "default"}`}>Mis compras</span>
                </Link>
            </Stack>
        </Stack>
    )
}
export default MiCuentaMenu