export const getWeekDate = (dateString) => {
    // Paso 1: Convertir la cadena a un objeto de fecha de JavaScript
    const date = new Date(dateString);

    // Paso 2: Obtener el día de la semana y el nombre del mes
    const weekdays = ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"];
    const months = [
        "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
    ];
    
    return {
        day : date.getDay(), 
        hour : date.getHours(),
        minutes : date.getMinutes(),
        weekday : weekdays[date.getDay()],
        month : months[date.getMonth()]   
    };
};

export const parseDireccion = (direccion) => {
    const direccionSplitted = direccion.split(", ");
    let direccionArray = [];

    const calle = direccionSplitted[0];
    const colonia = direccionSplitted[1];
    const municipio = direccionSplitted[2] ? direccionSplitted[2] : "";
    const cp = direccionSplitted[3] ? direccionSplitted[3] : "";
    const estado = direccionSplitted[4];

    direccionArray.push(calle);
    direccionArray.push(colonia);
    direccionArray.push(municipio);
    direccionArray.push("CP: "+ cp);
    direccionArray.push(estado);

    return direccionArray.join(", ");
  }