import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Form } from 'react-bootstrap'

import PostalCode from '../../common/formulario/PostalCode.component';
import '../../common/formulario/formularios.css'
import '../../App.css'
import '../../index.css'
import { updateCheckoutForm, updateGlobalState, validateForm } from 'redux/actions';
import { validate } from 'utils/validator';

function ClientDir({ index, editMode, item, isNew }) {
    const dispatch = useDispatch();

    const ClientDir = `DIRECCION${index}_FORM`;
    const prefix = `postalCodeCheck--CP--${ClientDir}`;

    const state = useSelector((state) => state);
    const { validations, formCheckout } = state;

    const renderForm = [
        [{ value:  'DIRECCION', placeholder: 'Calle' }, { value: 'COLONIA', placeholder: 'Colonia' }],
        [{ value: 'NUMERO_EXT', placeholder: 'No. Exterior' }, { value: 'NUMERO_INT', placeholder: 'No. Interior' }],
        [{ value: 'ESTADO', placeholder: 'Estado' }, { value: 'CIUDAD', placeholder: 'Municipio' }]
    ]
    const [form, setForm] = useState({
        DIRECCION: item.DIRECCION,
        COLONIA: item.COLONIA,
        NUMERO_EXT: item.NUMERO_EXT,
        NUMERO_INT: item.NUMERO_INT,
        ESTADO: item.ESTADO,
        CIUDAD: item.CIUDAD,
        COD_POSTAL: item.COD_POSTAL,
        ID_DIRECCION: item.ID_DIRECCION
    });
    const [codePostalCodeValid, setPostalCodeValid] = useState(false);

    //Métodos
    const onChangeValue = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value }) //Actualiza el valor ingresado al formulario
    }
    const validateInput = (e) => {
        dispatch(validateForm(ClientDir, validate(e.target))) //Actualiza el estado global del campo
    }
    const onChangeCP = () => {
        //Desactiva la edicion
        setPostalCodeValid(false);
        dispatch(updateGlobalState({
            [`postalCodeCheck--CP--${ClientDir}`]: false
        }))
        //Resetea el formulario
        //setForm({
        //    DIRECCION: null,
        //    COLONIA: null,
        //    NUMERO_EXT: null,
        //    NUMERO_INT: null,
        //    ESTADO: null,
        //    CIUDAD: null,
        //    COD_POSTAL: null,
        //}) //Actualiza el valor ingresado al formulario
        //dispatch(validateForm( ClientDir, validate(e.target))) //Actualiza el estado global del campo
    }

    //Actualiza el formulario global
    useEffect(() => {
        dispatch(updateCheckoutForm({ [`DIRECCION${index}_FORM`]: form }))
        dispatch(updateGlobalState({
            [`CP--${ClientDir}`]: form.COD_POSTAL,
            [`postalCodeCheck--CP--${ClientDir}`]: isNew ? !!state[`postalCodeCheck--CP--${ClientDir}`] : true
        }))
    }, [form]);

    //Revisa actualizaciones en el codigo postal
    useEffect(() => {
        setForm({ ...form, COD_POSTAL: state[`CP--${ClientDir}`] })
    }, [state[`CP--${ClientDir}`]]);
    //Revisa cambios en la validación del código postal
    useEffect(() => {
        setPostalCodeValid(state[`postalCodeCheck--CP--${ClientDir}`]);
    }, [state[`postalCodeCheck--CP--${ClientDir}`]]);
    //Revisa cambios en editMode
    useEffect(() => {
        if (editMode && !isNew) {
            dispatch(updateGlobalState({
                [`postalCodeCheck--CP--${ClientDir}`]: true
            }))
        }
    }, [editMode]);

    //Valida si se elimina un elemento, actualiza sus valores
    useEffect(() => {
        setForm(formCheckout[`DIRECCION${index}_FORM`]);
    }, [formCheckout[`DIRECCION${index}_FORM`]]);

    return (
        <div className='dir'>
            {
                editMode ?
                    <Row className="address-row">
                        <Col lg={true}>
                            <PostalCode id={ClientDir} customEvent={onChangeCP}></PostalCode>
                        </Col>
                        <Col lg={true} />
                    </Row>
                    :
                    <Row className="address-row">
                        <Col lg={true}>
                            <div className='address-input'><p className='info-title'>Código postal: </p><p><b>{item.COD_POSTAL}</b></p></div>
                        </Col>
                    </Row>
            }
            {
                editMode ?
                    renderForm.map(aRows =>
                        <Row className="address-row">
                            {
                                aRows.map(oColumn =>
                                    <Col lg={true}>
                                        <Form.Control disabled={!(codePostalCodeValid && editMode)} className={`address-input ${(codePostalCodeValid && editMode) ? 'golden-input' : 'grey-input'}`}
                                            type="text"
                                            isInvalid={!!validations[ClientDir][oColumn.value]}
                                            required={oColumn.value !== 'NUMERO_INT'}
                                            validations={JSON.stringify(oColumn.validations || {
                                                minSize: 1
                                            })}
                                            name={oColumn.value}
                                            placeholder={oColumn.placeholder || oColumn.value}
                                            value={form[oColumn.value]}
                                            onBlur={validateInput}
                                            onChange={onChangeValue} />
                                        <Form.Control.Feedback className="input-feedback" type='invalid'>{validations[ClientDir][oColumn.value]}</Form.Control.Feedback>
                                    </Col>
                                )
                            }
                        </Row>
                    )
                    :
                    renderForm.map(aRows =>
                        <Row className="address-row">
                            {
                                aRows.map(oColumn =>
                                    <Col lg={true}>
                                        <div className='address-input'>
                                            <p className='info-title'>{oColumn.placeholder}{':'}</p>
                                            <p><b>{form[oColumn.value]}</b></p></div>
                                    </Col>
                                )
                            }
                        </Row>
                    )
            }
        </div>
    )
}

export default ClientDir