//import React from 'react'
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Stack from 'react-bootstrap/Stack';

import ItemCard from '../../components/carrito/ItemCard'
import { formatterPrice } from 'utils/formatter';
import './CartOverview.component.css'
import urlSubcuenta from 'utils/urlSubcuenta';


function Resumen({ cardsEnabled }) {
    const { logged, formCheckout } = useSelector((state) => state)
    const [totalPrice, setTotalPrice] = useState(formCheckout.TOTAL);
    const [productos, setProductos] = useState([]);


    const handleLogin = () => {
        if (!logged) {
            // Realizar el redireccionamiento con el historial en el navegador
            window.location.href = urlSubcuenta() + '/login/';
        }
    };

    useEffect(() => {
        setTotalPrice(formCheckout.TOTAL)
    }, [formCheckout.TOTAL])
    useEffect(() => {
        if (formCheckout.PRODUCTOS) {
            setProductos(Object.values(formCheckout.PRODUCTOS))
        }
    }, [formCheckout.PRODUCTOS])

    return (
        <Stack className="overview">
            <Stack direction="horizontal" className="overview-title-cont">
                <h3 className="title-h3">Resumen del pedido</h3>
                <h2 className="title-total">{`Total: ${formatterPrice(totalPrice)}`}</h2>
            </Stack>
            <p className="title-description red-font">Ten en cuenta que el peso de los productos es estimado, por lo que el total del importe de tu pedido puede variar en el cobro final. Con base en el peso final del producto, se ajustará el importe final y éstos se reflejarán en tu ticket de compra al entregar el producto.</p>
            {
                cardsEnabled &&
                <Stack direction='horizontal' gap={5} className="checkout-items-container">
                    {
                        productos.length > 0 ? productos.map(item => <ItemCard PRODUCTO={item} />) : null
                    }
                </Stack>
            }
        </Stack>
    )
}

export default Resumen