import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

function Login() {
    const navigate = useNavigate()
    useEffect(() => {
        navigate('/app/')
    }, [])
    return (
        <div></div>
    )
}

export default Login