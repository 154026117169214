import React, { useEffect, useState } from 'react'
import { Stack } from 'react-bootstrap'
import { CaretRightFill } from 'react-bootstrap-icons';
import PedidoEnProceso from '../../images/mi-cuenta/PedidoEnProceso.svg'
import PedidoEnCamino from '../../images/mi-cuenta/PedidoEnCamino.svg'
import PedidoEntregado from '../../images/mi-cuenta/PedidoEntregado.svg'


import '../../common/formulario/formularios.css'
import '../../pages/mi-cuenta/MiCuenta.css'
import '../../App.css'
import '../../index.css'

function DeliveryStatus({ item }) {

    // Necesito saber primero si el usuario tiene o no un pedido en curso
    const [status, setStatus] = useState(1)


    // Eliminado logico de pedido
    const handleDeleteDir = (e) => {
        e.preventDefault()
        // dispatch(deleteDirById(dir.ID_CLIENTE, dir.ID_DIRECCION))
    }

    useEffect(() => {
        if (item.ESTADO === 1 || item.ESTADO === 2) {
            setStatus(3)
        } else if (item.ESTADO_RUTA !== 0) {
            setStatus(2)
        } else {
            setStatus(1)
        }
    }, [item])

    return (
        <Stack gap={5}>
            <Stack direction='horizontal' style={{ justifyContent: 'space-between' }} >
                <div className='pedido-info-cont'>
                    <h2 className="title-h2">{status !== 3 ? 'Pedido en curso' : 'Pedido entregado'}</h2>
                    <span className='id-pedido'>Transacción #{item ? item.ID_TRANSACCION : 'loading...'} - ID #{item ? item.ID : 'loading...'}</span>
                </div>
                {/* {status === 1 &&
                    <Stack style={{ flex: 'initial' }}>
                        <button onClick={handleDeleteDir} className='delete-dir-btn'>Eliminar pedido</button>
                    </Stack>
                } */}
            </Stack>

            <Stack className='delivery-status' direction='horizontal'>
                <Stack className='status-cont'>
                    <div className={`svg-container ${status === 1 ? 'status-active' : 'status-default'}`}>
                        <img className='status-img' src={PedidoEnProceso} />
                    </div>
                    <span className='status-text'>Pedido en proceso</span>
                </Stack>
                <Stack className='caret-cont'>
                    <span className='caret-text'><CaretRightFill /><CaretRightFill /></span>
                </Stack>
                <Stack className='status-cont'>
                    <div className={`svg-container ${status === 2 ? 'status-active' : 'status-default'}`}>
                        <img className='status-img' src={PedidoEnCamino} />
                    </div>
                    <span className='status-text'>Pedido en en camino</span>
                </Stack>
                {/* <span>&gt;&gt;</span> */}
                <Stack className='caret-cont'>
                    <span className='caret-text'><CaretRightFill /><CaretRightFill /></span>
                </Stack>
                <Stack className='status-cont'>
                    <div className={`svg-container ${status === 3 ? 'status-active' : 'status-default'}`}>
                        <img className='status-img' src={PedidoEntregado} />
                    </div>
                    <span className='status-text'>Pedido {status === 3 && item.ESTADO === 1 ? 'entregado' : status === 3 && item.ESTADO === 2 ? 'cancelado' : 'entregado'}</span>
                </Stack>
            </Stack>

        </Stack>
    )
}

export default DeliveryStatus