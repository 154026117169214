import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Form } from 'react-bootstrap';
import { updateCheckoutForm, validateForm } from 'redux/actions';
import { validate } from 'utils/validator';

import './formularios.css'
import '../../App.css'
import '../../index.css'

function CreditCardForm({ title, subtitle }) {
  const dispatch = useDispatch();
  const renderForm = [
    [
      {
        type: 'text',
        validate: {
          minSize: 1
        },
        value: 'NombreTarjeta',
        placeholder: 'Nombre que aparece en la tarjeta'
      }
      ,
      {
        type: 'number',
        validate: {
          minSize: 13
        },
        value: 'NumeroTarjeta',
        placeholder: 'Número de tarjeta'
      }
    ],
    [
      {
        type: 'text',
        validate: {
          minSize: 5,
          maxSize: 5,
          match: "^\\d{2}/\\d{2}$",
          maxLength: 5
        },
        value: 'VencimientoTarjeta',
        placeholder: 'MM/YY'
      }
      ,
      {
        type: 'number',
        validate: {
          minSize: 3,
          maxSize: 4,
          maxLength: 3,
        },
        value: 'CVCTarjeta',
        placeholder: 'CVC'
      }
    ]
  ]
  const { formCheckout, validations } = useSelector((state) => state);
  const [form, setForm] = useState(formCheckout.CARD_FORM);

  // Metodo para actualizar el valor en el formulario
  const onChangeValue = (e) => {
    const { name, value } = e.target;
    // Formatear la fecha de vencimiento
    if (name === 'VencimientoTarjeta') {
      const formattedValue = value.replace(/\D/g, '');
      if (formattedValue.length > 2) {
        const month = formattedValue.slice(0, 2);
        const year = formattedValue.slice(2);
        setForm({ ...form, [name]: `${month}/${year}` });
      } else {
        setForm({ ...form, [name]: formattedValue });
      }
    } 
    else {
      setForm({ ...form, [name]: value });
    }

  };
  
  const validateInput = (e) => {
    dispatch(validateForm('CARD_FORM', validate(e.target))); // Actualiza el estado global del campo
  }


  //Actualiza el formulario global
  useEffect(() => {
    dispatch(updateCheckoutForm({ CARD_FORM: form }))
  }, [form]);

  return (
    <Form.Group controlId='delivery-form'>
      {title && <h3 className="title-h3">{title}</h3>}
      {subtitle && <p className="title-description">{subtitle}</p>}
      {
        renderForm.map(aRows =>
          <Row className="address-row">
            {
              aRows.map(oColumn =>
                <Col lg={true}>
                  <Form.Control className="address-input golden-input"
                    type={oColumn.type}
                    isInvalid={validations.CARD_FORM[oColumn.value]}
                    required={true}
                    validations={JSON.stringify(oColumn.validate)}
                    name={oColumn.value}
                    placeholder={oColumn.placeholder || oColumn.value}
                    value={form[oColumn.value]}
                    onBlur={validateInput}
                    onChange={onChangeValue}
                    maxLength={oColumn.maxLength && oColumn.maxLength} />
                  <Form.Control.Feedback className="input-feedback" type='invalid'>{validations.CARD_FORM[oColumn.value]}</Form.Control.Feedback>
                </Col>
              )
            }
          </Row>
        )
      }
    </Form.Group>
  )
}

export default CreditCardForm