import React, { useEffect, useRef, useState } from 'react';
import Swal from 'sweetalert2';
import { useDispatch, useSelector } from 'react-redux';
import { Dash, Plus, QuestionCircle, Heart, HeartFill } from 'react-bootstrap-icons';
import CarritoCostillas from '../../images/carrito/Carrito-Costillas.png'
import { getImagesCard } from 'utils/getImages';
import { addClientFav, changeItemQuantity, deleteClientFav, deleteItemClientCart, updateCheckoutForm } from 'redux/actions';
import { formatterNumber, formatterPrice } from 'utils/formatter';
import useUnloggedCart from 'redux/actions/unloggedCart';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import 'bootstrap/dist/css/bootstrap.min.css';
import '../../App.css';
import '../../index.css';
import './itemCard.css';

const ItemCard = ({ PRODUCTO }) => {
    const { removeFromCart, addToCart } = useUnloggedCart()
    const dispatch = useDispatch()
    const { formCheckout, clientInfo, logged } = useSelector((state) => state)
    const [cantidad, setCantidad] = useState(formCheckout.PRODUCTOS[PRODUCTO.ID_PRODUCTO]?.CANTIDAD || 0);
    const [favorito, setFavorito] = useState(false);
    const [showTooltip, setShowTooltip] = useState(false);
    const buttonRef = useRef(null);

    const changeCantidad = (value) => {
        //Si quiere restar mas del ultimo item no lo permite
        if (parseInt(value) < 0 && parseInt(cantidad) === 1) {
            return
        }
        let newCantidad = parseInt(cantidad) + parseInt(value);
        //Actualiza la cantidad de items seleccionada
        const BODY_OBJ = {
            ID_PRODUCTO: PRODUCTO.ID_PRODUCTO,
            CANTIDAD: parseInt(newCantidad),
        }
        if (logged) {
            dispatch(changeItemQuantity(clientInfo.UUID, clientInfo.EMAIL, BODY_OBJ))
            // const newTotal = formCheckout.PRODUCTOS.reduce((acc, item) => acc + parseFloat(item.PRECIO) * parseInt(item.CANTIDAD), 0);
            // dispatch({
            //   type: 'UPDATE_CART_TOTAL',
            //   payload: parseFloat(parseFloat(newTotal).toFixed(2)),
            // });

            dispatch(updateCheckoutForm({ TOTAL: Object.values(formCheckout.PRODUCTOS).reduce((sum, obj) => sum + parseFloat(obj.TOTAL), 0) }))

        } else {
            addToCart({ ID_PRODUCTO: PRODUCTO.ID_PRODUCTO, CANTIDAD: value, PRECIO: PRODUCTO.PRECIO, PESO: PRODUCTO.PESO })
        }

    }

    const handleDelete = () => {
        if (logged) {
            if (clientInfo && clientInfo.UUID && clientInfo.EMAIL) {
                Swal.fire({
                    title: '¿Estas seguro que deseas eliminar este item del carrito?',
                    icon: 'warning',
                    showCloseButton: true,
                    showCancelButton: true,
                    confirmButtonText: 'Confirmar',
                    cancelButtonText: `Cancelar`,
                    confirmButtonColor: '#E5CB65',
                    background: '#333',
                    color: '#FFF'
                }).then((result) => {
                    if (result.isConfirmed) {
                        dispatch(deleteItemClientCart(clientInfo.UUID, clientInfo.EMAIL, PRODUCTO.ID_PRODUCTO))
                        // Swal.fire('Eliminado del carrito correctamente', '', 'success')
                    }

                })
            }
        } else {
            Swal.fire({
                title: '¿Estas seguro que deseas eliminar este item del carrito?',
                icon: 'warning',
                showCloseButton: true,
                showCancelButton: true,
                confirmButtonText: 'Confirmar',
                cancelButtonText: `Cancelar`,
                confirmButtonColor: '#E5CB65',
                background: '#333',
                color: '#FFF'
            }).then((result) => {
                if (result.isConfirmed) {
                    removeFromCart(PRODUCTO.ID_PRODUCTO)
                    // Swal.fire('Eliminado del carrito correctamente', '', 'success')
                }
            })
        }
    }


    // Favorito handlers
    const favoritoHandler = () => {
        // Aca deberíamos mandar un PATCH a los favoritos del usuario agregando el producto ID
        if (favorito === false) {
            dispatch(addClientFav(clientInfo.UUID, clientInfo.EMAIL, PRODUCTO.ID_PRODUCTO))
        }

        if (favorito === true) {
            dispatch(deleteClientFav(clientInfo.UUID, clientInfo.EMAIL, PRODUCTO.ID_PRODUCTO))
        }
        setFavorito(() => !favorito)
    };

    // Actualiza favorito
    useEffect(() => {
        if (clientInfo.FAVORITOS && clientInfo.FAVORITOS.length > 0) {
            const isFavorite = clientInfo.FAVORITOS.some(producto => producto.ID_PRODUCTO === PRODUCTO.ID_PRODUCTO);
            setFavorito(() => isFavorite)
        }

    }, [clientInfo.FAVORITOS])


    // Intento de arreglar las cantidades y totales
    useEffect(() => {
        if (formCheckout.PRODUCTOS[PRODUCTO.ID_PRODUCTO]) {
            setCantidad(parseInt(formCheckout.PRODUCTOS[PRODUCTO.ID_PRODUCTO].CANTIDAD));
        }
    }, [formCheckout.PRODUCTOS[PRODUCTO.ID_PRODUCTO]?.CANTIDAD, PRODUCTO])


    //Cierra el popuv ded escripción al hacer click en otro lado
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (buttonRef.current && !buttonRef.current.contains(event.target)) {
                setShowTooltip(false);
            }
        };

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    return (
        <div className="cont-flex card">
            <div className="img-cont">
                <div className="cont-block card-img" style={{ backgroundImage: `url(${PRODUCTO && PRODUCTO.TIPO && PRODUCTO.URL_IMAGEN ? getImagesCard(PRODUCTO.TIPO, PRODUCTO.URL_IMAGEN) : CarritoCostillas})` }}></div>

                {
                    logged ?
                        <button className='btn-item-producto-common-like' onClick={favoritoHandler}>
                            {favorito ? <HeartFill></HeartFill> : <Heart></Heart>}
                        </button>
                        : null
                }

            </div>

            <div className="cont-center">
                <div className="info-cont-center">
                    <h4>{PRODUCTO && PRODUCTO.NOMBRE ? PRODUCTO.NOMBRE.toUpperCase() : 'Título del producto'}</h4>
                    {/* <span>{producto ? (producto.DESCRIPCION) : 'Descripción del producto'}</span> */}
                    <p>aprox. {parseFloat((parseFloat(PRODUCTO.PESO) * cantidad).toFixed(2))}kg </p>

                </div>
                <div className="cont-counter cont-flex">
                    <button onClick={() => changeCantidad(-1)}><Dash></Dash></button>
                    <p>{cantidad.toString().padStart(2, '0')}</p>
                    <button onClick={() => changeCantidad(1)}><Plus></Plus></button>
                </div>
            </div>

            <div className="cont-right">
                <div className="question-cont-right">
                    <OverlayTrigger
                        trigger="click"
                        placement="top"
                        overlay={<Tooltip id="tooltip">Ten en cuenta que el peso de los productos es estimado, por lo que el total del importe de tu pedido puede variar en el cobro final. Con base en el peso final del producto, se ajustará el importe final y éstos se reflejarán en tu ticket de compra al entregar el producto.</Tooltip>}
                        show={showTooltip}
                    >
                        <button ref={buttonRef} onClick={() => setShowTooltip(!showTooltip)}>
                            <QuestionCircle />
                        </button>
                    </OverlayTrigger>
                </div>

                <div className="total-cont-right">
                    <p>{formatterPrice(parseFloat((parseFloat(PRODUCTO.PRECIO) * PRODUCTO.PESO) * cantidad ).toFixed(2))}</p>
                    <button onClick={handleDelete}>Eliminar</button>
                </div>

            </div>
        </div>
    )
}

export default ItemCard;