import React  from 'react'
import './formularios.css'
import '../../App.css'
import '../../index.css'


function DeliveryDateTime({ title }) {
    return (
        <>
            {title && <h3 className="title-h3">{title}</h3>}
            <p className="title-description">Si tu pedido lo realizas entre las 7:00am y 12:00 pm se entregará el mismo día en un tiempo 60 a 90 minutos una vez confirmado el pedido. (Excepto Domingos).</p>
            <p className="title-description">Si tu pedido lo realizas después de las 12:00 pm, tu pedido se entregará al día siguiente.</p>
            <p className="title-description">Las entregas de productos se realizan de lunes a sábado de 7:30am a 2:00pm.</p>
            {
                /*
                <Form.Group controlId='delivery-form'>
                {subtitle && <p className="title-description">{subtitle}</p>}
                <Container>
                    <Row className="address-row">
                        <Col lg={true}>
                            <Form.Control 
                                className="address-input golden-input" 
                                type="date" 
                                name="FechaEntrega" 
                                id="" 
                                isInvalid={!!globalErrors.DeliveryDateTime && globalErrors.DeliveryDateTime.FechaEntrega} 
                                value={form.FechaEntrega} 
                                onChange={(e) => setField(e.target.name, e.target.value)} />
                            <Form.Control.Feedback 
                                className="input-feedback" 
                                type='invalid'>{globalErrors.DeliveryDateTime && globalErrors.DeliveryDateTime.FechaEntrega}</Form.Control.Feedback>
                        </Col>
                        <Col lg={true}>
                            <Form.Control 
                                className="address-input golden-input" 
                                type="time" 
                                name="HoraEntrega" 
                                id="" 
                                isInvalid={!!globalErrors.DeliveryDateTime && globalErrors.DeliveryDateTime.HoraEntrega} 
                                value={form.HoraEntrega} 
                                onChange={(e) => setField(e.target.name, e.target.value)} />
                            <Form.Control.Feedback 
                                className="input-feedback" 
                                type='invalid'>{globalErrors.DeliveryDateTime && globalErrors.DeliveryDateTime.HoraEntrega}</Form.Control.Feedback>
                        </Col>
                    </Row>
                </Container>
                </Form.Group>
                
                */
            }
        </>
    )
}

export default DeliveryDateTime