import React, { useEffect, useState } from 'react'
import { Stack, Button } from 'react-bootstrap'
import BackgroundCarritoDesktop from '../../images/navbar/BackgroundCarritoDesktop.png'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import ItemCard from './ItemCard'
import { formatterPrice } from 'utils/formatter'

import './CartPanel.css'
import '../../App.css'
import '../../pages/carrito/carrito.page.css'

function CartPanel({ propiedades }) {
  const { cartPanel, handleOpen } = propiedades
  const { formCheckout, logged } = useSelector((state) => state)

  const [mobile, setMobile] = useState(false);
  const [totalPrice, setTotalPrice] = useState(formCheckout.TOTAL);
  const [productos, setProductos] = useState([]);

  if (mobile) {
    if (cartPanel) {
      document.documentElement.style.overflow = 'hidden';
      document.body.style.overflow = 'hidden';
    } else {
      document.documentElement.style.overflow = '';
      document.body.style.overflow = '';
    }
  }

  //Para tener un conteo del total y productos seleccionados
  useEffect(() => {
    setProductos(Object.values(formCheckout.PRODUCTOS));
  }, [formCheckout.PRODUCTOS])


  useEffect(() => {
    setTotalPrice(formCheckout.TOTAL);
  }, [formCheckout.TOTAL])

  // Setea si es mobile o no, según el width del viewport.
  useEffect(() => {
    const handleMediaQuery = (event) => {
      setMobile(event.matches);
    };
    const mediaQuery = window.matchMedia('(max-width: 600px)');
    setMobile(mediaQuery.matches);
    mediaQuery.addEventListener('change', handleMediaQuery);
    return () => {
      mediaQuery.removeEventListener('change', handleMediaQuery);
    };
  }, []);

  return (
    <div className={`cart-panel ${cartPanel && 'open'}`} style={{ backgroundImage: `url(${BackgroundCarritoDesktop})` }}>
      <Stack className='cart-top-cont' direction='horizontal'>
        <button className='closed-button' onClick={handleOpen}/>
        <Stack direction='horizontal'>
          <Stack direction='vertical'>
            <p className='cart-panel-total'>Total:<span>{formatterPrice(parseFloat(totalPrice).toFixed(2))}</span></p>
          </Stack>
        </Stack>
      </Stack>

      <Stack style={{ marginTop: '1rem', gap: '1em', height: '75%', overflowY: 'auto' }}>
        {
          productos.length > 0 ? productos.map(item => <ItemCard PRODUCTO={item} />) :

            <div className="cont-block purchases-buy-more-container">
              <p>Tu carrito de Cortes Selectos Kekén aún esta vacío. Conoce todos nuestros productos. </p>
              <div className="cont-flex purchases-links-container">
                <Link to="/app/productos">
                  <Button onClick={handleOpen}>Ver productos</Button>
                </Link>
              </div>
            </div>
        }
      </Stack>
      {
        productos.length ? <Link to={`/app/carrito`}>
          <button className='btn-primary btn-carrusel-home' onClick={handleOpen} >COMPRAR</button>
        </Link> : null

      }

    </div>
  )
}

export default CartPanel