import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Heart, HeartFill } from 'react-bootstrap-icons';
import { Plus } from 'react-bootstrap-icons';
import { Dash } from 'react-bootstrap-icons';

import './item-producto.common.css'
import { getImagesCard } from 'utils/getImages';
import { addClientFav, addItemClientCart, deleteClientFav, updateCheckoutForm, updateItem } from 'redux/actions';
import useUnloggedCart from 'redux/actions/unloggedCart'
import { Link } from 'react-router-dom';
import { formatterPrice } from 'utils/formatter';


function ItemProducto({ item, buttonText }) {
  const { addToCart, isItemInCart } = useUnloggedCart()
  const dispatch = useDispatch()
  const { clientInfo, logged, formCheckout } = useSelector((stateRedux) => stateRedux)
  const [state, setState] = useState(() => {
    return {
      CANTIDAD: "01",
      PRECIO: parseFloat(item.PRECIO), // Precio por KG
      PESO: parseFloat(item.PESO), // Peso por "paquete"
      TOTAL: Math.ceil((parseFloat(item.PRECIO) * parseFloat(item.PESO)) * 100) / 100,
      NOMBRE: item.NOMBRE,
      IMG: getImagesCard(item.TIPO, item.URL_IMAGEN),
      URL_IMAGEN: getImagesCard(item.TIPO, item.URL_IMAGEN),
      URL_IMAGEN_H: (item.TIPO === 'PRODUCTO') && getImagesCard(item.TIPO, item.URL_IMAGEN, true),
      ID_PRODUCTO: item.ID_PRODUCTO,
      FAVORITO: false,
      AÑADIDO: false,
    };
  });

  const favoritoHandler = () => {
    // Aca deberíamos mandar un PATCH a los favoritos del usuario agregando el producto ID
    if (state.FAVORITO === false) {
      dispatch(addClientFav(clientInfo.UUID, clientInfo.EMAIL, item.ID_PRODUCTO))
    }

    if (state.FAVORITO === true) {
      dispatch(deleteClientFav(clientInfo.UUID, clientInfo.EMAIL, item.ID_PRODUCTO))
    }
    setState((state) => ({
      ...state,
      FAVORITO: !state.FAVORITO
    }));

  };

  const sumarItem = () => {
    let nuevaCantidad = parseInt(state.CANTIDAD);
    nuevaCantidad++;
    setState((state) => ({
      ...state,
      TOTAL: (nuevaCantidad * parseFloat(state.PESO)) * parseFloat(state.PRECIO)
    }));

    nuevaCantidad = formatearNumero(nuevaCantidad);
    setState((state) => ({
      ...state,
      CANTIDAD: nuevaCantidad
    }));
  };

  const restarItem = () => {
    let nuevaCantidad = parseInt(state.CANTIDAD);
    if (nuevaCantidad > 1) {
      nuevaCantidad--;
      setState((state) => ({
        ...state,
        TOTAL: (nuevaCantidad * parseFloat(state.PESO)) * parseFloat(state.PRECIO)
      }));

      nuevaCantidad = formatearNumero(nuevaCantidad);
      setState((state) => ({
        ...state,
        CANTIDAD: nuevaCantidad
      }));
    } else {
      setState((state) => ({
        ...state,
        CANTIDAD: "01"
      }));
    }
  };

  const formatearNumero = (n) => {
    if (n < 10) {
      n = "0" + n;
    } else {
      n = n.toString();
    }
    return n;
  };

  const handleCart = (e) => {
    e.preventDefault()
    // Actualiza los productos en global state y actualiza el total del carrito
    if (logged) {
      if (formCheckout.PRODUCTOS[item.ID_PRODUCTO]) {
        //Actualiza la cantidad de items seleccionada
        const nuevaCantidad = parseInt(formCheckout.PRODUCTOS[item.ID_PRODUCTO].CANTIDAD) + parseInt(state.CANTIDAD)
        dispatch(updateItem(item.ID_PRODUCTO, { CANTIDAD: nuevaCantidad }))
        
        //Actualiza el precio total
        dispatch(updateCheckoutForm({ TOTAL: formCheckout.TOTAL + ((nuevaCantidad * parseFloat(state.PESO)) * parseFloat(state.PRECIO)) }))
        dispatch(addItemClientCart(clientInfo.UUID, clientInfo.EMAIL, {
          ID_PRODUCTO: item.ID_PRODUCTO,
          CANTIDAD: nuevaCantidad
        }))
      } else {
        dispatch(addItemClientCart(clientInfo.UUID, clientInfo.EMAIL, {
          ID_PRODUCTO: item.ID_PRODUCTO,
          CANTIDAD: parseInt(state.CANTIDAD)
        }))
      }
    } else {
      addToCart({ ID_PRODUCTO: item.ID_PRODUCTO, CANTIDAD: parseInt(state.CANTIDAD), PRECIO: parseFloat(state.PRECIO), PESO: parseFloat(state.PESO) })
    }

    setState((state) => {
      return {
        ...state,
        AÑADIDO: true
      }
    })
  }
  useEffect(() => {
    if (logged) {
      if (clientInfo && clientInfo.FAVORITOS.length) {
        setState((state) => {
          const isFavorite = clientInfo.FAVORITOS.length > 0 ? clientInfo.FAVORITOS.some((fav) => fav.ID_PRODUCTO === item.ID_PRODUCTO) : false;
          return {
            ...state,
            FAVORITO: isFavorite,
          }
        })
      }
      if (clientInfo && clientInfo.CARRITO.PRODUCTOS.length) {
        setState((state) => {
          const isAlreadyAdded = (clientInfo && clientInfo.CARRITO.PRODUCTOS.length > 0) ? clientInfo.CARRITO.PRODUCTOS.some((added) => added.ID_PRODUCTO === state.ID_PRODUCTO) : false
          return {
            ...state,
            AÑADIDO: isAlreadyAdded,
          }
        })
      }
    }

    // Checkea si el item esta añadido al carrito del usuario no logeado.
    else {
      const isAlreadyAdded = isItemInCart()
      setState((state) => {
        return {
          ...state,
          AÑADIDO: isAlreadyAdded
        }
      })
    }
  }, [clientInfo.FAVORITOS, clientInfo.CARRITO && clientInfo.CARRITO.PRODUCTOS]);

  return (
    <div className='div-item-producto-block'>
      <div className='div-img-item-producto-common'>
        <Link to={`/app/${item.TIPO === 'PRODUCTO' ? 'pdpproductos' : 'pdpacompañamientos'}/${item.ID_PRODUCTO}`}>
          <img className='img-item-producto-common' src={state.IMG && state.IMG}
            onMouseEnter={() => (state.URL_IMAGEN_H ? setState((state) => ({ ...state, IMG: state.URL_IMAGEN_H })) : false)}
            onMouseOut={() => setState((state) => ({ ...state, IMG: state.URL_IMAGEN }))} alt="">
          </img>
        </Link>
        {
          logged &&
          <button className='btn-item-producto-common-like' onClick={favoritoHandler}>
            {state.FAVORITO === true ? <HeartFill></HeartFill> : <Heart></Heart>}
          </button>
        }
        <span className='span-item-producto-common-img-descripcion'>{state.NOMBRE}</span>
      </div>
      <div className='div-cantidad-complementos-parrilla-item'>
        <button className='btn-item-producto-common-menos-mas' onClick={restarItem}>
          <Dash></Dash>
        </button>
        <span className='text-color-white span-item-producto-common-contador'>{state.CANTIDAD}</span>
        <button className='btn-item-producto-common-menos-mas' onClick={sumarItem}>
          <Plus></Plus>
        </button>
      </div>
      <div className='div-item-producto-common-precio'>
        <div className='div-item-producto-common-peso-aprox text-item-producto-common-peso'>
          <span className='body-copy'>Aprox. {parseFloat(parseFloat(state.PESO).toFixed(2))} kg</span>
          <span className='body-copy'>paquete</span>
        </div>
        <span className='text-color-white text-item-producto-common-precio'>{formatterPrice(parseFloat(state.TOTAL).toFixed(2))}</span>
      </div>
      <div className='div-item-producto-common-agregar-item'>
        <button
          className='btn-dorado btn-item-producto-common-agregar-item'
          onMouseEnter={() => (state.URL_IMAGEN_H ? setState((state) => ({ ...state, IMG: state.URL_IMAGEN_H })) : false)}
          onMouseOut={() => setState((state) => ({ ...state, IMG: state.URL_IMAGEN }))}
          onClick={(e) => handleCart(e)}
        >
          {buttonText ? buttonText : 'AÑADIR AL CARRITO'}
        </button>
      </div>
    </div>
  );
}

export default ItemProducto;