
import ItemProducto from '../../common/item-producto/item-producto.common';
import CrossSell from '../../common/cross-sell/cross-sell.common'
import HeroBanner from '../../common/hero-banner.common';
import { getBannerImg } from 'utils/getImages';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './acompañamientos.page.css';
import { useSelector } from 'react-redux';

import { QuestionCircle } from 'react-bootstrap-icons';

function Acompañamientos() {
  const { productos, acompañamientos } = useSelector((state) => state)



  return (
    <div>
      <HeroBanner title='COMPLEMENTA <br/> TU PARRILLA' subtitle='CONOCE LOS COMPLEMENTOS PARA CARNE ASADA QUE TE AYUDARÁN A CONQUISTAR LA PARRILLA' backgroundImg={getBannerImg("Acompaniamientos")}></HeroBanner>
      {/* <div className='cabecera-producto-img'>
            <h1 className='text-color-white'>TODO EL SABOR DEL CERDO A LA PARRILLA</h1>
            <span className='text-color-white'>CONOCE NUESTRA VARIEDAD DE CORTES CARNE DE CERDO</span>
          </div> */}
      <div className='background-color-gradient-black-grey div-acompañamiento-acompañamientos'>
        <div>
          <Row className='row-products-container'>
            {acompañamientos.map(p => (
              <Col sm="12" md="12" xl="4" lg="6" className='div-acompañamiento-item-padding'>
                <ItemProducto item={p} buttonText="AÑADIR AL CARRITO" />
              </Col>
            ))}
          </Row>

          <div className='tooltip-container'>
            <div className='tooltip-sub-container'>
              <span>
                <QuestionCircle />
              </span>
              <p>
                Ten en cuenta que el peso de los productos es estimado, por lo que el total del importe de tu pedido puede variar en el cobro final. Con base en el peso final del producto, se ajustará el importe final y éstos se reflejarán en tu ticket de compra al entregar el producto.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <CrossSell backgroundImage={true} titulo={"Todo el sabor del cerdo a la parrilla"} subtitulo={"Conoce nuestra variedad de cortes de carne de cerdo."}
          items={productos} />
      </div>
    </div>
  );
}

export default Acompañamientos;