import React from 'react'
import { Stack, Row, Col } from 'react-bootstrap';
import CartOverview from './CartOverview.component'
import CreditCardForm from '../../common/formulario/CreditCardForm'
import BillingInfo from '../../common/formulario/BillingInfo'

import './Checkout.css'
import '../../index.css'
import '../../App.css'
import '../../common/formulario/formularios.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import { useDispatch } from 'react-redux';
import { resetFormCheckout } from '../../redux/actions';

function CheckoutPayment({ properties }) {
  const { setSelectedPage } = properties;
  const dispatch = useDispatch();
  const handleSubmit = () => {
    dispatch(resetFormCheckout());
    setSelectedPage('complete');
  };

  return (

    <Stack className="form-container">
      <CartOverview cardsEnabled={false} />
      {
        /*<Stack className="payment-method">
          <PaymentMethodForm
            propiedades={{ globalForm, setGlobalForm, globalErrors, setGlobalErrors }}
            title='Método de pago'
            subtitle='Escoge el método de pago que deseas'
          />
        </Stack>
        (globalForm.PaymentMethod && globalForm.PaymentMethod.Method === 'Online') &&
          <CreditCardForm
            propiedades={{ globalForm, setGlobalForm, globalErrors, setGlobalErrors }}
            title='Información de tarjeta'
            subtitle='Todas las transacciones son seguras'
          />*/
      }
      <Stack>
        <CreditCardForm title='Información de tarjeta' subtitle='Todas las transacciones son seguras'/>
        <BillingInfo title='Datos de facturación' isCheckout={true}/>
      </Stack>
      <Row>
        <Col lg={true}><button className="btn-dorado btn-submit" onClick={handleSubmit}>Realizar el pago</button></Col>
      </Row>
    </Stack>
  )
}

export default CheckoutPayment