import axios from 'axios';
import Swal from 'sweetalert2';
import urlSubcuenta from './urlSubcuenta';

const sessionExpired = () => {
    Swal.fire({
        title: 'Tu sesión expiró',
        text: 'Por favor inicia sesión nuevamente.',
        icon: 'warning',
        background: '#333',
        color: '#FFF',
        showCancelButton: false,
        confirmButtonColor: '#E5CB65',
        confirmButtonText: 'Ok'
    }).then(() => {
        // Redirige al home o a la página de inicio de sesión
        window.location.href = urlSubcuenta() + '/login/';
    });
};

const request = (config) => {
    if (config) {
        var req = {
            maxBodyLength: Infinity,
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                'X-XSS-Protection': '1; mode = block',
                'X-Content-Type-Options': 'nosniff',
                'X-Frame-Options': 'SAMEORIGIN',
                'Content-Security-Policy': 'self',
                'Strict-Transport-Security': 'max-age = 31536000; include subdomains; preload',
            },
            ...config, //Reemplazara las configuraciones por defecto
        }
        return new Promise((resolve, reject) => {
            axios.request(req).then(resolve).catch(e => {
                if(e.response.status === 401){
                    e.unauthorized = true;
                    sessionExpired();
                }
                reject(e);
            });
        });
    } else {
        throw "Parámetro no válido"
    }
}


const http = {
    post: (url, body) => request({
        method: 'post',
        url: url,
        data: body
    }),
    get: (url) => request({
        method: 'get',
        url: url
    }),
    request: request
}

export default http;