import React, { useEffect, useState } from 'react';
import ItemProductoDescripcion from '../../common/item-producto/item-producto-descripcion.common';
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/a11y';

import { getCarouselImages } from '../../utils/getImages';

import 'bootstrap/dist/css/bootstrap.min.css';
import './carousel-categoria-component.css';
import { useSelector } from 'react-redux';

function CarouselCategoria() {
    const { productos } = useSelector((state) => state);
    const [mobile, setMobile] = useState(false);
    const [productosLocal, setProductos] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState("P000001");

    useEffect(() => {
        const mediaQuery = window.matchMedia("(max-width: 600px)");
        setMobile(mediaQuery.matches);

        const handleResize = () => {
            setMobile(mediaQuery.matches);
        };

        mediaQuery.addEventListener("change", handleResize);
        return () => {
            mediaQuery.removeEventListener("change", handleResize);
        };
    }, []);

    useEffect(() => {
        if (productos && productos.length) {
            let products = productos.filter(e => ["P000001", "P000002", "P000003", "P000004", "P000005", "P000006", "P000007", "P000008", "P000009"].includes(e.ID_PRODUCTO)).map(product => {
                let headers = getCarouselImages({
                    mobile: mobile,
                    type: "categorias",
                    name: product.URL_IMAGEN,
                });

                return {
                    ...product,
                    headerInactiveIMG: headers[0],
                }
            });
            if (products.length) {
                setProductos(products);
                if (products[0]) {
                    setSelectedProduct(products[0].ID_PRODUCTO);
                }
            }
        }
    }, [productos]);

    const handleShowProduct = (id) => {
        setSelectedProduct(id);
    }

    return (
        <div className='carousel-categoria-container'>
            <Swiper
                className='swiper-categoria'
                navigation={{
                    nextEl: ".image-swiper-button-next",
                    prevEl: ".image-swiper-button-prev",
                    disabledClass: "swiper-button-disabled",
                }}
                slidesPerView={2}
                breakpoints={{
                    990: { slidesPerView: 4},
                    640: { slidesPerView: 3},
                  }}
                spaceBetween={50}
                modules={[Navigation]}
                >

                {productosLocal.map(prod =>
                    <SwiperSlide key={prod.ID_PRODUCTO}>
                        <div className={ selectedProduct === prod.ID_PRODUCTO ? 'carousel-selected-item' : 'carousel-categoria-item'}>
                            <img
                                src={prod.headerInactiveIMG}
                                alt=""
                                onClick={() => handleShowProduct(prod.ID_PRODUCTO)}
                            />
                            <span 
                                className={'text-color-white'} 
                                onClick={() => handleShowProduct(prod.ID_PRODUCTO)}>{prod.NOMBRE}</span>
                        </div>
                    </SwiperSlide>
                )}
                <div className="swiper-button-prev image-swiper-button-prev image-swiper-button"></div>
                <div className="swiper-button-next image-swiper-button-next image-swiper-button"></div>
            </Swiper>
            {productosLocal.map(prod =>
                <div className='inner-carousel-categoria-container' key={prod.ID_PRODUCTO}>
                    {selectedProduct === prod.ID_PRODUCTO && <ItemProductoDescripcion productoID={prod.ID_PRODUCTO} home={true} />}
                </div>
            )}
        </div >
    )
}
export default CarouselCategoria;
