//Validaciones correspondientes
// Párametros recibidos:
// value: Valor del componente
// param: Valor del objeto validate
const validations = {
  minSize: (value, size) => !(value.toString().length >= size) && `Este campo no puede estar vacío.`,
  maxSize: (value, size) => !(value.toString().length <= size) && `El valor ingresado no puede superar los ${size} caracteres.`,
  match: (value, regExp) => !(new RegExp(regExp).test(value)) ? 'El valor ingresado no cumple el formato definido.' : ''
}

export function validate(object) {
  //Si el campo es requerido
  if (object.required) {
    //Validaciones a realizar sobre el campo
    const valide = JSON.parse(object.getAttribute("validations"));
    let sMessage = '';

    //Recorre las validaciones
    for (let key in valide) {
      //Valida
      sMessage = validations[key] ? validations[key](object.value, valide[key]) : '';

      //Si encuentra error, lo retorna
      if (sMessage) {
        return {
          [object.name]: sMessage
        }
      }
    }
  }
  //No se ha encontrado un error
  return {
    [object.name]: null
  }
}



export function checkForm(form) {
  return Object.values(form).every(value => value === null)
}


export function validarCampos(objeto) {
  const resultado = {};

  for (const key in objeto) {
    if (Object.hasOwnProperty.call(objeto, key)) {
      const valor = objeto[key];
      // console.log("<Valor> en funcion validadora:", valor)
      if (!valor) {
        resultado[key] = "Este campo no puede estar vacío";
      } else {
        resultado[key] = null; // No hay error, el campo es válido
      }
      if (key.includes("POSTAL")) {
        if (/^\d{5}$/.test(valor)) {
          resultado[key] = null; // No hay error, el código postal es válido
        } else {
          resultado[key] =
            "El código postal debe contener exactamente 5 dígitos numéricos y no puede estar vacío.";
        }
      } else if (key.includes("EMAIL")) {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (emailRegex.test(valor)) {
          resultado[key] = null; // No hay error, el email es válido
        } else {
          resultado[key] = "El formato del correo electrónico no es válido";
        }
      }
      if (key.includes("TELEFONO")) {
        // // Validar el formato del número de teléfono (formato mexicano)
        // // const telefonoRegex = /^[0-9]{10}$/;
        // const telefonoRegex = /^\+?52[0-9]{10}$/;
        // if (telefonoRegex.test(valor)) {
        //   resultado[key] = null; // No hay error, el teléfono es válido
        // } else {
        //   resultado[key] =
        //     "El número de teléfono debe tener 10 dígitos despues del código pais (+52) y no puede estar vacío.";
        // }
      }
      if (key.includes("NUMERO")) {
        // Validar que solo contenga números
        if (valor){
          if (valor.toString().length >= 1 && valor.toString().length <= 13) {
            resultado[key] = null; // No hay error, solo contiene números
          } else {
            resultado[key] = "Este no puede estar vacío.";
          }
        }
      }
      if (key.includes("CVC")) {
        // Validar que tenga entre 3 y 4 caracteres y sean números
        if (/^\d{3,4}$/.test(valor)) {
          resultado[key] = null; // No hay error, el CVC es válido
        } else {
          resultado[key] = "El CVC ingresado no es válido.";
        }
      }
      if (key.includes("NumeroTarjeta")) {
        // Validar que tenga al menos 13 caracteres y sean números
        if (/^\d+$/.test(valor)) {
          resultado[key] = null; // No hay error, el número de tarjeta es válido
        } else {
          resultado[key] =
            "El número de tarjeta no puede contener letras y debe tener al menos 13 dígitos numéricos";
        }
      }

      if (key.includes("Vencimiento")) {
        
        // Validar que tenga exactamente 5 caracteres y sea formato MM/YY
        const vencimientoRegex = /^(0[1-9]|1[0-2])\/\d{2}$/;
        if (/^\d{4}$/.test(valor)) {
          resultado[key] = null; // No hay error, el formato de vencimiento es válido
        } else if (vencimientoRegex.test(valor)) {
          resultado[key] = null; // No hay error, el formato de vencimiento es válido
        } else {
          resultado[key] = "El formato de vencimiento debe ser MM/YY";
        }
      }
      if (key.includes("INT")) {
        resultado[key] = null; // No hay error, el campo INT puede estar vacío
      }

      if (key.includes("ID") && key !== 'APELLIDO') {
        resultado[key] = null; // No hay error, no hace falta validar IDs
      }
    }
  }

  return resultado;
}