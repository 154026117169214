import React, { useState, useEffect } from 'react'
import { Row, Col, Form, Stack } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import { checkForm, validarCampos, validate } from 'utils/validator'
import { updateCheckoutForm, validateForm } from 'redux/actions'

import './formularios.css'
import '../../App.css'
import '../../index.css'
import PencilIcon from 'common/pencil.common'

function ClientInfo({ title, subtitle, isCheckout }) {
  const dispatch = useDispatch();

  const renderForm = [
    [
      {
        type: 'text',
        validate: {
          minSize: 1
        },
        value: 'FACT_RFC',
        placeholder: 'R.F.C.'
      }
      ,
      {
        type: 'text',
        validate: {
          minSize: 1
        },
        value: 'FACT_REGIMEN_FISCAL',
        placeholder: 'Régimen Fiscal'
      }
    ],
    [
      {
        type: 'text',
        validate: {
          minSize: 1
        },
        value: 'FACT_NOMBRE',
        placeholder: 'Nombre(s)'
      }
      ,
      {
        type: 'text',
        validate: {
          minSize: 1
        },
        value: 'FACT_APELLIDO',
        placeholder: 'Apellido'
      }
    ],
    [
      {
        type: 'text',
        validate: {
          match: "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+[.]{1}[a-zA-Z]{2,}$"
        },
        value: 'FACT_EMAIL',
        placeholder: 'Correo electrónico'
      }
      ,
      {
        type: 'number',
        validate: {
          minSize: 1
        },
        value: 'FACT_COD_POSTAL',
        placeholder: 'C.P.'
      }
    ],
    [
      {
        type: 'text',
        validate: {
          minSize: 1
        },
        value: 'FACT_DIRECCION',
        placeholder: 'Calle'
      }
      ,
      {
        type: 'text',
        validate: {
          minSize: 1
        },
        value: 'FACT_COLONIA',
        placeholder: 'Colonia'
      }
    ],
    [
      {
        type: 'text',
        validate: {
          minSize: 0
        },
        value: 'FACT_NUMERO_INT',
        placeholder: 'No. Interior'
      }
      ,
      {
        type: 'text',
        validate: {
          minSize: 1
        },
        value: 'FACT_NUMERO_EXT',
        placeholder: 'No. Exterior'
      }
    ],
    [
      {
        type: 'text',
        validate: {
          minSize: 1
        },
        value: 'FACT_ESTADO',
        placeholder: 'Estado'
      }
      ,
      {
        type: 'text',
        validate: {
          minSize: 1
        },
        value: 'FACT_CIUDAD',
        placeholder: 'Municipio'
      }
    ]
  ]

  const { formCheckout, validations, clientInfo, logged } = useSelector((state) => state);
  const state = useSelector((state) => state)
  const [form, setForm] = useState(formCheckout.BILLING_FORM);
  const [localForm, setLocalForm] = useState(formCheckout.BILLING_FORM);
  const [selectedOption, setSelectedOption] = useState(false);
  const [requiresInvoice, setRequiresInvoice] = useState(true);
  const [isEditable, setIsEditable] = useState(false);

  const handleEdit = () => {

    dispatch(validateForm('global', { 'BILLING_FORM': !isEditable }))
    let isValid = false

    if (isEditable) {
      let oValidatesForm = validarCampos(formCheckout.BILLING_FORM)
      dispatch(validateForm('BILLING_FORM', oValidatesForm))
      isValid = checkForm(oValidatesForm)
      if (isValid) {
        setIsEditable(false)
        return false
      } else {
        setIsEditable(true)
        return true
      }
    } else {
      setIsEditable(true)
      return true
    }
  };

  //Metodo para actualizar el valor en el formulario global y local
  const onChangeValue = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value })
    setLocalForm({ ...localForm, [e.target.name]: e.target.value })
  }
  const validateInput = (e) => {
    dispatch(validateForm('BILLING_FORM', validate(e.target)))
  }

  //Metodo para actualizar el valor en el formulario
  const handleChangeSelect = (event) => {
    setSelectedOption(event.target.checked);
    if (logged) {
      if (event.target.checked) {
        let oForm = {
          FACT_NOMBRE: formCheckout.CLIENT_FORM.NOMBRE,
          FACT_APELLIDO: formCheckout.CLIENT_FORM.APELLIDO,
          FACT_EMAIL: formCheckout.CLIENT_FORM.EMAIL,
          FACT_COD_POSTAL: formCheckout.DIRECCION0_FORM.COD_POSTAL,
          FACT_DIRECCION: formCheckout.DIRECCION0_FORM.DIRECCION,
          FACT_NUMERO_EXT: formCheckout.DIRECCION0_FORM.NUMERO_EXT,
          FACT_NUMERO_INT: formCheckout.DIRECCION0_FORM.NUMERO_INT,
          FACT_ESTADO: formCheckout.DIRECCION0_FORM.ESTADO,
          FACT_CIUDAD: formCheckout.DIRECCION0_FORM.CIUDAD,
          FACT_COLONIA: formCheckout.DIRECCION0_FORM.COLONIA,
          FACT_RFC: formCheckout.BILLING_FORM.FACT_RFC,
          FACT_REGIMEN_FISCAL: formCheckout.BILLING_FORM.FACT_REGIMEN_FISCAL,
        },
          oValidationForm = {}
        Object.keys(oForm).forEach(sKey => {
          oValidationForm[sKey] = !!oForm[sKey] ? null : ''
          if (sKey.includes('INT')) {
            oValidationForm[sKey] = null
          }
        })
        dispatch(validateForm('BILLING_FORM', oValidationForm))
        setForm(oForm);
      } else {
        setForm(localForm);
      }
    }

    // Este ELSE pertenece al usuario NO logueado.
    else {
      if (event.target.checked) {
        const postalCodeTyped = `CP--checkout`
        let oForm = {
          FACT_NOMBRE: formCheckout.CONTACT_FORM.NOMBRE ? formCheckout.CONTACT_FORM.NOMBRE : '',
          FACT_APELLIDO: formCheckout.CONTACT_FORM.APELLIDO ? formCheckout.CONTACT_FORM.APELLIDO : '',
          FACT_EMAIL: formCheckout.CONTACT_FORM.EMAIL ? formCheckout.CONTACT_FORM.EMAIL : '',
          FACT_COD_POSTAL: state[postalCodeTyped] ? state[postalCodeTyped] : '',
          FACT_DIRECCION: formCheckout.ADDRESS_FORM.DIRECCION ? formCheckout.ADDRESS_FORM.DIRECCION : '',
          FACT_NUMERO_EXT: formCheckout.ADDRESS_FORM.NUMERO_EXT ? formCheckout.ADDRESS_FORM.NUMERO_EXT : '',
          FACT_NUMERO_INT: formCheckout.ADDRESS_FORM.NUMERO_INT ? formCheckout.ADDRESS_FORM.NUMERO_INT : '',
          FACT_ESTADO: formCheckout.ADDRESS_FORM.ESTADO ? formCheckout.ADDRESS_FORM.ESTADO : '',
          FACT_CIUDAD: formCheckout.ADDRESS_FORM.CIUDAD ? formCheckout.ADDRESS_FORM.CIUDAD : '',
          FACT_COLONIA: formCheckout.ADDRESS_FORM.COLONIA ? formCheckout.ADDRESS_FORM.COLONIA : '',
          FACT_RFC: "",
          FACT_REGIMEN_FISCAL: "",
        },
          oValidationForm = {}
        Object.keys(oForm).forEach(sKey => {
          oValidationForm[sKey] = !!oForm[sKey] ? null : ''
          if (sKey.includes('INT')) {
            oValidationForm[sKey] = null
          }
        })
        dispatch(validateForm('BILLING_FORM', oValidationForm))
        setForm(oForm);
      } else {
        let oValidationForm = {}
        Object.keys(localForm).forEach(sKey => {
          oValidationForm[sKey] = !!localForm[sKey] ? null : ''
          if (sKey.includes('INT')) {
            oValidationForm[sKey] = null
          }
        })
        setForm(localForm);
      }
    }
  };

  const handleCheckboxChange = (event) => {
    setRequiresInvoice(event.target.checked);
    dispatch(validateForm('global', { 'REQUIRES_INVOICE': event.target.checked }))
  };

  //Actualiza el formulario global
  useEffect(() => {
    dispatch(updateCheckoutForm({ BILLING_FORM: form }));
  }, [form]);

  //Resetea el estado del requires invoice
  useEffect(() => {
    // Esta línea se ejecuta una vez cuando el componente se monta
    dispatch(validateForm('global', { 'REQUIRES_INVOICE': true }));
  }, [dispatch]);

  //Actualiza el formulario global
  useEffect(() => {
    if (logged) {
      const infoFacturacion = {
        FACT_NOMBRE: clientInfo.FACT_NOMBRE ? clientInfo.FACT_NOMBRE : '',
        FACT_APELLIDO: clientInfo.FACT_APELLIDO ? clientInfo.FACT_APELLIDO : '',
        FACT_EMAIL: clientInfo.FACT_EMAIL ? clientInfo.FACT_EMAIL : '',
        FACT_COD_POSTAL: clientInfo.FACT_COD_POSTAL ? clientInfo.FACT_COD_POSTAL : '',
        FACT_DIRECCION: clientInfo.FACT_DIRECCION ? clientInfo.FACT_DIRECCION : '',
        FACT_NUMERO_EXT: clientInfo.FACT_NUMERO_EXT ? clientInfo.FACT_NUMERO_EXT : '',
        FACT_NUMERO_INT: clientInfo.FACT_NUMERO_INT ? clientInfo.FACT_NUMERO_INT : '',
        FACT_ESTADO: clientInfo.FACT_ESTADO ? clientInfo.FACT_ESTADO : '',
        FACT_CIUDAD: clientInfo.FACT_CIUDAD ? clientInfo.FACT_CIUDAD : '',
        FACT_COLONIA: clientInfo.FACT_COLONIA ? clientInfo.FACT_COLONIA : '',
        FACT_RFC: clientInfo.FACT_RFC ? clientInfo.FACT_RFC : '',
        FACT_REGIMEN_FISCAL: clientInfo.FACT_REGIMEN_FISCAL ? clientInfo.FACT_REGIMEN_FISCAL : '',
      }
      var oValidationForm = {}
      Object.keys(infoFacturacion).forEach(sKey => {
        oValidationForm[sKey] = !!infoFacturacion[sKey] ? null : ''
        if (sKey.includes('INT')) {
          oValidationForm[sKey] = null
        }
      })
      dispatch(validateForm('BILLING_FORM', oValidationForm))
      
      setForm(infoFacturacion);
    } else {
      setIsEditable(true)
    }
  }, []);

  const renderCheckboxAndForm = () => (
    <div>
      <Form.Check
        style={{ textAlign: 'left' }}
        type='checkbox'
        id='facturacion-billing-checkbox'
        checked={requiresInvoice}
        onChange={handleCheckboxChange}
        label='Requiere factura'
        name='Requiere factura'
        className='facturacion-billing-checkbox'
      />  
      <Form.Check
        style={{ textAlign: 'left' }}
        type='checkbox'
        id='facturacion-input-checkbox'
        checked={selectedOption}
        onChange={handleChangeSelect}
        label={isCheckout ? 'Usar datos de envío como facturación' : 'Usar datos de la dirección principal'}
        name='Facturacion'
        className='facturacion-input-checkbox'
      />
      {form && requiresInvoice ? renderForm.map(aRows => (
        <Row className="address-row">
          {aRows.map(oColumn => (
            <Col lg={true}>
              <Form.Control
                className="address-input golden-input"
                type={oColumn.type}
                isInvalid={validations.BILLING_FORM[oColumn.value]}
                required={oColumn.value !== 'FACT_NUMERO_INT'}
                validations={JSON.stringify(oColumn.validate)}
                name={oColumn.value}
                placeholder={oColumn.placeholder || oColumn.value}
                value={form && form[oColumn.value] ? form[oColumn.value] : ''}
                onBlur={validateInput}
                onChange={onChangeValue}
              />
              <Form.Control.Feedback className="input-feedback" type='invalid'>
                {validations.BILLING_FORM[oColumn.value]}
              </Form.Control.Feedback>
            </Col>
          ))}
        </Row>
      )) : null}
    </div>
  );

  return (
    <Form.Group controlId="address-form">
      <Stack direction='horizontal' className='icon-btn-cont'>
        {title && <h3 className="title-h3" style={{ marginBottom: '2rem' }}>{title}</h3>}
        <PencilIcon onClick={() => handleEdit()} />
      </Stack>
      {subtitle && <p className="title-description">{subtitle}</p>}
      {isEditable ?
        renderCheckboxAndForm()
        :
        form ? renderForm.map(aRows =>
          <Row className="address-row-default">
            {
              aRows.map(oColumn =>
                <Col lg={true} style={{ paddingLeft: 0 }}>
                  <div className='data-container'>
                    <p>{oColumn.placeholder}{':'} <b>{form && form[oColumn.value] ? form[oColumn.value] : ''}</b></p>
                  </div>
                </Col>
              )
            }
          </Row>
        ) : null
      }
    </Form.Group >
  )
}

export default ClientInfo