import logo from '../../logo-dorado.svg';
import instagram from '../../icons/instagram.svg';
import { Link, useNavigate } from 'react-router-dom';

import './footer.layout.css'

function Footer() {
  let history = useNavigate();
  return (
    <div className='custom-footer'>
        <div className='footer-navigation-content'>
            <img src={logo} className='footer-img img-fluid' onClick={() => history(`/app/`)}/>
            <Link className='footer-button-icon' to="/app/" target='_blank'>
              <button className='footer-button-icon'><img src={instagram} alt="" width="100%"/></button>
            </Link>
            <Link to="https://api.whatsapp.com/send?phone=%2B529999302222" target='_blank' className='footer-link'>CONTÁCTANOS</Link>
            <Link to="/app/preguntas-frecuentes" className='footer-link'>PREGUNTAS FRECUENTES</Link>
            <Link to='/app/terminos' className='footer-link'>TÉRMINOS Y CONDICIONES</Link>
            <Link to='/app/privacidad' className='footer-link'>AVISO DE PRIVACIDAD</Link>
            <Link to='/app/devoluciones' className='footer-link'>POLÍTICAS DE DEVOLUCIONES</Link> 
       </div>
       <div className='footer-text-content'>
            <label className='footer-text'>© Todos los derechos reservados Grupo Porcícola Mexicano 2023</label>
       </div>        
    </div>
  );
}

export default Footer;