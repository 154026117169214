const actionTypes = {
  GET_LOGGED_STATE: 'GET_LOGGED_STATE',
  GET_LOGGED_CLIENT: 'GET_LOGGED_CLIENT',
  UPDATE_LOGGED_CLIENT: 'UPDATE_LOGGED_CLIENT',
  GET_CLIENT_DIR: 'GET_CLIENT_DIR',
  ADD_CLIENT_DIR: 'ADD_CLIENT_DIR',
  UPDATE_CLIENT_DIR: 'UPDATE_CLIENT_DIR',
  DELETE_CLIENT_DIR: 'DELETE_CLIENT_DIR',
  GET_CLIENT_FAV: 'GET_CLIENT_FAV',
  ADD_CLIENT_FAV: 'ADD_CLIENT_FAV',
  DELETE_CLIENT_FAV: 'DELETE_CLIENT_FAV',
  GET_CLIENT_CART: 'GET_CLIENT_CART',
  ADD_ITEM_CLIENT_CART: 'ADD_ITEM_CLIENT_CART',
  DELETE_ITEM_CLIENT_CART: 'DELETE_ITEM_CLIENT_CART',
  GET_PRODUCTOS: 'GET_PRODUCTOS',
  GET_PRODUCTOS_HISTORICO: 'GET_PRODUCTOS_HISTORICO',
  GET_ACOMPAÑAMIENTOS: 'GET_ACOMPAÑAMIENTOS',
  CHECK_POSTAL_CODE: 'CHECK_POSTAL_CODE',
  UPDATE_CHECKOUT_FORM: 'UPDATE_CHECKOUT_FORM',
  UPDATE_ITEM: 'UPDATE_ITEM',
  CLEAR_POSTAL_CODE: 'CLEAR_POSTAL_CODE',
  POST_PEDIDO_USUARIO_LOGEADO: 'POST_PEDIDO_USUARIO_LOGEADO',
  FORM_VALIDATION: 'FORM_VALIDATION',
  ADD_TO_CART: 'ADD_TO_CART',
  REMOVE_FROM_CART: 'REMOVE_FROM_CART',
  CLEAR_CART: 'CLEAR_CART',
  GET_CART_ITEMS: 'GET_CART_ITEMS',
  IS_ITEM_IN_CART: 'IS_ITEM_IN_CART',
  GET_CART_TOTAL: 'GET_CART_TOTAL',
  MERGE_CARTS: 'MERGE_CARTS',
  UPDATE_CART_TOTAL: 'UPDATE_CART_TOTAL',
  UPDATE_GLOBAL_STATE: 'UPDATE_GLOBAL_STATE',
  POST_PEDIDO_USUARIO_NO_LOGEADO: 'POST_PEDIDO_USUARIO_NO_LOGEADO',
  GET_PEDIDOS_USUARIO_LOGUEADO: 'GET_PEDIDOS_USUARIO_LOGUEADO',
  GET_PRODUCTOS_ULTIMO_PEDIDO: 'GET_PRODUCTOS_ULTIMO_PEDIDO',
  SET_LOADER: 'SET_LOADER',
  SET_PEDIDO_REALIZADO: 'SET_PEDIDO_REALIZADO',
  CHANGE_ITEM_QUANTITY: 'CHANGE_ITEM_QUANTITY',
  RESET_FORM_CHECKOUT: 'RESET_FORM_CHECKOUT',
  CLEAR_RESET_TRIGGER: 'CLEAR_RESET_TRIGGER',
  RESET_CARD_FORM: 'RESET_CARD_FORM'
}
export default actionTypes;