import actionTypes from "../actions/actionTypes"

const initialState = {
    loader: false,
    logged: false,
    validations: {
        global: {
            ADDRESS_FORM: true,
            DIRECCION0_FORM: false,
            DIRECCION1_FORM: false,
            DIRECCION2_FORM: false,
            BILLING_FORM: false,
            CLIENT_FORM: false,
            REQUIRES_INVOICE: true
        },
        ADDRESS_FORM: {
            DIRECCION: '',
            COLONIA: '',
            NUMERO_EXT: '',
            NUMERO_INT: null,
            ESTADO: '',
            CIUDAD: '',
        },
        CARD_FORM: {
            NombreTarjeta: '',
            NumeroTarjeta: '',
            VencimientoTarjeta: '',
            CVCTarjeta: ''
        },
        BILLING_FORM: {
            FACT_RFC: '',
            FACT_REGIMEN_FISCAL: '',
            FACT_NOMBRE: '',
            FACT_APELLIDO: '',
            FACT_EMAIL: '',
            FACT_COD_POSTAL: '',
            FACT_DIRECCION: '',
            FACT_COLONIA: '',
            FACT_NUMERO_INT: null,
            FACT_NUMERO_EXT: '',
            FACT_ESTADO: '',
            FACT_CIUDAD: '',
        },
        CLIENT_FORM: {
            NOMBRE: '',
            APELLIDO: '',
            EMAIL: '',
            TELEFONO: '',
        },
        DIRECCION0_FORM: {
            DIRECCION: '',
            COLONIA: '',
            NUMERO_EXT: '',
            NUMERO_INT: null,
            ESTADO: '',
            CIUDAD: ''
        },
        DIRECCION1_FORM: {
            DIRECCION: '',
            COLONIA: '',
            NUMERO_EXT: '',
            NUMERO_INT: null,
            ESTADO: '',
            CIUDAD: ''
        },
        DIRECCION2_FORM: {
            DIRECCION: '',
            COLONIA: '',
            NUMERO_EXT: '',
            NUMERO_INT: null,
            ESTADO: '',
            CIUDAD: ''
        },
        CONTACT_FORM: {
            NOMBRE: '',
            COD_POSTAL: '',
            APELLIDO: '',
            EMAIL: '',
            TELEFONO: '',
        }
    },
    formCheckout: {
        TOTAL: 0,
        PRODUCTOS: {},
        SELECTED_ADDRESS: 'Otra',
        ADDRESS_FORM: {
            DIRECCION: '',
            COLONIA: '',
            NUMERO_EXT: '',
            NUMERO_INT: '',
            ESTADO: '',
            CIUDAD: '',
        },
        CARD_FORM: {
            NombreTarjeta: '',
            NumeroTarjeta: '',
            VencimientoTarjeta: '',
            CVCTarjeta: ''
        },
        BILLING_FORM: {
            FACT_RFC: '',
            FACT_REGIMEN_FISCAL: '',
            FACT_NOMBRE: '',
            FACT_APELLIDO: '',
            FACT_EMAIL: '',
            FACT_COD_POSTAL: '',
            FACT_DIRECCION: '',
            FACT_COLONIA: '',
            FACT_NUMERO_INT: '',
            FACT_NUMERO_EXT: '',
            FACT_ESTADO: '',
            FACT_CIUDAD: '',
        },
        CLIENT_FORM: {
            NOMBRE: '',
            APELLIDO: '',
            EMAIL: '',
            TELEFONO: '',
        },
        DIRECCION0_FORM: {
            DIRECCION: '',
            COLONIA: '',
            NUMERO_EXT: '',
            NUMERO_INT: '',
            ESTADO: '',
            CIUDAD: ''
        },
        DIRECCION1_FORM: {
            DIRECCION: '',
            COLONIA: '',
            NUMERO_EXT: '',
            NUMERO_INT: '',
            ESTADO: '',
            CIUDAD: ''
        },
        DIRECCION2_FORM: {
            DIRECCION: '',
            COLONIA: '',
            NUMERO_EXT: '',
            NUMERO_INT: '',
            ESTADO: '',
            CIUDAD: ''
        },
        CONTACT_FORM: {
            NOMBRE: '',
            COD_POSTAL: '',
            APELLIDO: '',
            EMAIL: '',
            TELEFONO: '',

        }
    },
    formResetTrigger: false,
    'postalCode--CP--checkout': '',
    'postalCodeCheck--CP--checkout': false,
    // clientInfo:{
    //     "DIRECCIONES": [
    //         {
    //             "UUID": "c1e9d94f-aea8-4338-a252-b599c35363d4",
    //             "ID_DIRECCION": "76",
    //             "COD_POSTAL": "97000",
    //             "DIRECCION": "Calle Falsa",
    //             "NUMERO_EXT": "123",
    //             "NUMERO_INT": null,
    //             "ESTADO": "Yucatán",
    //             "CIUDAD": "Progreso",
    //             "COLONIA": "Col. Centro",
    //             "COMENTARIO": null,
    //             "FECHA_REGISTRO": "2023-11-03T14:00:50.000Z",
    //             "INACTIVA": 0
    //         }
    //     ],
    //     "FAVORITOS": [
    //         {
    //             "FECHA_REGISTRO": "2023-10-27T19:59:54.000Z",
    //             "ID_PRODUCTO": "P000006",
    //             "UUID": "c1e9d94f-aea8-4338-a252-b599c35363d4"
    //         },
    //         {
    //             "FECHA_REGISTRO": "2023-10-27T19:59:58.000Z",
    //             "ID_PRODUCTO": "P000007",
    //             "UUID": "c1e9d94f-aea8-4338-a252-b599c35363d4"
    //         },
    //         {
    //             "FECHA_REGISTRO": "2023-10-27T20:00:07.000Z",
    //             "ID_PRODUCTO": "A000004",
    //             "UUID": "c1e9d94f-aea8-4338-a252-b599c35363d4"
    //         },
    //         {
    //             "FECHA_REGISTRO": "2023-10-27T20:00:08.000Z",
    //             "ID_PRODUCTO": "A000002",
    //             "UUID": "c1e9d94f-aea8-4338-a252-b599c35363d4"
    //         },
    //         {
    //             "FECHA_REGISTRO": "2023-11-16T19:17:00.000Z",
    //             "ID_PRODUCTO": "P000003",
    //             "UUID": "c1e9d94f-aea8-4338-a252-b599c35363d4"
    //         },
    //         {
    //             "FECHA_REGISTRO": "2023-11-16T19:17:09.000Z",
    //             "ID_PRODUCTO": "P000002",
    //             "UUID": "c1e9d94f-aea8-4338-a252-b599c35363d4"
    //         },
    //         {
    //             "FECHA_REGISTRO": "2023-11-16T19:17:11.000Z",
    //             "ID_PRODUCTO": "P000001",
    //             "UUID": "c1e9d94f-aea8-4338-a252-b599c35363d4"
    //         }
    //     ],
    //     "CARRITO": {
    //         "ID_CARRITO": "138",
    //         "EMAIL": "aorozco@blueboot.com",
    //         "UUID": "c1e9d94f-aea8-4338-a252-b599c35363d4",
    //         "PRODUCTOS": [
    //             {
    //                 "ID_PRODUCTO": "P000005",
    //                 "ID_CARRITO": "138",
    //                 "CANTIDAD": 2,
    //                 "PRECIO": "170.00",
    //                 "TOTAL": "680.00",
    //                 "UNIDAD": "KG",
    //                 "PESO": "2.0000"
    //             }
    //         ],
    //         "TOTAL": 680
    //     },
    //     "PRODUCTOS_HISTORICOS": [
    //         {
    //             "ID_PRODUCTO": "P000001",
    //             "ACTIVO": "X",
    //             "NOMBRE": "ARRACHERA DE CERDO",
    //             "CATEGORIA": "CERDO",
    //             "TIPO": "PRODUCTO",
    //             "DESCRIPCION": "Uno de los cortes más nobles para cocinar, gracias a su sabor natural y textura suave, es perfecto para asar a la parrilla. La sal y especias son suficientes para realzar sus atributos",
    //             "PRECIO": "109.5",
    //             "UNIDAD": "KG",
    //             "URL_IMAGEN": "ARRACHERA_DE_CERDO",
    //             "PESO": "1"
    //         },
    //         {
    //             "ID_PRODUCTO": "P000002",
    //             "ACTIVO": "X",
    //             "NOMBRE": "COSTILLAS ST. LOUIS",
    //             "CATEGORIA": "CERDO",
    //             "TIPO": "PRODUCTO",
    //             "DESCRIPCION": "Se obtienen del centro del costillar del cerdo. Es una costilla larga con carne pegada al hueso lo que le da suavidad y jugosidad. De más rápida cocción que una costilla tradicional, ideal para cocinarse a la parrilla a fuego lento con el marinado de tu elección",
    //             "PRECIO": "185",
    //             "UNIDAD": "KG",
    //             "URL_IMAGEN": "COSTILLAS_ST._LOUIS",
    //             "PESO": "1"
    //         }
    //     ],
    //     "PEDIDOS_RECIENTES": [],
    //     "ID_CLIENTE": "5",
    //     "UUID": "c1e9d94f-aea8-4338-a252-b599c35363d4",
    //     "EMAIL": "aorozco@blueboot.com",
    //     "TELEFONO": "2616977165",
    //     "NOMBRE": "Lucas",
    //     "APELLIDO": "Andres",
    //     "COD_POSTAL": "24000",
    //     "FACTURA": "",
    //     "FACT_NOMBRE": "Nombre Cliente1",
    //     "FACT_APELLIDO": "Apellido Cliente1",
    //     "FACT_COD_POSTAL": "24000",
    //     "FACT_DIRECCION": "Avenida Siempre Viva",
    //     "FACT_NUMERO_EXT": "756",
    //     "FACT_NUMERO_INT": "",
    //     "FACT_ESTADO": "Mundo Fake",
    //     "FACT_CIUDAD": "Municipio Prueba",
    //     "FACT_EMAIL": "aorozco@blueboo.com",
    //     "FACT_COLONIA": "Springfield 2",
    //     "FACT_RFC": "RFC Ejemplo",
    //     "FACT_REGIMEN_FISCAL": "Regimen Ejemplo",
    //     "FECHA_REGISTRO": "2023-07-20T19:37:31.000Z",
    //     "INVITADO": null
    // },
    clientInfo: {
        DIRECCIONES: [],
        FAVORITOS: [],
        CARRITO: {
            PRODUCTOS: [],
        },
        PRODUCTOS_HISTORICOS: [],
        PEDIDOS_RECIENTES: []
    },
    guestInfo: {
        DATOS_FACTURA: {
            FACT_RFC: "",
            FACT_REGIMEN_FISCAL: "",
            FACT_NOMBRE: "",
            FACT_APELLIDO: "",
            FACT_EMAIL: "",
            FACT_COD_POSTAL: "",
            FACT_DIRECCION: "",
            FACT_NUMERO_EXT: "",
            FACT_NUMERO_INT: "",
            FACT_ESTADO: "",
            FACT_CIUDAD: "",
            FACT_COLONIA: "",
            FECHA_REGISTRO: null,
        },
        DIRECCION: {
            DIRECCION: "",
            NUMERO_EXT: "",
            NUMERO_INT: "",
            ESTADO: "",
            CIUDAD: "",
            COLONIA: "",
            COD_POSTAL: "",
        },
        DATOS_CLIENTE: {
            EMAIL: "",
            TELEFONO: "",
            NOMBRE: "",
            APELLIDO: "",
        },
        CARRITO: {
            PRODUCTOS: [],
            TOTAL: 0
        },
        ID_TRANSACCION: "ASD123T35RTG",
    },
    confirmacionPedido: {
        DATA: {},
        PRODUCTOS: [],
        PEDIDO_REALIZADO: false,
    },

    userBackendData: [{
        Direccion: ['Curabitur Interdum, Curabitur Finibus, No. 101, Vivamus, Vivamus sollicitudin', 'Curabitur, Curabitur Finibus, No. 102, Vivamus, Vivamus sollicitudin'],
        CP: 19650,
        Nombre: 'Nulla Egestas',
        Apellido: 'Tortor Pharetra',
        Telefono: '55 39025782',
        Email: 'nulla123@dominio.com',
        userId: '3d380f95-a5fc-434f-8e2c-e43f615b7f8e'
    }],
    clientDirById: {},
    clientDirAll: [],
    clientById: {},
    productsById: {},
    productsAll: [],
    productos: [],
    acompañamientos: []
}

export default function reducer(state = initialState, action) {
    switch (action.type) {

        case actionTypes.UPDATE_GLOBAL_STATE:
            return {
                ...state,
                ...action.payload
            }
        case actionTypes.GET_LOGGED_STATE:
            return {
                ...state,
                logged: action.payload
            }

        case actionTypes.GET_LOGGED_CLIENT:
            return {
                ...state,
                clientInfo: { ...state.clientInfo, ...action.payload }
            }

        case actionTypes.UPDATE_LOGGED_CLIENT:
            return {
                ...state,
                clientInfo: { ...state.clientInfo, ...action.payload }
            }

        case actionTypes.GET_CLIENT_DIR:

            let filteredDirs = action.payload.length ? action.payload.filter((dir) => dir.INACTIVA !== 1) : action.payload
            return {
                ...state,
                clientInfo: { ...state.clientInfo, DIRECCIONES: filteredDirs }
            }

        case actionTypes.ADD_CLIENT_DIR:
            return {
                ...state,
                clientInfo: { ...state.clientInfo, DIRECCIONES: [...state.clientInfo.DIRECCIONES, action.payload] }
            }


        case actionTypes.UPDATE_CLIENT_DIR:

            let updatedDir = state.clientInfo.DIRECCIONES.filter((dir) => dir.ID_DIRECCION !== action.payload.ID_DIRECCION)
            updatedDir.push(action.payload)

            return {
                ...state,
                clientInfo: { ...state.clientInfo, DIRECCIONES: updatedDir }
            }

        case actionTypes.DELETE_CLIENT_DIR:
            let direcciones = state.clientInfo.DIRECCIONES
            return {
                ...state,
                clientInfo: { ...state.clientInfo, DIRECCIONES: direcciones.filter((e) => e.ID_DIRECCION !== action.payload) }
            }

        case actionTypes.GET_CLIENT_FAV:
            return {
                ...state,
                clientInfo: { ...state.clientInfo, FAVORITOS: action.payload }
            }

        case actionTypes.ADD_CLIENT_FAV:
            let allProducts = state.productos.concat(state.acompañamientos)
            let newFavProduct = allProducts.filter((p) => p.ID_PRODUCTO === action.payload)
            return {
                ...state,
                clientInfo: { ...state.clientInfo, FAVORITOS: [...state.clientInfo.FAVORITOS, ...newFavProduct] }
            }

        case actionTypes.DELETE_CLIENT_FAV:
            let favoritos = state.clientInfo.FAVORITOS
            return {
                ...state,
                clientInfo: { ...state.clientInfo, FAVORITOS: favoritos.filter((e) => e.ID_PRODUCTO !== action.payload) }
            }

        case actionTypes.GET_CLIENT_CART:
            return {
                ...state,
                clientInfo: { ...state.clientInfo, CARRITO: action.payload }
            }

        case actionTypes.ADD_ITEM_CLIENT_CART:
            return {
                ...state,
                clientInfo: { ...state.clientInfo, CARRITO: action.payload }
            }

        case actionTypes.CHANGE_ITEM_QUANTITY:

            let carritoActualizado = state.clientInfo.CARRITO.PRODUCTOS.map((producto) => {
                if (producto.ID_PRODUCTO === action.payload.ID_PRODUCTO) {
                    return { ...producto, CANTIDAD: action.payload.CANTIDAD, TOTAL: parseFloat((parseInt(action.payload.CANTIDAD) * parseFloat(producto.PESO)) * parseFloat(producto.PRECIO)).toFixed(2) };
                } else {
                    return producto;
                }
            })

            return {
                ...state,
                clientInfo: { ...state.clientInfo, CARRITO: { ...state.clientInfo.CARRITO, PRODUCTOS: carritoActualizado } }
                // clientInfo: { ...state.clientInfo, CARRITO: {...state.clientInfo.CARRITO, PRODUCTOS: [...state.clientInfo.CARRITO.PRODUCTOS, ]}}
            }

        case actionTypes.DELETE_ITEM_CLIENT_CART:
            //let itemsOnCart = state.clientInfo.CARRITO.PRODUCTOS
            return {
                ...state,
                clientInfo: { ...state.clientInfo, CARRITO: action.payload }
            }

        case actionTypes.GET_PRODUCTOS:
            return {
                ...state,
                productos: action.payload
            }

        case actionTypes.GET_PRODUCTOS_HISTORICO:
            return {
                ...state,
                clientInfo: { ...state.clientInfo, PRODUCTOS_HISTORICOS: action.payload }
            }

        case actionTypes.GET_ACOMPAÑAMIENTOS:
            return {
                ...state,
                acompañamientos: action.payload
            }

        case actionTypes.CHECK_POSTAL_CODE:
            // postalCodeCheck--CP--{id}
            const postalCodeCheck = `postalCodeCheck--${action.payload.instance}`
            // postalCode--CP--{id}
            const postalCode = `postalCode--${action.payload.instance}`
            const codPostal = action.payload.instance
            if (!action.payload.data.length) {
                return {
                    ...state,
                    [postalCode]: action.payload.data,
                    [postalCodeCheck]: false,
                    [codPostal]: action.payload.codPostal
                }
            } else {
                return {
                    ...state,
                    [postalCode]: action.payload.data,
                    [postalCodeCheck]: true,
                    [codPostal]: action.payload.codPostal
                    // [codPostal]: action.payload.data[0].COD_POSTAL
                }
            }
        case actionTypes.UPDATE_CHECKOUT_FORM:
            return {
                ...state,
                formCheckout: {
                    ...state.formCheckout,
                    ...action.payload
                }
            };
        case actionTypes.FORM_VALIDATION:
            return {
                ...state,
                validations: {
                    ...state.validations,
                    [action.payload.form]: {
                        ...state.validations[action.payload.form],
                        ...action.payload.values
                    }
                }
            };
        case actionTypes.UPDATE_ITEM:
            return {
                ...state,
                formCheckout: {
                    ...state.formCheckout,
                    PRODUCTOS: {
                        ...state.formCheckout.PRODUCTOS,
                        [action.payload.id]: Object.assign(state.formCheckout.PRODUCTOS[action.payload.id], action.payload.value)
                    }
                }
            };

        case actionTypes.CLEAR_POSTAL_CODE:
            const newState = { ...state };
            for (const key in newState) {
                if (key.includes("postalCode--")) {
                    newState[key] = "";
                }
            }
            return newState;

        case actionTypes.POST_PEDIDO_USUARIO_LOGEADO:
            delete action.payload.JSON_PEDIDO;

            return {
                ...state,
                clientInfo: {
                    ...state.clientInfo,
                    CARRITO: {
                        PRODUCTOS: [],
                    },
                },
                formCheckout: {
                    ...state.formCheckout,
                    TOTAL: 0,
                    PRODUCTOS: {},
                    SELECTED_ADDRESS: 'Otra',
                },
                confirmacionPedido: {
                    ...state.confirmacionPedido,
                    DATA: action.payload,
                    PRODUCTOS: action.payload.PRODUCTOS ? action.payload.PRODUCTOS : []
                }

            }

        case actionTypes.GET_PEDIDOS_USUARIO_LOGUEADO:
            return {
                ...state,
                clientInfo: {
                    ...state.clientInfo, PEDIDOS_RECIENTES: action.payload
                }
            };
        case actionTypes.ADD_TO_CART:
            return {
                ...state,
                guestInfo: {
                    ...state.guestInfo,
                    CARRITO: {
                        ...state.guestInfo.CARRITO,
                        PRODUCTOS: action.payload,
                    },
                },
            };
        case actionTypes.REMOVE_FROM_CART:
            return {
                ...state,
                guestInfo: {
                    ...state.guestInfo,
                    CARRITO: {
                        ...state.guestInfo.CARRITO,
                        PRODUCTOS: action.payload,
                    },
                },
            };
        case actionTypes.CLEAR_CART:
            return {
                ...state,
                guestInfo: {
                    ...state.guestInfo,
                    CARRITO: {
                        PRODUCTOS: [],
                        TOTAL: action.payload,
                    },
                },
            };
        case actionTypes.GET_CART_ITEMS:
            return {
                ...state,
                guestInfo: {
                    ...state.guestInfo,
                    CARRITO: {
                        ...state.guestInfo.CARRITO,
                        PRODUCTOS: action.payload,
                    },
                },
            };
        case actionTypes.UPDATE_CART_TOTAL:
            return {
                ...state,
                guestInfo: {
                    ...state.guestInfo,
                    CARRITO: {
                        ...state.guestInfo.CARRITO,
                        TOTAL: action.payload,
                    },
                },
            };

        case actionTypes.POST_PEDIDO_USUARIO_NO_LOGEADO:
            delete action.payload.JSON_PEDIDO;

            return {
                ...state,
                guestInfo: {
                    ...state.guestInfo, CARRITO: {
                        PRODUCTOS: [],
                    },
                },
                confirmacionPedido: {
                    ...state.confirmacionPedido,
                    DATA: action.payload,
                    PRODUCTOS: action.payload.PRODUCTOS ? action.payload.PRODUCTOS : []
                }
            }
        case actionTypes.GET_PRODUCTOS_ULTIMO_PEDIDO:
            return {
                ...state,
                confirmacionPedido: {
                    ...state.confirmacionPedido,
                    PRODUCTOS: action.payload
                }
            }
        case actionTypes.SET_LOADER:
            return {
                ...state,
                loader: action.payload
            }

        case actionTypes.SET_PEDIDO_REALIZADO:
            return {
                ...state,
                confirmacionPedido: { ...state.confirmacionPedido, PEDIDO_REALIZADO: action.payload }
            }
            case actionTypes.RESET_FORM_CHECKOUT:
                return {
                    ...state,
                    formCheckout: {
                        ...state.formCheckout,
                        SELECTED_ADDRESS: initialState.formCheckout.SELECTED_ADDRESS,
                        ADDRESS_FORM: initialState.formCheckout.ADDRESS_FORM,
                        CARD_FORM: initialState.formCheckout.CARD_FORM,
                        BILLING_FORM: initialState.formCheckout.BILLING_FORM,
                        CLIENT_FORM: initialState.formCheckout.CLIENT_FORM,
                        DIRECCION0_FORM: initialState.formCheckout.DIRECCION0_FORM,
                        DIRECCION1_FORM: initialState.formCheckout.DIRECCION1_FORM,
                        DIRECCION2_FORM: initialState.formCheckout.DIRECCION2_FORM,
                        CONTACT_FORM: initialState.formCheckout.CONTACT_FORM,
                    },
                    formResetTrigger: true,
                }
        case actionTypes.CLEAR_RESET_TRIGGER:
            return {
                ...state,
                formResetTrigger: false,
                'postalCode--CP--checkout': '',
                'postalCodeCheck--CP--checkout': false,
            };
        case actionTypes.RESET_CARD_FORM:
            return {
                ...state,
                formCheckout: {
                    ...state.formCheckout,
                    CARD_FORM: initialState.formCheckout.CARD_FORM,
                }
            }
        default:
            return {
                ...state,
            };
    }
}
