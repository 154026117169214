import Accordion from 'react-bootstrap/Accordion';
import HeroBanner from '../../common/hero-banner.common';

import { getBannerImg } from 'utils/getImages';
import { getPreguntas } from './faq.page';
import { ChevronDown, ChevronUp } from 'react-bootstrap-icons';
import { useState } from 'react';

import './faq.page.css';
import 'bootstrap/dist/css/bootstrap.min.css';

function FAQ() {
    var preguntas = getPreguntas();
    const [pedido, setPedido] = useState(false);
    const [producto, setProducto] = useState(false);
    return (
        <div className="faq-background">
            <HeroBanner title='PREGUNTAS FRECUENTES' subtitle='' backgroundImg={getBannerImg("FAQPreguntas")}></HeroBanner>
            <div className="faq-container background-color-gradient-black-grey">
            <Accordion>
                <Accordion.Item className='faq-acordion-item-pre-container'>
                    <Accordion.Header onClick={() => { setPedido(!pedido) }} className='faq-acordion-item-pre-container-header'><span>PEDIDOS <button>
                        {pedido ? <ChevronDown style={{ color: "white", background: "#363634" }}></ChevronDown> : <ChevronUp style={{ color: "white", background: "#363634" }}></ChevronUp>}
                    </button></span></Accordion.Header>
                    <Accordion.Body>
                        <Accordion >
                            {
                                preguntas.preguntasPedido.map(p => (
                                    <Accordion.Item eventKey={preguntas.preguntasPedido.indexOf(p)} className='faq-acordion-item-pregunta'>
                                        <Accordion.Header>{(preguntas.preguntasPedido.indexOf(p) + 1) + ". " + p.pregunta}</Accordion.Header>
                                        <Accordion.Body className='faq-acordion-item-descripcion'>{p.respuesta}</Accordion.Body>
                                    </Accordion.Item>
                                ))
                            }
                        </Accordion>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            <Accordion>
                <Accordion.Item className='faq-acordion-item-pre-container'>
                    <Accordion.Header onClick={() => { setProducto(!producto) }} className='faq-acordion-item-pre-container-header'><span>PRODUCTO <button>{producto ? <ChevronDown style={{ color: "white", background: "#363634" }}></ChevronDown> : <ChevronUp style={{ color: "white", background: "#363634" }}></ChevronUp>}</button></span></Accordion.Header>
                    <Accordion.Body>
                        <Accordion >
                            {
                                preguntas.preguntasProducto.map(p => (
                                    <Accordion.Item eventKey={preguntas.preguntasProducto.indexOf(p)} className='faq-acordion-item-pregunta'>
                                        <Accordion.Header>{(preguntas.preguntasProducto.indexOf(p) + 1) + ". " + p.pregunta}</Accordion.Header>
                                        <Accordion.Body className='faq-acordion-item-descripcion'>{p.respuesta}</Accordion.Body>
                                    </Accordion.Item>
                                ))
                            }
                        </Accordion>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            </div>
        </div>
    );
}
export default FAQ;