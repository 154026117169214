import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import logo from '../../logo-dorado.svg';
import { Link, useLocation } from 'react-router-dom';
import CartPanel from '../../components/carrito/CartPanel';
import { BoxArrowRight, BoxArrowInLeft } from 'react-bootstrap-icons';
import Swal from 'sweetalert2';

import './navbar.layout.css';
import '../../App.css'
import urlSubcuenta from 'utils/urlSubcuenta';

function Navbar() {
  const { logged, clientInfo, guestInfo } = useSelector((state) => state);
  const [cartPanel, setCartPanel] = useState(false);
  const [itemsCarrito, setItemsCarrito] = useState(0);
  const [numberCarrito, setNumberCarrito] = useState(0);

  // Setea el menú de escritorio ó el de hamburguesa según el Viewport
  const location = useLocation();
  const { pathname } = location;

  const handleOpen = () => {
    setCartPanel((cartPanel) => !cartPanel);
  };

  const handleLogin = () => {
    if (!logged) {
      const uniqueParam = new Date().getTime();

      // Realizar el redireccionamiento con el historial en el navegador
      window.location.href = urlSubcuenta() + '/login/';

    }
  };

  useEffect(() => {
    if (logged) {
      setItemsCarrito(clientInfo.CARRITO.TOTAL);
      setNumberCarrito(clientInfo.CARRITO.PRODUCTOS.length);
    } else {
      setItemsCarrito(guestInfo.CARRITO.TOTAL);
      setNumberCarrito(guestInfo.CARRITO.PRODUCTOS.length);
    }
  }, [clientInfo.CARRITO?.TOTAL, guestInfo.CARRITO?.TOTAL, clientInfo.CARRITO?.PRODUCTOS, guestInfo.CARRITO?.PRODUCTOS, logged]);

  return (
    <nav className='custom-navbar'>
      <img src={logo} className='navbar-img' alt="" onClick={() => window.location.href = '/app/'} />
      <Link to={`/app/productos`} style={{ color: `${pathname.includes('productos') ? '#E5CB65' : 'white'}` }} className='navbar-button'>NUESTROS CORTES</Link>
      <Link to={`/app/acompañamientos`} style={{ color: `${pathname.includes('acompa') ? '#E5CB65' : 'white'}` }} className='navbar-button'>ACOMPAÑAMIENTOS</Link>
      <Link to="https://keken.com.mx" target='_blank' className='navbar-button'>CONÓCENOS</Link>

      <div className='btn-icon-container'>
        {
          !logged ?
            <Link title="Mi cuenta" onClick={handleLogin}>
              <button className='navbar-button-icon'>
              <img src={pathname.includes("cuenta") ? '/images/icons/USUARIO_SELECTED.png' : '/images/icons/USUARIO_DEFAULT.png'} alt="Mi cuenta" width="100%" />
              </button>
            </Link>
            :
            /* Círculo con la inicial del nombre del usuario logueado */
            <Link title="Perfil" to={`/app/cuenta/datos`} className='user-initial-circle'>
              <span className='user-initial'>{clientInfo && clientInfo.NOMBRE ? clientInfo.NOMBRE[0].toUpperCase() : ' '}</span>
            </Link>
        }

        <button title="Carrito de compras" className='navbar-button-icon' onClick={handleOpen}>
          <div className='cart-icon-container'>
            {
              pathname.includes("carrito") && itemsCarrito === 0 ? (
                <img src="/images/icons/CARRITO_HOVER.png" alt="" width="100%" />
              ) : pathname.includes("carrito") && itemsCarrito > 0 ? (
                <img src="/images/icons/CARRITO_LLENO_FOCUS.png" alt="" width="100%" />
              ) : !pathname.includes("carrito") && itemsCarrito > 0 ? (
                <img src="/images/icons/CARRITO_LLENO.png" alt="" width="100%" />
              ) : (
                <img src="/images/icons/CARRITO_DEFAULT.png" alt="" width="100%" />
              )
            }
            {itemsCarrito > 0 && (
              <div className='cart-items-count'>
                {numberCarrito}
              </div>
            )}
          </div>
        </button>

        {/* Botón de logout */
          logged ? <button
            title="Logout"
            style={{ color: 'white' }}
            className='navbar-button-icon'
            onClick={() => {
              Swal.fire({
                title: '¿Estás seguro que deseas cerrar sesión?',
                icon: 'warning',
                showCloseButton: true,
                showCancelButton: true,
                confirmButtonText: 'Confirmar',
                cancelButtonText: 'Cancelar',
                confirmButtonColor: '#E5CB65',
                background: '#333',
                color: '#FFF'
              }).then((result) => {
                if (result.isConfirmed) {
                  const uniqueParam = new Date().getTime();
                  // Hacer la redirección al cerrar sesión
                  window.location.href = urlSubcuenta() + `/do/logout?cache_bust=${uniqueParam}`;
                }
              });
            }}
          >
            <BoxArrowRight style={{ fontSize: '1.2rem' }} />
          </button>
            : 
            null
            // <button
            //   title="Login"
            //   style={{ color: 'white' }}
            //   className='navbar-button-icon'
            //   onClick={handleLogin}
            // >
            //   <BoxArrowInLeft style={{ fontSize: '1.2rem' }} />
            // </button>
        }
      </div>
      <CartPanel propiedades={{ cartPanel, setCartPanel, handleOpen }}></CartPanel>
    </nav>
  );
}

export default Navbar;